export const activateOrDeactivateModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    padding: "1rem",
    border: "none",
    background: "#fff",
    boxShadow:
        "0 4.8px 14.4px rgba(0,0,0,.18), 0 25.6px 57.6px rgba(0,0,0,.22)",
    borderRadius: "3px",
    outline: "none",
};

export const createOrEditBtn = {
    fontFamily: "Source Sans Pro",
    background: "none",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    color: "#343a40",
    fontWeight: "400",
    padding: "0 0.9rem 0 0.7rem",
    height: "2.7rem",
    borderRadius: "none",
    "&:hover": {
        background: "#f4f3f2",
        borderRadius: "none",
    },
};
export const menuBtn = {
    fontFamily: "Source Sans Pro",
    background: "#80bdff",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    color: "#343a40",
    fontWeight: "400",
    padding: "0 0.9rem 0 0.7rem",
    height: "2.7rem",
    borderRadius: "none",
    "&:hover": {
        background: "#80bdff",
        borderRadius: "none",
    },
};

export const personalMenuBtn = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    background: "rgb(167, 209, 254)",
    margin: "20px auto",
    border: "1px solid #998d8d",
    borderRadius: "8px",
    textDecoration: "none",
    fontFamily: "Source Sans Pro",
    width: "160px",
    // textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    color: "#343a40",
    fontWeight: "400",
    padding: "0 0.9rem 0 0.7rem",
    height: "2.7rem",

    "&:hover": {
        background: "#f4f3f2",
        borderRadius: "none",
    },
};

export const drawer = {
    width: 400,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: 400,
        top: "7.5vh",
        padding: "1.2rem 0.7rem 1.2rem 1.2rem",
        boxSizing: "border-box",
    },
};

export const filterDrawer = {
    width: 250,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: 250,
        top: "7.5vh",
        padding: "1.2rem 0.7rem 1.2rem 1.2rem",
        boxSizing: "border-box",
    },
};

export const saveBtn = {
    backgroundColor: "#205493",
    textTransform: "capitalize",
    padding: "0.43rem 1.3333rem",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    borderRadius: "2px",
    marginRight: "16px",

    "&:hover": {
        backgroundColor: "#007bff",
        borderRadius: "none",
    },
};

export const cancelBtn = {
    fontFamily: "Source Sans Pro",
    backgroundColor: "none",
    textTransform: "capitalize",
    color: "#201f1e",
    border: "1px solid #8a8886",
    borderRadius: "2px",
    padding: "0.43rem 1.3333rem",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    "&:hover": {
        backgroundColor: "transparent",
        borderRadius: "none",
        borderColor: "#545b62",
    },
};

export const activateOrDeactivateBtn = {
    fontFamily: "Source Sans Pro",
    backgroundColor: "#205493",
    textTransform: "capitalize",
    padding: "0.43rem 1.3333rem",
    fontSize: "1rem",
    lineHeight: "1.333rem",
    borderRadius: "2px",
    marginRight: "16px",
    margin: "5px",
    "&:hover": {
        backgroundColor: "#007bff",
        borderRadius: "none",
    },
};

export const selectWithCloseIcon = {
    ".MuiOutlinedInput-input": {
        padding: "0.450rem 0.95rem",
        color: "#495057",
        backgroundColor: "#fff",
        fontSize: "1rem",
    },
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #8A8886",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #8A8886",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        color: "#495057",
        border: "none",
        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
    },
};

export const resetDefaultSelectBackground = {
    background: "transparent !important",
    "&:hover": {
        background: "rgba(0, 0, 0, 0.04) !important",
    },
};

export const rolesToolbarButtons = {
    backgroundColor: "none",
    color: "#343a40",
    textTransform: "capitalize",
    lineHeight: "1.333rem",
    fontSize: "1rem",
    height: "2.7rem",
    padding: "0 0.7rem 0 0.5rem",
    "&:hover": { backgroundColor: "#F4F3F2" },
};

export const roleSettingsAccordionSummary = {
    minHeight: 0,
    margin: 0,
    padding: 0,
    "&:hover": {
        background: "#f4f3f2",
    },
    ".MuiAccordionSummary-content": {
        margin: "5px 0",
    },
    ".MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
        minHeight: 0,
    },
    ".MuiAccordionSummary-content.Mui-expanded": {
        margin: "5px 0",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
        order: "-1",
        margin: "0 auto",
    },
    ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
        margin: 0,
    },
};

export const rolePermissionActionBox = {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&:hover": {
        background: "#f4f3f2",
    },
};

export const rolePermissionActionName = {
    padding: "0.7333rem 0.8667rem 0.8rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordWrap: "normal",
    flexGrow: "1",
    fontFamily: "Source Sans Pro ,sans-serif",
};

export const navBarAccordion = {
    "&:before": {
        display: "none",
    },
    boxShadow: "none",
    padding: "0 0 0 0.8rem",
    borderBottom: "1px solid rgba(0, 0, 0, .1)",
    margin: "0",
    ".MuiAccordionSummary-content": {
        margin: "1px 0 !important",
    },
    "&.MuiExpansionPanel-root:before": {
        display: "none",
    },
    ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
        margin: 0,
    },
};

export const navBarAccordionSummary = {
    color: "#201f1e",
    fontSize: "1.05rem",
    lineHeight: "1.3333rem",
    fontFamily: "Source Sans Pro, sans-serif",
};
export const organizationCircleLogo = {
    borderRadius: "50%",
    width: "60px",
    height: "60px",
};

export const downloadDocumentButton = {
    textTransform: "capitalize",
    textDecoration: "underline",
    background: "none",
    padding: 0,
    position: "relative",
    right: "5px",
    "&:hover": {
        background: "none",
        textDecoration: "underline",
    },
};

export const projectsListTableCell = {
    border: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    paddingLeft: "8px",
    "& span": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& a": {
        textDecoration: "underline",
        "&:visited": {
            color: "#3d78d4",
        },
        "&:link": {
            color: "#3d78d4",
        },
        "&:active": {
            color: "#3d78d4",
        },
    },
};

export const materialReactTableHeader = {
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& > span": {
        display: "block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
};

export const materialReactTableCell = {
    gap: "5px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    "& > p": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    "& > span": {
        display: "block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    "& a": {
        display: "block",
        // marginBottom: "5px",
        color: "#1976d2",
        textDecoration: "underline",
        textUnderlinePosition: "under",
        "&:visited": {
            color: "#3d78d4",
        },
        "&:link": {
            color: "#3d78d4",
        },
        "&:active": {
            color: "#3d78d4",
        },
    },
};

export const usersListTableCell = {
    border: "none",
    fontFamily: "Source Sans Pro",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    paddingLeft: "8px",
};

export const projectsTableContainer = {
    marginTop: "1rem",
    maxHeight: "calc(100vh - 31vh)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "0.3333rem",
        height: "0.3333rem",
    },

    "&::-webkit-scrollbar-thumb": {
        borderRadius: "0.3333rem",
        background: "#6d6d6d",
    },

    "&::-webkit-scrollbar-track": {
        borderRadius: "0.3333rem",
    },
};

export const employeesTableContainer = {
    width: "100%",
    maxHeight: "calc(100vh - 31vh)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "0.3333rem",
        height: "0.3333rem",
    },

    "&::-webkit-scrollbar-thumb": {
        borderRadius: "0.3333rem",
        background: "#6d6d6d",
    },

    "&::-webkit-scrollbar-track": {
        borderRadius: "0.3333rem",
    },
    "&:hover": {
        "&::-webkit-scrollbar": {
            width: "0.3333rem",
            height: "0.3333rem",
        },
    },
};

export const autocompleteStyles = {
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "0",
        border: "1px solid #8A8886",
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            border: "1px solid #8A8886",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
        },
    },
    // padding: "5px 0",
};

//COMMONS

export const tableContainerStyle = {
    marginTop: "20px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "0.3333rem",
        height: "0.3333rem",
    },

    "&::-webkit-scrollbar-thumb": {
        borderRadius: "0.3333rem",
        background: "#6d6d6d",
    },

    "&::-webkit-scrollbar-track": {
        borderRadius: "0.3333rem",
    },
};

export const tableRowStyle = {
    "& img": {
        visibility: "hidden",
    },
    "&": {
        cursor: "pointer",
    },
    "&:hover": {
        background: "#f4f3f2",
        "& img": {
            visibility: "visible",
            cursor: "pointer",
        },
    },
};

export const headTableCellStyle = {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: "#3E444A",
    padding: "10px 15px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "700",
    "&:first-of-type": {
        padding: "8px 8px 8px 26px",
    },
    textAlign: "center",
};

export const bodyTableCellStyle = {
    fontSize: 14,
    padding: "10px 15px",
    wordBreak: "break-word",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&:first-of-type": {
        padding: "10px",
    },
    "&:last-of-type": {
        padding: "10px",
    },
};
