import ComponentWrapper from "features/Catalogs/CatalogWrapper";
import EmployeeView from "features/Employee/EmployeeView/EmployeeView";
import HasPermission from "features/HasPermission";
import RequireAuth from "features/RequireAuth";
import { RouteObject } from "react-router-dom";
import RoleList from "views/Administration/Roles/RoleList";
import RoleSettings from "views/Administration/Roles/RoleSettings";
import UserPermissionsSettings from "views/Administration/UserPermissions/UserPermissionsSettings";
import UsersPermissions from "views/Administration/UserPermissions/UsersPermissions";
import { Documents } from "views/Catalogs/Documents";
import ContractCreate from "views/Contracts/ContractEmployees/ContractCreate/ContractCreate";
import ContractDetailsCreateAndEdit from "views/Contracts/ContractEmployees/ContractDetailsCreateAndEdit/ContractDetailsCreateAndEdit";
import ContractEdit from "views/Contracts/ContractEmployees/ContractEdit/ContractEdit";
import ContractEmployees from "views/Contracts/ContractEmployees/ContractEmployees";
import ContractsViewDetails from "views/Contracts/ContractEmployees/ContractsViewDetails/ContractsViewDetails";
import ContractView from "views/Contracts/ContractEmployees/ContractView/ContractView";
import Employees from "views/Employees";
import EditEmployeeForm from "views/Employees/EditEmployeeForm";
import Layout from "views/Layout";
import { LoginPage } from "views/Login";
import Organizations from "views/Organizations";
import EditOrganizationForm from "views/Organizations/EditOrganizationForm";
import OrganizationView from "views/Organizations/OrganizationView/OrganizationView";
import Projects from "views/Projects";
import ProjectsManagement from "views/Projects/ProjectsManagement/ProjectsManagement";
import ProjectsView from "views/Projects/ProjectsView/ProjectsView";
import Salaries from "views/Salaries";
import EmployeeSalaries from "views/Salaries/EmployeeSalaries/EmployeeSalaries";
import SalariesActions from "views/Salaries/SalariesActions/SalariesActions";
import Tracking from "views/Tracking";
import TrackingEmployeeTable from "views/Tracking/TrackingEmployeeTable";
import TrackingManagement from "views/Tracking/TrackingManagement/TrackingManagement";
import Users from "views/Users";
import VacationsSickDays from "views/VacationsSickDays";
import VacationSickDaysRemainingDays from "views/VacationsSickDays/VacationSickDaysRemainingDays";
import VacationsSickDaysEditEmployee from "views/VacationsSickDays/VacationsSickDaysEditEmployee";
import VacationsSickDaysViewEmployee from "views/VacationsSickDays/VacationsSickDaysViewEmployee";

import { Synchronization } from "../views/Administration/Synchronization";
import { PersonalAccount } from "../views/PersonalAccount";
import { PersonalAccountEdit } from "../views/PersonalAccount/PersonalAccountEdit";
import { PersonalApplications } from "../views/PersonalAccount/PersonalApplications/PersonalApplications";
import { PersonalCalendar } from "../views/PersonalAccount/PersonalCalendar/PersonalCalendar";
import { PersonalDocuments } from "../views/PersonalAccount/PersonalDocuments/PersonalDocuments";
import { PersonalInfo } from "../views/PersonalAccount/PersonalInfo/PersonalInfo";
import { PersonalNotifications } from "../views/PersonalAccount/PersonalNotifications/PersonalNotifications";
import { Requests } from "../views/Requests";
import TrackingProjectViewPage from "../views/Tracking/Rerenders/TrackingProjectViewPage";

import {
  ADMINISTRATION_PATH,
  CATALOG_PATHS,
  CATALOG_WRAPPER_PATH,
  CONTRACT_EMPLOYEES_PATH,
  CONTRACTS_CREATE,
  CONTRACTS_DETAILS_CREATE,
  CONTRACTS_DETAILS_EDIT,
  CONTRACTS_EDIT,
  CONTRACTS_PATH,
  CONTRACTS_VIEW,
  CONTRACTS_VIEW_DETAILS_PATH,
  DAYSOFF_EMPLOYEE_PATH,
  DAYSOFF_PATH,
  DAYSOFF_PATH_REMAINING_DAYS,
  EMPLOYEE_EDITING_PATH,
  EMPLOYEE_PATH,
  EMPLOYEES_PATH,
  IMPORT_PATH,
  LOGIN_PATH,
  ORGANIZATION_CREATE_PATH,
  ORGANIZATION_EDITING_PATH,
  ORGANIZATION_PATH,
  ORGANIZATIONS_PATH,
  PERSONAL_ACCOUNT,
  PERSONAL_ACCOUNT_APPLICATIONS,
  PERSONAL_ACCOUNT_CALENDAR,
  PERSONAL_ACCOUNT_COMMON_INFO,
  PERSONAL_ACCOUNT_DOCUMENTS,
  PERSONAL_ACCOUNT_EDIT,
  PERSONAL_ACCOUNT_NOTIFICATIONS,
  PROJECT_MANAGEMENT_PATH,
  PROJECT_PATH,
  PROJECTS_MANAGEMENT_PATH,
  PROJECTS_PATH,
  ROLE_PERMISSIONS_PATH,
  ROLES_PERMISSIONS_PATH,
  SALARIES_PATH,
  TRACKING_EMPLOYEE_TABLE_VIEW_PATH,
  TRACKING_MANAGEMENT_PATH,
  TRACKING_PATH,
  TRACKING_VIEW_PATH,
  TRACKINGS_MANAGMENT_PATH,
  USER_PERMISSIONS_PATH,
  USERS_PATH,
  USERS_PERMISSIONS_PATH,
} from "./constants";

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      {
        path: PERSONAL_ACCOUNT,

        children: [
          {
            path: "",
            element: <PersonalAccount />,
          },
          {
            path: PERSONAL_ACCOUNT_COMMON_INFO,
            element: <PersonalInfo />,
          },
          {
            path: PERSONAL_ACCOUNT_DOCUMENTS,
            element: <PersonalDocuments />,
          },
          {
            path: PERSONAL_ACCOUNT_APPLICATIONS,
            element: <PersonalApplications />,
          },
          {
            path: PERSONAL_ACCOUNT_CALENDAR,
            element: <PersonalCalendar />,
          },
          {
            path: PERSONAL_ACCOUNT_NOTIFICATIONS,
            element: <PersonalNotifications />,
          },
        ],
      },

            {
                path: CATALOG_WRAPPER_PATH,
                children: CATALOG_PATHS.map((name: string) => ({
                    path: name,
                    element: <ComponentWrapper name={name} />,
                })),
            },
            {
                path: "commondocuments",
                element: <Documents name={'common-documents'} />,
            },
            {
              path: 'applications',
              element: <Requests />,
            },
            {
                path: ORGANIZATIONS_PATH,

        children: [
          {
            path: "",
            element: <Organizations />,
          },
          {
            path: ORGANIZATION_PATH,
            element: <OrganizationView />,
          },
          {
            path: ORGANIZATION_EDITING_PATH,
            element: <EditOrganizationForm />,
          },
          {
            path: ORGANIZATION_CREATE_PATH,
            element: <EditOrganizationForm />,
          },
        ],
      },
      {
        path: ADMINISTRATION_PATH,
        children: [
          {
            path: USERS_PATH,
            element: <Users />,
          },
          {
            path: ROLES_PERMISSIONS_PATH,
            element: <RoleList />,
          },
          {
            path: IMPORT_PATH,
            element: <Synchronization />,
          },
          {
            path: ROLE_PERMISSIONS_PATH,
            element: <RoleSettings />,
          },
          {
            path: USERS_PERMISSIONS_PATH,
            element: <UsersPermissions />,
          },
          {
            path: USER_PERMISSIONS_PATH,
            element: <UserPermissionsSettings />,
          },
        ],
      },
      {
        path: EMPLOYEES_PATH,
        children: [
          {
            path: "",
            element: (
              <HasPermission>
                <Employees />
              </HasPermission>
            ),
          },
          {
            path: EMPLOYEE_PATH,
            element: <EmployeeView />,
          },
          {
            path: EMPLOYEE_EDITING_PATH,
            element: <EditEmployeeForm />,
          },
        ],
      },
      {
        path: PROJECTS_PATH,
        children: [
          {
            path: "",
            element: (
              <HasPermission>
                <Projects />
              </HasPermission>
            ),
          },
          {
            path: PROJECT_PATH,
            element: <ProjectsView />,
          },
          {
            path: PROJECTS_MANAGEMENT_PATH,
            element: <ProjectsManagement />,
          },
          {
            path: PROJECT_MANAGEMENT_PATH,
            element: <ProjectsManagement />,
          },
        ],
      },
      {
        path: `${PERSONAL_ACCOUNT_EDIT}`,
        element: <PersonalAccountEdit />,
      },
      {
        path: "employees/edit/:id",
        element: <EditEmployeeForm />,
      },
      {
        path: "employees/:employeeId",
        element: <EmployeeView />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "projects/view/:projectId",
        element: <ProjectsView />,
      },
      {
        path: "projects/management/:operation",
        element: <ProjectsManagement />,
      },

      {
        path: "organizations/edit/:id",
        element: <EditOrganizationForm />,
      },
      {
        path: SALARIES_PATH,
        children: [
          {
            path: "",
            element: (
              <HasPermission>
                <Salaries />
              </HasPermission>
            ),
          },
          {
            path: "create",
            element: <SalariesActions />,
          },
          {
            path: ":employeeId",
            children: [
              {
                path: "",
                element: <EmployeeSalaries />,
              },
              {
                path: "create",
                element: <SalariesActions />,
              },
              {
                path: "edit/:id",
                element: <SalariesActions />,
              },
            ],
          },
        ],
      },
      {
        path: DAYSOFF_PATH,
        children: [
          {
            path: "",
            element: <VacationsSickDays />,
          },
          {
            path: DAYSOFF_EMPLOYEE_PATH,
            children: [
              {
                path: "",
                element: <VacationsSickDaysViewEmployee />,
              },
              {
                path: "edit",
                element: <VacationsSickDaysEditEmployee />,
              },
            ],
          },
          {
            path: DAYSOFF_PATH_REMAINING_DAYS,
            children: [
              {
                path: "",
                element: <VacationSickDaysRemainingDays />
              },
            ],
          },
        ],
      },
      {
        path: TRACKING_PATH,
        children: [
          {
            path: "",
            element: <Tracking />,
          },
          {
            path: TRACKING_VIEW_PATH,
            element: <TrackingManagement />,
          },
          {
            path: TRACKINGS_MANAGMENT_PATH,
            element: <TrackingManagement />,
          },
          {
            path: TRACKING_MANAGEMENT_PATH,
            element: <TrackingManagement />,
          },
          {
            path: TRACKING_EMPLOYEE_TABLE_VIEW_PATH,
            element: <TrackingEmployeeTable />,
          },
          {
            path: "project/:projectId",
            element: <TrackingProjectViewPage />,
          },
        ],
      },
      {
        path: CONTRACTS_PATH,
        children: [
          {
            path: CONTRACTS_CREATE,
            element: <ContractCreate />,
          },
          {
            path: CONTRACTS_EDIT,
            element: <ContractEdit />,
          },
          {
            path: CONTRACTS_VIEW,
            element: <ContractView />,
          },
          {
            path: CONTRACT_EMPLOYEES_PATH,
            //возможно нужно будет поменять название компоненты и пути
            element: <ContractEmployees />,
          },
          {
            path: CONTRACTS_VIEW_DETAILS_PATH,
            element: <ContractsViewDetails />,
          },
          {
            path: CONTRACTS_DETAILS_CREATE,
            element: <ContractDetailsCreateAndEdit />,
          },
          {
            path: CONTRACTS_DETAILS_EDIT,
            element: <ContractDetailsCreateAndEdit />,
          },
        ],
      },
      // {
      //     path: '*',
      //     element: <PageNotFound/>
      // },
    ],
  },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
];

export default routes;
