import React, { useEffect, useState } from "react";
import axiosApi from "core/request";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { sendNotification } from "ui/Toast";

import AdapterDayjs from "@date-io/date-fns";
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Backdrop,
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { FilterIcon } from "../../assets/icons";
import AgGrid from "../../features/AgGrid/AgGrid";
import { useAppSelector } from "../../hooks";
import { cancelBtn, createOrEditBtn } from "../../styles/MUIStyles";
import Loader from "../../ui/Loader";

import { LeaveOrder } from "./LeaveOrder";
import { formatDate, readStatus } from "./utils";

import styles from "./styles.module.css";

const RequestStatuses = {
  None: 0,
  AwaitingPM: 1,
  AwaitingHR: 2,
  AwaitingGM: 3,
  Rejected: 4,
  Approved: 5,
  Cancelled: 6,
};

export type Request = {
  createdDate: Date;
  finishDate: Date;
  isStandard: boolean;
  id: number;
  nextApproverName: string;
  pureDays: number;
  remark: string;
  requestStatus: number;
  sign: number;
  startDate: Date;
  employeeName?: string;
  employeeEmail?: string;
  position?: string;
  email?: string;
};

export const Requests = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState<Request[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isOpenModalApprove, setIsOpenModalApprove] = useState(false);
  const [isOpenModalReject, setIsOpenModalReject] = useState(false);
  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterDate, setFilterDate] = useState<dayjs.Dayjs | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [leaveOrderData, setLeaveOrderData] = useState<Request | null>(null);
  const userRole = useAppSelector(state => state.auth.role);
  const [draftFilterName, setDraftFilterName] = useState("");
  const [draftFilterStatus, setDraftFilterStatus] = useState("");
  const [draftFilterDate, setDraftFilterDate] = useState<dayjs.Dayjs | null>(null);

  const handleOpenDrawer = () => {
    setDraftFilterName(filterName);
    setDraftFilterStatus(filterStatus);
    setDraftFilterDate(filterDate);
    setIsOpenDrawer(true);
  };

  const handleResetFilters = () => {
    setFilterName("");
    setFilterStatus("");
    setFilterDate(null);
    setDraftFilterName("");
    setDraftFilterStatus("");
    setDraftFilterDate(null);
  };

  const handleCloseDrawer = () => setIsOpenDrawer(false);

  const handleOpenDownloadModal = (data: Request) => {
    setLeaveOrderData(data);
    setIsOpenModal(true);
  };

  const getApplications = async (filters?: {
    filterName?: string;
    filterStatus?: string;
    filterDate?: dayjs.Dayjs | null;
  }) => {
    setIsLoading(true);
    const params: any = {
      pageSize: 100,
    };

    const name = filters?.filterName ?? filterName;
    const status = filters?.filterStatus ?? filterStatus;
    const date = filters?.filterDate ?? filterDate;

    if (name.trim() !== "") {
      params["filter.name"] = name;
    }

    if (date) {
      params["filter.createdDateTime"] = dayjs(date).format("YYYY-MM-DD");
    }

    if (status !== "") {
      params["filter.status"] = Number(status);
    }

    try {
      const response = await axiosApi.get<{
        data: Request[];
        metadata: any;
      }>("/personal-cabinet/calendar/daysoff/requests", { params });
      setRequests(response.data.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching applications", error);
    } finally {
      setIsLoading(false);
    }
  };

  const approve = async (id: number) => {
    try {
      setIsLoading(true);
      await axiosApi.post(`/personal-cabinet/calendar/daysoff/request/${id}/approve`);
      sendNotification(t("requests.notification.approveSuccess"), "success");
    } catch (e) {
      console.log(e);
      sendNotification(t("requests.notification.approveError"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const reject = async (id: number) => {
    try {
      setIsLoading(true);
      await axiosApi.post(`/personal-cabinet/calendar/daysoff/request/${id}/reject`);
      sendNotification(t("requests.notification.rejectSuccess"), "success");
    } catch (e) {
      console.log(e);
      sendNotification(t("requests.notification.rejectError"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const cancel = async (id: number) => {
    try {
      setIsLoading(true);
      await axiosApi.post(`/personal-cabinet/calendar/daysoff/request/${id}/cancel`);
      sendNotification(t("requests.notification.cancelSuccess"), "success");
    } catch (e) {
      console.log(e);
      sendNotification(t("requests.notification.cancelError"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleApproveRequest = () => {
    setIsOpenModalApprove(true);
    handleCloseMenu();
  };

  const handleConfirmApprove = async () => {
    if (selectedRow?.id) {
      await approve(selectedRow.id);
      setIsOpenModalApprove(false);
      getApplications();
    }
  };

  const handleRejectRequest = () => {
    setIsOpenModalReject(true);
    handleCloseMenu();
  };

  const handleConfirmReject = async () => {
    if (selectedRow?.id) {
      await reject(selectedRow.id);
      setIsOpenModalReject(false);
      getApplications();
    }
  };

  const handleConfirmCancel = async () => {
    if (selectedRow?.id) {
      await cancel(selectedRow.id);
      setIsOpenModalCancel(false);
      getApplications();
    }
  };

  const columnDefs = [
    {
      headerName: t("requests.column.id"),
      field: "id",
      resizable: true,
      width: 90,
    },
    {
      headerName: t("requests.column.employeeName"),
      field: "employeeName",
      resizable: true,
      width: 220,
    },
    {
      headerName: t("requests.column.email"),
      field: "employeeEmail",
      resizable: true,
      width: 200,
    },
    {
      headerName: t("data.position"),
      field: "position",
      resizable: true,
      width: 150,
    },
    {
      headerName: t("requests.column.remark"),
      field: "remark",
      resizable: true,
      width: 150,
    },
    {
      headerName: t("requests.column.createdDate"),
      field: "createdDate",
      resizable: true,
      width: 150,
      valueGetter: (params: any) => {
        const createdDate = new Date(params.data.createdDate);
        return formatDate(createdDate);
      },
    },
    {
      headerName: t("requests.column.period"),
      resizable: true,
      width: 220,
      valueGetter: (params: any) => {
        const startDate = new Date(params.data.startDate);
        const finishDate = new Date(params.data.finishDate);
        return `${formatDate(startDate)} - ${formatDate(finishDate)}`;
      },
    },
    {
      headerName: t("requests.column.status"),
      field: "requestStatus",
      valueFormatter: (params: any) => readStatus(params.value),
      resizable: true,
      width: 320,
    },
    {
      headerName: t("requests.column.nextApproverName"),
      field: "nextApproverEmail",
      resizable: true,
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <span className={styles.responsibleMenuItem}>
            <span>{params.data.nextApproverName}</span>
            {!!params.data.nextApproverEmail && (
              <span className={styles.responsiblePost}>
                {params.data.nextApproverEmail}
              </span>
            )}
          </span>
        );
      },
    },
    {
      headerName: t("requests.column.isStandard"),
      field: "isStandard",
      resizable: true,
      width: 180,
      cellRenderer: (params: any) => {
        return (
          <span>
            {params.value ? t("requests.standard") : t("requests.nonStandard")}
          </span>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 60,
      resizable: false,
      cellRenderer: (params: any) => {
        return (
          <IconButton onClick={(event) => handleMenuClick(event, params.data)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
    {
      headerName: "",
      field: "actions",
      width: 70,
      resizable: false,
      cellRenderer: (params: any) => {
        if (userRole !== "HumanResources") return null;
        return (
          <Tooltip title={t("buttons.PrintDocument")}>
            <IconButton onClick={() => handleOpenDownloadModal(params.data)}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      {isLoading && (
        <div style={{
          position: "absolute",
          zIndex: 1000,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}>
          <Loader />
        </div>
      )}
      <div className={styles.header}>
        <h4 style={{ flex: 1 }}>{t("tabs.applications")}</h4>
        <Button
          startIcon={<FilterIcon />}
          variant="text"
          sx={{ ...createOrEditBtn }}
          onClick={handleOpenDrawer}
        >
          {t("requests.filtersButton")}
        </Button>
      </div>
      <div className={isLoading ? styles.opacity : ''}>
        <AgGrid
          rowData={requests}
          columnDefs={columnDefs}
          height="500px"
          activeRow={{}}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleApproveRequest}>
          {t("requests.approveButton")}
        </MenuItem>
        <MenuItem onClick={handleRejectRequest}>
          {t("requests.rejectButton")}
        </MenuItem>
        {/*<MenuItem onClick={handleCancelRequest}>
          {t("requests.cancelButton")}
        </MenuItem>*/}
      </Menu>
      <Modal
        open={isOpenModalApprove}
        onClose={() => setIsOpenModalApprove(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t("requests.approveModalTitle", {
              employeeName: selectedRow?.employeeName,
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t("requests.approveModalDescription")}
          </Typography>
          <Button
            variant="contained"
            color="success"
            onClick={handleConfirmApprove}
            sx={{ mr: 2, textTransform: "none" }}
          >
            {t("requests.approveButton")}
          </Button>
          <Button
            sx={{ ...cancelBtn }}
            variant="outlined"
            onClick={() => setIsOpenModalApprove(false)}
          >
            {t("requests.noButton")}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={isOpenModalReject}
        onClose={() => setIsOpenModalReject(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t("requests.rejectModalTitle", {
              employeeName: selectedRow?.employeeName,
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t("requests.rejectModalDescription")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmReject}
            sx={{ mr: 2, textTransform: "none" }}
          >
            {t("requests.rejectButton")}
          </Button>
          <Button
            sx={{ ...cancelBtn }}
            variant="outlined"
            onClick={() => setIsOpenModalReject(false)}
          >
            {t("requests.noButton")}
          </Button>
        </Box>
      </Modal>
      <Modal
        open={isOpenModalCancel}
        onClose={() => setIsOpenModalCancel(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t("requests.cancelModalTitle", {
              employeeName: selectedRow?.employeeName,
            })}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t("requests.cancelModalDescription")}
          </Typography>
          <Button
            variant="contained"
            color="warning"
            onClick={handleConfirmCancel}
            sx={{ mr: 2, textTransform: "none" }}
          >
            {t("requests.cancelButton")}
          </Button>
          <Button
            sx={{ ...cancelBtn }}
            variant="outlined"
            onClick={() => setIsOpenModalCancel(false)}
          >
            {t("requests.noButton")}
          </Button>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isOpenDrawer} onClose={handleCloseDrawer}>
        <Box sx={{ width: 300, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t("requests.drawerTitle")}
          </Typography>

          <TextField
            fullWidth
            label={t("requests.filterNameLabel")}
            value={draftFilterName}
            onChange={(e) => setDraftFilterName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t("requests.filterStatusLabel")}</InputLabel>
            <Select
              value={draftFilterStatus}
              onChange={(e) => setDraftFilterStatus(e.target.value)}
              label={t("requests.filterStatusLabel")}
            >
              <MenuItem value="">{t("requests.filterOptionAll")}</MenuItem>
              <MenuItem value={RequestStatuses.AwaitingPM.toString()}>
                {t("requests.awaitingPM")}
              </MenuItem>
              <MenuItem value={RequestStatuses.AwaitingHR.toString()}>
                {t("requests.awaitingHR")}
              </MenuItem>
              <MenuItem value={RequestStatuses.AwaitingGM.toString()}>
                {t("requests.awaitingGM")}
              </MenuItem>
              <MenuItem value={RequestStatuses.Approved.toString()}>
                {t("requests.approved")}
              </MenuItem>
              <MenuItem value={RequestStatuses.Rejected.toString()}>
                {t("requests.rejected")}
              </MenuItem>
              <MenuItem value={RequestStatuses.Cancelled.toString()}>
                {t("requests.cancelled")}
              </MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="dd.MM.yyyy"
              label={t("requests.filterDateLabel")}
              value={draftFilterDate}
              onChange={(newValue) => setDraftFilterDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
            />
          </LocalizationProvider>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="outlined" onClick={handleResetFilters}>
              {t("requests.resetFilters")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setFilterName(draftFilterName);
                setFilterStatus(draftFilterStatus);
                setFilterDate(draftFilterDate);
                getApplications({
                  filterName: draftFilterName,
                  filterStatus: draftFilterStatus,
                  filterDate: draftFilterDate,
                });
                handleCloseDrawer();
              }}
            >
              {t("buttons.Apply")}
            </Button>

          </Box>
        </Box>
      </Drawer>
      {leaveOrderData && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <LeaveOrder {...leaveOrderData} />
        </Modal>
      )}
    </div>
  );
};
