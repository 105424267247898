import { createSlice } from '@reduxjs/toolkit';

import { ICommonDocument } from '../../models/commonDocument';
import { addDocument, deleteDocument, getDocuments } from '../thunks/commonDocumentsThunk';

type CommonDocumentsSlice = {
    documents: ICommonDocument[];
    isLoading: boolean;
};

const initialState: CommonDocumentsSlice = {
    documents: [],
    isLoading: false
};

const commonDocumentsSlice = createSlice({
    name: 'common-documents',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDocuments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.documents = action.payload.data;
            })
            .addCase(addDocument.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addDocument.fulfilled, (state, action) => {
                state.isLoading = false;
                state.documents = [...state.documents, action.payload];
            })
            .addCase(addDocument.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteDocument.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDocument.fulfilled, (state) => {
                // state.documents = state.documents.filter((doc) => doc.id !== action.payload.data.id);
                state.isLoading = false;
            })
            .addCase(deleteDocument.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

// eslint-disable-next-line no-empty-pattern
export const {} = commonDocumentsSlice.actions;

export default commonDocumentsSlice.reducer;
