import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector, useUpdateEffect } from "hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CONTRACT_EMPLOYEES_PATH, CONTRACTS_PATH } from "router/constants";
import { setDefaultValuesForContractData } from "store/slices/contractsSlice";
import {
  addAdditionToContract,
  editContractAddition,
  getContractById,
  getMainContractById,
} from "store/thunks/contractsEmployees";
import Loader from "ui/Loader";

import AdapterDayjs from "@date-io/date-fns";
import { ErrorMessage } from "@hookform/error-message";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import ConfirmationModal from "../../../../features/ConfirmationModal";
import ButtonWithProgress from "../../../../ui/ButtonWithProgress";
import { sendNotification } from "../../../../ui/Toast";

import styles from "./ContractDetialsCreateAndEdit.module.scss";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const InputMask = require("react-input-mask");

interface IContractInput {
  description: string | null;
  conclusionDate: string | null;
  endDate: string | null;
  contractAmount: number | string | null;
  amountMonth: number | string | null;
  note: string | null;
  parentId: number | null;
  id: number;
}

const ContractDetailsCreateAndEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const mainContractConclusionDate = localStorage.getItem(
    "mainContractConclusionDate",
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalConfirm = () => {
    if (isDirty) {
      navigate(-1);
      setConfirmationModalOpen(false);
      // setIsAddDocumentModalOpen(false);
      reset({}, { keepValues: true });
    }
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    clearErrors,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = useForm<IContractInput>({
    defaultValues: {
      description: "",
      conclusionDate: null,
      endDate: null,
      contractAmount: null,
      amountMonth: null,
      note: "",
    },
  });

  const amountMonth = watch("amountMonth");
  const contractAmount = watch("contractAmount");
  const conclusionDate = watch("conclusionDate");
  const endDate = watch("endDate");
  const createdContractAdditionId = localStorage.getItem(
    "createdContractAdditionId",
  );

  const editAndViewContractData = useAppSelector(
    (state) => state.contracts.editAndViewContractData,
  );
  const mainContractData = useAppSelector(
    (state) => state.contracts.mainContractData,
  );
  const isGettingEditOrViewContractDataLoading = useAppSelector(
    (state) => state.contracts.isGettingEditOrViewContractDataLoading,
  );
  const isCreatingContractAdditionLoading = useAppSelector(
    (state) => state.contracts.isCreatingContractAdditionLoading,
  );
  const isEditingContractAdditionLoading = useAppSelector(
    (state) => state.contracts.isEditingContractAdditionLoading,
  );

  const onSubmit: SubmitHandler<IContractInput> = async (contractData) => {
    if (
      editAndViewContractData &&
      params?.operation === "create" &&
      !createdContractAdditionId
    ) {
      const contractStatus =
        editAndViewContractData.status === "Active"
          ? 1
          : editAndViewContractData.status === "Suspended"
            ? 2
            : 3;
      const response = await dispatch(
        addAdditionToContract({
          number: editAndViewContractData.number,
          employeeId: editAndViewContractData.employeeId,
          name: editAndViewContractData.name,
          description: contractData.description || null,
          typeId: editAndViewContractData.typeId,
          conclusionDate: contractData.conclusionDate
            ? dayjs(contractData.conclusionDate).format(
                "YYYY-MM-DDT00:00:00.000",
              ) + "Z"
            : null,
          endDate: contractData.endDate
            ? dayjs(contractData.endDate).format("YYYY-MM-DDT00:00:00.000") +
              "Z"
            : null,
          currencyId: editAndViewContractData.currencyId,
          contractAmount: contractData?.contractAmount ?? null,
          amountMonth: contractData?.amountMonth ?? null,
          status: contractStatus,
          note: contractData.note,
          parentId: editAndViewContractData.id,
        }),
      );

      if (
        response.meta.requestStatus === "fulfilled" &&
        response?.payload >= 0
      ) {
        sendNotification(t("notifications.successfullyCreated"), "success");
        localStorage.setItem("createdContractAdditionId", response.payload);
        reset(getValues());
        navigate(-1);
      }

      return;
    }

    if (
      editAndViewContractData &&
      params?.operation === "create" &&
      createdContractAdditionId
    ) {
      const response = await dispatch(
        editContractAddition({
          number: editAndViewContractData.number,
          employeeId: editAndViewContractData.employeeId,
          name: editAndViewContractData.name,
          description: contractData.description || null,
          typeId: editAndViewContractData.typeId,
          conclusionDate: contractData.conclusionDate
            ? dayjs(contractData.conclusionDate).format(
                "YYYY-MM-DDT00:00:00.000",
              ) + "Z"
            : null,
          endDate: contractData.endDate
            ? dayjs(contractData.endDate).format("YYYY-MM-DDT00:00:00.000") +
              "Z"
            : null,
          currencyId: editAndViewContractData.currencyId,
          contractAmount: contractData?.contractAmount ?? null,
          amountMonth: contractData?.amountMonth ?? null,
          status: editAndViewContractData.id,
          note: contractData.note,
          id: editAndViewContractData.id,
        }),
      );

      if (response.meta.requestStatus === "fulfilled") {
        sendNotification(t("notifications.successfullySaved"), "success");
        reset(getValues());
      }

      return;
    }

    if (
      editAndViewContractData &&
      params?.operation === "edit" &&
      params.contractId
    ) {
      const response = await dispatch(
        editContractAddition({
          number: editAndViewContractData.number,
          employeeId: editAndViewContractData.employeeId,
          name: editAndViewContractData.name,
          description: contractData.description || null,
          typeId: editAndViewContractData.typeId,
          conclusionDate: contractData.conclusionDate
            ? dayjs(contractData.conclusionDate).format(
                "YYYY-MM-DDT00:00:00.000",
              ) + "Z"
            : null,
          endDate: contractData.endDate
            ? dayjs(contractData.endDate).format("YYYY-MM-DDT00:00:00.000") +
              "Z"
            : null,
          currencyId: editAndViewContractData.currencyId,
          contractAmount: contractData?.contractAmount ?? null,
          amountMonth: contractData?.amountMonth ?? null,
          status: editAndViewContractData.id,
          note: contractData.note,
          id: editAndViewContractData.id,
        }),
      );

      if (response.meta.requestStatus === "fulfilled") {
        sendNotification(t("notifications.successfullySaved"), "success");
        reset(getValues());
      }
    }
  };

  useEffect(() => {
    if (params?.operation === "edit" && params?.contractId) {
      dispatch(getContractById(+params.contractId));
      return;
    }

    if (localStorage.getItem("contractDetailsWithAdditionsParentId")) {
      dispatch(
        getContractById(
          Number(localStorage.getItem("contractDetailsWithAdditionsParentId")),
        ),
      );
    }
  }, [params]);

  useEffect(() => {
    if (
      params?.operation === "edit" &&
      params?.contractId &&
      editAndViewContractData
    ) {
      //почему то reset не может засетать данные, но почему то setValue работает
      // if (editAndViewContractData.contractAmount) {
      //     setValue(
      //         "contractAmount",
      //         editAndViewContractData.contractAmount
      //     );
      // }
      //
      // if (editAndViewContractData.amountMonth) {
      //     setValue(
      //         "amountMonth",
      //         editAndViewContractData.amountMonth
      //     );
      // }

      reset({
        description: editAndViewContractData.description || null,
        conclusionDate: editAndViewContractData.conclusionDate
          ? dayjs(editAndViewContractData.conclusionDate).format(
              "YYYY-MM-DDT00:00:00.000",
            ) + "Z"
          : null,
        endDate: editAndViewContractData.endDate
          ? dayjs(editAndViewContractData.endDate).format(
              "YYYY-MM-DDT00:00:00.000",
            ) + "Z"
          : null,
        note: editAndViewContractData.note,
        contractAmount: editAndViewContractData?.contractAmount ?? null,
        amountMonth: editAndViewContractData?.amountMonth ?? null,
      });
    }
  }, [params, editAndViewContractData, reset]);

  useUpdateEffect(() => {
    if (
      amountMonth ||
      contractAmount ||
      amountMonth === 0 ||
      contractAmount === 0
    ) {
      clearErrors("contractAmount");
      clearErrors("amountMonth");
    }
  }, [amountMonth, contractAmount]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("createdContractAdditionId");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      localStorage.removeItem("createdContractAdditionId");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //     if (editAndViewContractData && editAndViewContractData.parentId) {
  //         dispatch(getMainContractById(editAndViewContractData.parentId));
  //     }
  // }, [editAndViewContractData]);

  useEffect(() => {
    return () => {
      dispatch(setDefaultValuesForContractData(null));
    };
  }, []);

  if (isGettingEditOrViewContractDataLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.contractDetailsCreateAndEdit}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Breadcrumbs
          sx={{
            padding: ".5rem 0 .5rem 0",
            width: "80%",
            margin: "0 auto",
          }}
        >
          <Typography sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}>
            Impulse
          </Typography>
          <Link
            to={`/${CONTRACTS_PATH}/${CONTRACT_EMPLOYEES_PATH}`}
            className={styles.breadScrumbsLink}
          >
            {t("contracts.contractsEmployees")}
          </Link>
          {editAndViewContractData?.number ? (
            <Typography
              sx={{
                color: "#201f1e",
                fontFamily: "Source Sans Pro",
              }}
            >
              {editAndViewContractData.number}
            </Typography>
          ) : null}
        </Breadcrumbs>
        <div className={styles.contractCreateFormContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.buttonsContainer}>
              <div>
                <span>
                  {editAndViewContractData?.number
                    ? t("contracts.contract") +
                      " " +
                      editAndViewContractData?.number
                    : null}{" "}
                  {editAndViewContractData?.parentId ||
                  mainContractConclusionDate
                    ? t("contracts.from") +
                      " " +
                      dayjs(
                        mainContractData?.conclusionDate ||
                          mainContractConclusionDate,
                      ).format("DD.MM.YYYY")
                    : null}
                </span>
              </div>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <ButtonWithProgress
                  loading={
                    isCreatingContractAdditionLoading ||
                    isEditingContractAdditionLoading
                  }
                  disabled={
                    isCreatingContractAdditionLoading ||
                    isEditingContractAdditionLoading ||
                    Boolean(createdContractAdditionId && !isDirty) ||
                    Boolean(Object.keys(errors).length)
                  }
                  variant="contained"
                  type="submit"
                  sx={{
                    fontFamily: "Source Sans Pro",
                    backgroundColor: "#205493",
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    lineHeight: "1.333rem",
                    color: "#fff",
                    fontWeight: "400",
                    padding: "0 0.7rem 0.1rem 0.7rem",
                    height: "2.5rem",
                  }}
                >
                  {t("buttons.Save")}
                </ButtonWithProgress>
                <Button
                  startIcon={<CloseOutlinedIcon />}
                  variant="contained"
                  onClick={() => {
                    if (isDirty) {
                      setConfirmationModalOpen(true);
                    } else {
                      navigate(-1);
                    }
                  }}
                  sx={{
                    fontFamily: "Source Sans Pro",
                    backgroundColor: "#205493",
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    lineHeight: "1.333rem",
                    color: "#fff",
                    fontWeight: "400",
                    padding: "0 0.7rem 0.1rem 0.7rem",
                    height: "2.5rem",
                  }}
                >
                  {t("buttons.Close")}
                </Button>
              </Box>
            </div>
            <div className={styles.contractDetailsCreateAndEditMainInfo}>
              <h2>{editAndViewContractData?.employeeName || null}</h2>
              <h3>{editAndViewContractData?.name || null}</h3>
              <span className={styles.contractType}>
                {editAndViewContractData?.type || null}
              </span>
            </div>
            <div className={styles.contractDetailsCreateAndEditInputWrapper}>
              <div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <label htmlFor="description">
                      {t("contracts.contractsDescription")}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "85%",
                      }}
                    >
                      <textarea
                        {...register("description", {
                          maxLength: {
                            value: 2048,
                            message: t("validation.maxLength2048"),
                          },
                        })}
                        autoComplete="off"
                        placeholder={
                          t("contracts.enterContractDescription") as string
                        }
                        id="description"
                        className={
                          errors?.description
                            ? styles.contractDescriptionError
                            : styles.contractDescription
                        }
                        rows={3}
                      />
                      <ErrorMessage
                        name={"description"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <span
                      className={styles.startDate}
                      style={{
                        color: "grey",
                        fontWeight: "700",
                      }}
                    >
                      {t("contracts.conclusionDate")}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "85%",
                      }}
                    >
                      <Controller
                        control={control}
                        name={"conclusionDate"}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            inputFormat="dd/MM/yyyy"
                            maxDate={watch("endDate")}
                            minDate={
                              location?.state?.date ||
                              mainContractConclusionDate
                            }
                            onError={(reason, inputValue) => {
                              const isMainAndAdditionContractConclusionDateTheSame =
                                dayjs(
                                  dayjs(inputValue).format("DD.MM.YYYY"),
                                ).isSame(
                                  mainContractData?.conclusionDate
                                    ? dayjs(
                                        mainContractData?.conclusionDate,
                                      ).format("DD.MM.YYYY")
                                    : dayjs(
                                        editAndViewContractData?.conclusionDate,
                                      ).format("DD.MM.YYYY"),
                                );
                              if (
                                dayjs(inputValue).isValid() &&
                                dayjs(inputValue).isBefore("01/01/2000")
                              ) {
                                setError("conclusionDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.minDateErrorMessage",
                                  ) as string,
                                });
                                return;
                              }

                              if (
                                dayjs(endDate).isValid() &&
                                dayjs(inputValue).isAfter(endDate)
                              ) {
                                setError("conclusionDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.contractConclusionMaxDateError",
                                  ) as string,
                                });
                                return;
                              }

                              if (
                                dayjs(inputValue).isValid() &&
                                editAndViewContractData?.conclusionDate &&
                                !isMainAndAdditionContractConclusionDateTheSame &&
                                dayjs(inputValue).isBefore(location.state.date)
                              ) {
                                setError("conclusionDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.contractAdditionConclusionMinDateError",
                                  ) as string,
                                });
                                return;
                              }

                              if (inputValue && !dayjs(inputValue).isValid()) {
                                setError("conclusionDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.invalidDate",
                                  ) as string,
                                });
                                return;
                              }

                              if (!editAndViewContractData?.conclusionDate) {
                                setError("conclusionDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.contractAdditionConclusionMainContractDateError",
                                  ) as string,
                                });
                                if (!conclusionDate) {
                                  clearErrors("conclusionDate");
                                }

                                return;
                              }

                              if (reason === null) {
                                clearErrors("conclusionDate");
                              }
                            }}
                            PopperProps={{
                              placement: "auto",
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                sx={{
                                  "& input::placeholder": {
                                    fontFamily: "Source Sans Pro",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    lineHeight: 1.5,
                                    color: "#495057",
                                    opacity: 0.8,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "0",
                                    border: errors?.conclusionDate
                                      ? "1px solid #dc3545"
                                      : "1px solid #8A8886",
                                    transition:
                                      "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      border: errors?.conclusionDate
                                        ? "1px solid #dc3545"
                                        : "1px solid #8A8886",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: errors?.conclusionDate
                                        ? "#dc3545"
                                        : "#80bdff",
                                      boxShadow: errors?.conclusionDate
                                        ? "0 0 0 0.2rem rgba(220, 53, 69, .25)"
                                        : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                      borderWidth: "1px",
                                    },
                                  },
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: t(
                                    "projects.dayMonthYear",
                                  ) as string,
                                  autoComplete: "off",
                                }}
                              />
                            )}
                          />
                        )}
                        rules={{
                          // required: t(
                          //     "validation.requiredField",
                          // ) as string,
                          validate: (value) => {
                            const isMainAndAdditionContractConclusionDateTheSame =
                              dayjs(dayjs(value).format("DD.MM.YYYY")).isSame(
                                dayjs(location.state.date).format("DD.MM.YYYY"),
                              );

                            if (
                              dayjs(value).isValid() &&
                              dayjs(value).isBefore("01/01/2000")
                            ) {
                              return t(
                                "validation.minDateErrorMessage",
                              ) as string;
                            }

                            if (
                              dayjs(endDate).isValid() &&
                              dayjs(value).isAfter(endDate)
                            ) {
                              return t(
                                "validation.contractConclusionMaxDateError",
                              ) as string;
                            }

                            if (
                              dayjs(value).isValid() &&
                              editAndViewContractData?.conclusionDate &&
                              !isMainAndAdditionContractConclusionDateTheSame &&
                              dayjs(value).isBefore(location?.state?.date)
                            ) {
                              return t(
                                "validation.contractAdditionConclusionMinDateError",
                              ) as string;
                            }

                            if (value && !dayjs(value).isValid()) {
                              return t("validation.invalidDate") as string;
                            }

                            // if (
                            //     !editAndViewContractData?.conclusionDate
                            // ) {
                            //     return t(
                            //         "validation.contractAdditionConclusionMainContractDateError",
                            //     ) as string;
                            // }
                          },
                        }}
                      />
                      <ErrorMessage
                        name="conclusionDate"
                        errors={errors}
                        render={({ message }) => (
                          <span
                            className={styles.contractConclusionDateErrorMsg}
                          >
                            {message}
                          </span>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <span
                      className={styles.startDate}
                      style={{
                        color: "grey",
                        fontWeight: "700",
                      }}
                    >
                      {t("contracts.endDate")}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "85%",
                      }}
                    >
                      <Controller
                        control={control}
                        name={"endDate"}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            minDate={
                              watch("conclusionDate") ||
                              mainContractConclusionDate
                            }
                            onError={(reason, inputValue) => {
                              if (
                                dayjs(inputValue).isValid() &&
                                dayjs(inputValue).isBefore("01/01/2000")
                              ) {
                                setError("endDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.minDateErrorMessage",
                                  ) as string,
                                });
                              }

                              if (
                                dayjs(inputValue).isValid() &&
                                dayjs(inputValue).isBefore(conclusionDate)
                              ) {
                                setError("endDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.contractEndMinDateError",
                                  ) as string,
                                });
                              }

                              if (!dayjs(inputValue).isValid()) {
                                setError("endDate", {
                                  type: "custom",
                                  message: t(
                                    "validation.invalidDate",
                                  ) as string,
                                });
                              }

                              if (reason === null) {
                                clearErrors("endDate");
                              }
                            }}
                            inputFormat="dd/MM/yyyy"
                            PopperProps={{
                              placement: "auto",
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                sx={{
                                  "& input::placeholder": {
                                    fontFamily: "Source Sans Pro",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    lineHeight: 1.5,
                                    color: "#495057",
                                    opacity: 0.8,
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "0",
                                    border: errors?.endDate
                                      ? "1px solid #dc3545"
                                      : "1px solid #8A8886",
                                    transition:
                                      "border-color .15s ease-in-out, box-shadow .15s ease-in-out",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      border: errors?.endDate
                                        ? "1px solid #dc3545"
                                        : "1px solid #8A8886",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: errors?.endDate
                                        ? "#dc3545"
                                        : "#80bdff",
                                      boxShadow: errors?.endDate
                                        ? "0 0 0 0.2rem rgba(220, 53, 69, .25)"
                                        : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                      borderWidth: "1px",
                                    },
                                  },
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: t(
                                    "projects.dayMonthYear",
                                  ) as string,
                                  autoComplete: "off",
                                }}
                              />
                            )}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="endDate"
                        errors={errors}
                        render={({ message }) => (
                          <span className={styles.contractEndDateErrorMsg}>
                            {message}
                          </span>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <label htmlFor="contractAmount">
                      {t("contracts.contractAmount")}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // width: "85%",
                      }}
                    >
                      <Controller
                        render={({
                          field: { name, onChange, onBlur, value, ref },
                        }) => (
                          <NumericFormat
                            // getInputRef={ref}
                            size="small"
                            variant="outlined"
                            sx={{
                              "& input::placeholder": {
                                fontFamily: "Source Sans Pro",
                                fontSize: "1rem",
                                fontWeight: "400",
                                lineHeight: 1.5,
                                color: "#495057",
                                opacity: 0.8,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: errors.contractAmount
                                  ? "1px solid #dc3545"
                                  : "1px solid #8A8886",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  border: errors?.contractAmount
                                    ? "1px solid #dc3545"
                                    : "1px solid #8A8886",
                                },
                                "&.Mui-focused fieldset": {
                                  border: errors?.contractAmount
                                    ? "1px solid #dc3545"
                                    : "1px solid #80bdff",
                                  boxShadow: errors?.contractAmount
                                    ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                    : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                },
                              },
                            }}
                            value={value ?? ""}
                            onBlur={onBlur}
                            name={name}
                            onValueChange={(values) => {
                              onChange(values.floatValue);
                              setValue("amountMonth", null);
                            }}
                            className={
                              errors?.contractAmount
                                ? styles.contractAmountError
                                : styles.contractAmount
                            }
                            thousandSeparator=" "
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            //TODO: сумма должна быть больше нуля
                            isAllowed={(values) => {
                              const { formattedValue } = values;
                              return formattedValue.length <= 18;
                            }}
                            customInput={TextField}
                          />
                        )}
                        name={"contractAmount"}
                        control={control}
                        rules={{
                          required:
                            contractAmount ||
                            amountMonth ||
                            contractAmount === 0 ||
                            amountMonth === 0
                              ? false
                              : (t(
                                  "validation.contractAmountOrAmountMonth",
                                ) as string),
                        }}
                      />
                      <ErrorMessage
                        name={"contractAmount"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ textAlign: "left" }}>
                    <span>{editAndViewContractData?.currency}</span>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <label htmlFor="amountMonth">
                      {t("contracts.amountMonth")}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // width: "85%",
                      }}
                    >
                      <Controller
                        render={({
                          field: { name, onChange, onBlur, value, ref },
                        }) => (
                          <NumericFormat
                            // getInputRef={ref}
                            size="small"
                            variant="outlined"
                            sx={{
                              "& input::placeholder": {
                                fontFamily: "Source Sans Pro",
                                fontSize: "1rem",
                                fontWeight: "400",
                                lineHeight: 1.5,
                                color: "#495057",
                                opacity: 0.8,
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "0",
                                border: errors.amountMonth
                                  ? "1px solid #dc3545"
                                  : "1px solid #8A8886",
                              },
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  border: errors?.amountMonth
                                    ? "1px solid #dc3545"
                                    : "1px solid #8A8886",
                                },
                                "&.Mui-focused fieldset": {
                                  border: errors?.amountMonth
                                    ? "1px solid #dc3545"
                                    : "1px solid #80bdff",
                                  boxShadow: errors?.amountMonth
                                    ? "0 0 0 0.2rem rgba(220,53,69,.25)"
                                    : "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                                },
                              },
                            }}
                            value={value ?? ""}
                            onBlur={onBlur}
                            name={name}
                            onValueChange={(values) => {
                              onChange(values.floatValue);
                              setValue("contractAmount", null);
                            }}
                            className={
                              errors?.amountMonth
                                ? styles.contractAmountError
                                : styles.contractAmountMonth
                            }
                            thousandSeparator=" "
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            isAllowed={(values) => {
                              const { formattedValue } = values;
                              return formattedValue.length <= 18;
                            }}
                            customInput={TextField}
                          />
                        )}
                        name={"amountMonth"}
                        control={control}
                        rules={{
                          required:
                            contractAmount ||
                            amountMonth ||
                            contractAmount === 0 ||
                            amountMonth === 0
                              ? false
                              : (t(
                                  "validation.contractAmountOrAmountMonth",
                                ) as string),
                        }}
                      />
                      <ErrorMessage
                        name={"amountMonth"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ textAlign: "left" }}>
                    <span>{editAndViewContractData?.currency}</span>
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2} sx={{ textAlign: "right" }}>
                    <label htmlFor="note">{t("contracts.note")}</label>
                  </Grid>
                  <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "85%",
                      }}
                    >
                      <textarea
                        {...register("note", {
                          maxLength: {
                            value: 512,
                            message: t("validation.maxLength512"),
                          },
                        })}
                        autoComplete="off"
                        placeholder={t("contracts.enterNote") as string}
                        id="note"
                        className={
                          errors?.note
                            ? styles.contractNoteError
                            : styles.contractNote
                        }
                        rows={3}
                      />
                      <ErrorMessage
                        name={"note"}
                        errors={errors}
                        render={({ message }) => <span>{message}</span>}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </form>
        </div>
      </LocalizationProvider>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setConfirmationModalOpen}
        actionName={"Close"}
        instanceName=""
        message={"closeFormWithoutSaving"}
        handleAction={closeModalConfirm}
      />
    </div>
  );
};

export default ContractDetailsCreateAndEdit;
