import { CSSProperties, FC, PropsWithChildren } from "react";
import cn from "classnames";

import styles from "./styles.module.css";

export enum ETextVariant {
    REGULAR = "REGULAR",
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    BOLD = "BOLD",
}

type TextVariant = "REGULAR" | "SMALL" | "MEDIUM" | "BOLD";

interface TextProps {
    variant: ETextVariant | TextVariant;
    size?: number;
    className?: string;
    style?: CSSProperties;
    onClick?: (
        event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    ) => void;
}

export const Text: FC<PropsWithChildren<TextProps>> = (props) => {
    const { variant, children, size, className, style, onClick } = props;

    return (
        <p
            onClick={onClick}
            style={{
                fontSize: size,
                ...style,
            }}
            className={cn(
                styles.text,
                {
                    [styles.small]: variant === ETextVariant.SMALL,
                    [styles.regular]: variant === ETextVariant.REGULAR,
                    [styles.medium]: variant === ETextVariant.MEDIUM,
                    [styles.bold]: variant === ETextVariant.BOLD,
                },
                className,
            )}
        >
            {children}
        </p>
    );
};
