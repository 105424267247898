import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import closeIcon from "../../assets/icons/closeIcon.svg";
import { useAppDispatch } from "../../hooks";
import { IDocument } from "../../models/documents";
import { editDocument, getOrganization, uploadDocument } from "../../store";
import {
  activateOrDeactivateBtn,
  activateOrDeactivateModal,
  cancelBtn,
} from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";
import { sendNotification } from "../../ui/Toast";
import FileInput from "../FileInput/FileInput";

import styles from "../../views/Catalogs/CatalogsLayout.module.scss";

const DocumentModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: Function;
  actionName: string;
  document: IDocument | null;
  instanceName: string;
  activeEntityId: string | number;
  editDocumentsList: (document: IDocument, action: string) => void;
}> = ({
  isModalOpen,
  setIsModalOpen,
  actionName,
  document,
  instanceName,
  activeEntityId,
  editDocumentsList,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    control,
  } = useForm<IDocument>();

  const setFormData = (fields: IDocument | null) => {
    setValue("id", fields?.id || null);
    setValue("name", fields?.name || "");
    setValue("description", fields?.description || "");
    setValue("attachment", fields?.attachment || null);
  };

  const getFormData = () => {
    return {
      id: getValues("id") ?? +activeEntityId,
      name: getValues("name"),
      description: getValues("description"),
      attachment: getValues("attachment"),
      createdTimeUtc: new Date().toISOString(),
    };
  };

  const invalidSubmit = (errors: any, event: any) => {
    // event.preventDefault();
    event.stopPropagation();
  };

  const onSubmit = async () => {
    if (getValues("id")) {
      const response = await dispatch(
        editDocument({ ...getFormData(), endpoint: instanceName }),
      );
      if (response.meta.requestStatus === "fulfilled") {
        editDocumentsList(getFormData(), "edit");
        sendNotification(t("notifications.successfullySaved"), "success");
        dispatch(getOrganization(Number(activeEntityId!)));
      }
      if (response.meta.requestStatus === "rejected") {
        sendNotification(t("errors.docNotSaved"), "error");
      }
    } else {
      const response = await dispatch(
        uploadDocument({
          ...getFormData(),
          id: activeEntityId,
          endpoint: instanceName,
        }),
      );
      if (response.meta.requestStatus === "fulfilled") {
        editDocumentsList({ ...getFormData(), id: response.payload }, "add");
        sendNotification(t("notifications.successfullySaved"), "success");
        dispatch(getOrganization(Number(activeEntityId!)));
      }
      if (response.meta.requestStatus === "rejected") {
        sendNotification(t("errors.docNotSaved"), "error");
      }
    }
    setIsModalOpen(false);
    setFormData(null);
  };

  const closeForm = () => {
    setIsModalOpen(false);
    setFormData(null);
  };

  useEffect(() => {
    if (isModalOpen && document) {
      setFormData({ ...document });
    }
  }, [isModalOpen, document]);

  useEffect(() => {
    if (!isModalOpen) {
      clearErrors();
    }
  }, [isModalOpen]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen || false}
        onClose={closeForm}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={activateOrDeactivateModal}>
            <div className={styles.activateModalTopWrapper}>
              <h3 className={styles.activateModalTitle}>
                {t(`buttons.${actionName}`)}{" "}
                {t("catalogActions.documents") || " "}
              </h3>
              <img src={closeIcon} alt="" onClick={closeForm} />
            </div>

            <form onSubmit={handleSubmit(onSubmit, invalidSubmit)}>
              <label htmlFor="name" className={styles.drawerTopSubTitle}>
                {t("data.name")}
              </label>
              <div>
                <input
                  {...register("name", {
                    required: t("validation.requiredField") as string,
                    maxLength: {
                      value: 100,
                      message: t("validation.maxLength100"),
                    },
                  })}
                  id="name"
                  type="text"
                  autoComplete="off"
                  placeholder={t("data.name") as string}
                  className={
                    errors?.name
                      ? styles.catalogsDrawerInputError
                      : styles.catalogsDrawerInput
                  }
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({ message }) => <span>{message}</span>}
                />
              </div>

              <label htmlFor="description" className={styles.drawerTopSubTitle}>
                {t("data.description")}
              </label>
              <div>
                <input
                  {...register("description", {})}
                  id="description"
                  type="text"
                  autoComplete="off"
                  placeholder={t("data.description") as string}
                  className={
                    errors?.description
                      ? styles.catalogsDrawerInputError
                      : styles.catalogsDrawerInput
                  }
                />
                <ErrorMessage
                  name="description"
                  errors={errors}
                  render={({ message }) => <span>{message}</span>}
                />
              </div>
              <FileInput name="attachment" errors={errors} control={control} />
              <div className={styles.activateModalButtonWrapper}>
                <Button
                  onClick={() => {
                    setIsModalOpen(false);
                    setFormData(null);
                  }}
                  variant="text"
                  sx={{ ...cancelBtn, margin: "5px" }}
                >
                  {t("buttons.Cancel")}
                </Button>
                <ButtonWithProgress
                  loading={false}
                  disabled={false}
                  variant="contained"
                  type="submit"
                  sx={activateOrDeactivateBtn}
                >
                  {t(`buttons.${actionName}`)}
                </ButtonWithProgress>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DocumentModal;
