import React, { FC, memo, useState } from 'react';
import dayjs from 'dayjs';
import { IDocument } from 'models/documents';
import { useTranslation } from "react-i18next";

import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import deleteIcon from '../../assets/icons/delete.svg';
import download from '../../assets/icons/download.svg';
import editIcon from '../../assets/icons/hoverEditIcon.svg';
import axiosApi from '../../core/request';
import { ICommonDocument } from '../../models/commonDocument';
import { materialReactTableCell } from '../../styles/MUIStyles';
import AgGrid from '../AgGrid/AgGrid';
import Guard from "../Guard";

import { DeleteFile } from './components/DeleteFile';
import { EditFile } from './components/EditFile';

import styles from './styles.module.css';

interface DocumentsListProps {
    documents: IDocument[];
    isPersonal?: boolean;
    allowedPermissions?: any[];
}

export const DocumentsList: FC<DocumentsListProps> = memo(({ documents, isPersonal = false, allowedPermissions }) => {
    const [isOpenEditFile, setIsOpenEditFile] = useState(false);
    const [isOpenDeleteFile, setIsOpenDeleteFile] = useState(false);
    const [currentDoc, setCurrentDoc] = useState<null | ICommonDocument>(null);
    const openEditFileModal = (docData: any) => {
        setCurrentDoc(docData);
        setIsOpenEditFile(true);
    };

    const openDeleteFileModal = (docData: any) => {
        setCurrentDoc(docData);
        setIsOpenDeleteFile(true);
    };

    const { t } = useTranslation();

    const downloadDocument = async (docData: any, isPersonal: boolean) => {
        try {
            const currentPath = window.location.pathname;
            let apiUrl;

            if (currentPath.includes('common-info')) {
                apiUrl = isPersonal
                    ? `/personal-cabinet/documents/my-docs/${docData?.id}/download`
                    : `/personal-cabinet/documents/common-docs/${docData?.id}/download`;
            } else {
                apiUrl = `/common-documents/${docData?.id}/download`;
            }

            const response = await axiosApi.get(apiUrl, {
                responseType: 'blob',
            });

                const contentDisposition = response.headers['content-disposition'];
                let fileNameWithExtension;

                // Если есть поле filename*=UTF-8
                if (contentDisposition.includes("filename*=UTF-8''")) {
                    fileNameWithExtension = decodeURIComponent(
                        contentDisposition
                            .split("filename*=UTF-8''")[1]
                            .split(';')[0]
                    );
                } else {
                    fileNameWithExtension = contentDisposition
                        .split('filename=')[1]
                        .split(';')[0]
                        .replace(/"/g, '');
                }    
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', decodeURI(fileNameWithExtension));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } catch (error) {
                    console.log(error);
                }
            };

    const RowComponent = (p: any) => {

        switch (p.colDef.field) {
            case 'customActions':
                return (
                    <div>
                        {!isPersonal && (
                            <Guard
                                allowedPermissions={allowedPermissions || []}
                                permissionName="Edit"
                            >
                                <img
                                    onClick={() => openEditFileModal(p.data)}
                                    className={styles.icon}
                                    src={editIcon}
                                    alt="edit"
                                />
                            </Guard>
                        )}
                        <Guard
                            allowedPermissions={allowedPermissions || []}
                            permissionName="DownloadFile"
                        >
                            <img
                                onClick={() => downloadDocument(p.data, isPersonal)}
                                className={styles.icon}
                                src={download}
                                alt="download"
                            />
                        </Guard>
                        <Guard
                            allowedPermissions={allowedPermissions || []}
                            permissionName="Delete"
                        >
                            <img
                                onClick={() => openDeleteFileModal(p.data)}
                                className={styles.icon}
                                src={deleteIcon}
                                alt="delete"
                            />
                        </Guard>
                    </div>
                );
            case 'name':
                return (
                    <Box sx={materialReactTableCell}>
                        <div
                            // href="#"
                            // onClick={() => viewDocument(p.data, currentPath.includes('common-info'))}
                        >
                            {p.data.name}
                        </div>
                    </Box>
                );
            case 'description':
                return (
                    <Box sx={materialReactTableCell}>{p.data.description}</Box>
                );
            case 'originalFileName':
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.originalFileName}
                    </Box>
                );

            case 'createdTimeUtc':
                return (
                    <Box sx={materialReactTableCell}>
                        {dayjs(p.data.createdTimeUtc).format('DD.MM.YYYY')}
                    </Box>
                );
            default:
                return null;
        }
    };

    const generateColumnDefs = () => {
        const updatedColumnDefs: any[] = [
            {
                headerName: t('documents.documentName'),
                field: 'name',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 3,
            },
            {
                headerName: t('projects.documentDescription'),
                field: 'description',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 2,
            },
            {
                headerName: t('documents.documentOriginalName'),
                field: 'originalFileName',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 2,
            },
            {
                headerName: t('documents.documentDate'),
                field: 'createdTimeUtc',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 1,
            },
            {
                headerName: t('data.actions'),
                field: 'customActions',
                resizable: true,
                cellRenderer: RowComponent,
                flex: 1,
            },
        ];

        return updatedColumnDefs;
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <div>
            <AgGrid
                rowData={documents}
                columnDefs={columnDefs}
                activeRow={null}
                height={'35vh'}
                headerHeight={35}
            />

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isOpenEditFile}
                    onClose={() => setIsOpenEditFile(false)}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box>
                        <EditFile
                            isPersonal={isPersonal}
                            setIsOpenEditFile={setIsOpenEditFile}

                            id={currentDoc?.id || 0}
                            currentDoc={currentDoc}
                        />
                    </Box>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={isOpenDeleteFile}
                    onClose={() => setIsOpenDeleteFile(false)}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Box>
                        <DeleteFile
                            isPersonal={isPersonal}
                            setIsOpenDeleteFile={setIsOpenDeleteFile}
                            id={currentDoc?.id || 0}
                            originalFileName={currentDoc?.originalFileName || ''}
                        />
                    </Box>
                </Modal>
            </div>

        </div>
    );
});
