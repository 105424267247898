// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__XcL4a {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 16px;\n}\n\n.styles_responsibleMenuItem__bIEej {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\n\n.styles_responsiblePost__w77xR {\n  color: #495057;\n  opacity: .7;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/PersonalAccount/PersonalInfo/MyRequests/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,eAAe;AACjB","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 16px;\n}\n\n.responsibleMenuItem {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\n\n.responsiblePost {\n  color: #495057;\n  opacity: .7;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__XcL4a",
	"responsibleMenuItem": "styles_responsibleMenuItem__bIEej",
	"responsiblePost": "styles_responsiblePost__w77xR"
};
export default ___CSS_LOADER_EXPORT___;
