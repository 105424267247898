import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";

import styles from "./FileInput.module.scss";

interface Props {
  name: string;
  errors: any;
  isOpen?: boolean;
  control: any;
  isCreateOrEditing?: "edit" | "create" | null;
}

const FileInput: React.FC<Props> = (props) => {
  const { control, isOpen, name, errors } = props;
  const { t } = useTranslation();

  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      setFileName(null);
    }
  }, [isOpen]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (files) => {
              if (!files || files.length === 0) {
                  return t('validation.requiredField') as string;
              }
              return true; 
          },
        }}
        render={({ field }) => (
          <>
            <input
              {...field}
              type="file"
              id="customFileInput"
              value={undefined}
              style={{ display: "none" }}
              onChange={(e) => {
                field.onChange(e.target.files);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  if (file) {
                    setFileName(file.name);
                  } else {
                    setFileName(null);
                  }
                }
              }}
            />
            <label htmlFor="customFileInput" className={styles.fileInputLabel}>
              {t("projects.chooseFile")}
            </label>
            <label
              className={styles.fileInputDescription}
              htmlFor="customFileInput"
            >
              {fileName ? fileName : t("projects.chooseFile")}
            </label>
          </>
        )}
      />
      <ErrorMessage
        errors={errors}
        name="file"
        render={({ message }) => (
          <span style={{ display: "block" }}
          className={styles.fileInputErrorMsg}>{message}</span>
        )}
      />
    </>
  );
};

export default FileInput;
