import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getAllSkillLevels,
    getAllSkills,
    getPersonalInfoEmployeeById,
} from "../../store";
import { menuBtn } from "../../styles/MUIStyles";

import styles from "./styles.module.scss";

export const PersonalAccount = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigator = useNavigate();

    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const userId = useAppSelector((state) => state.auth.userId);

    useEffect(() => {
        (async () => {
            if (userId) {
                await Promise.all([
                    dispatch(getPersonalInfoEmployeeById(userId)),
                    dispatch(getAllSkills()),
                    dispatch(getAllSkillLevels()),
                ]);
            }
        })();
    }, [dispatch, userId, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "PersonalCabinet");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/personal-cabinet`,
        );

        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    return (
        <Box sx={{ width: "50%", padding: "10px 0" }}>
            <Typography sx={{ margin: "20px", fontWeight: "900" }}>
                {t("account.hello")}, {personalInfoEmployeeDetails?.firstName}!{" "}
            </Typography>
            <Typography sx={{ margin: "20px" }}>
                {" "}
                {t("account.youhave")} {t("account.newnotifications")}{" "}
            </Typography>
            <hr></hr>

            <Grid container spacing={2} sx={{ width: "60%" }}>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        style={{
                            border: "1px solid #998d8d",
                            marginTop: 25,
                            marginLeft: 25,
                            marginRight: 50,
                        }}
                        sx={menuBtn}
                        onClick={() =>
                            navigator("/personalcabinet/common-info")
                        }
                        variant="text"
                    >
                        {t("account.personalInfo")}
                    </Button>
                </Grid>
                {/* <Grid item xs={6}>
                    <Button
                        fullWidth
                        style={{
                            border: "1px solid #998d8d",
                            marginTop: 25,
                            marginLeft: 25,
                            marginRight: 50,
                        }}
                        sx={menuBtn}
                        onClick={() =>
                            navigator("/personalcabinet/personal-documents")
                        }
                        variant="text"
                    >
                        {t("account.documents")}
                    </Button>
                </Grid> */}
                <Grid item xs={6}>
                    <Button
                        classes={{
                            disabled: styles.buttonDisabled,
                        }}
                        disabled
                        fullWidth
                        style={{
                            border: "1px solid #998d8d",
                            marginTop: 25,
                            marginLeft: 25,
                            marginRight: 50,
                        }}
                        sx={menuBtn}
                        onClick={() =>
                            navigator("/personalcabinet/applications")
                        }
                        variant="text"
                    >
                        {t("account.applications")}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        classes={{
                            disabled: styles.buttonDisabled,
                        }}
                        disabled
                        fullWidth
                        style={{
                            border: "1px solid #998d8d",
                            marginTop: 25,
                            marginLeft: 25,
                        }}
                        sx={menuBtn}
                        onClick={() => navigator("/personalcabinet/calendar")}
                        variant="text"
                    >
                        {t("account.calendar")}
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        classes={{
                            disabled: styles.buttonDisabled,
                        }}
                        disabled
                        fullWidth
                        style={{
                            border: "1px solid #998d8d",
                            marginTop: 25,
                            marginLeft: 25,
                            marginRight: 50,
                        }}
                        sx={menuBtn}
                        onClick={() =>
                            navigator("/personalcabinet/notifications")
                        }
                        variant="text"
                    >
                        {t("account.notifications")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
