import * as React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import axiosApi from "core/request";
import dayjs, { type Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import VacationConflictModal from "features/VacationConflictModal";
import { useAppSelector } from "hooks";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sendNotification } from "ui/Toast";

import AdapterDayjs from "@date-io/date-fns";
import { ErrorMessage } from "@hookform/error-message";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
dayjs.extend(isBetween);

import styles from "./styles.module.scss";

interface RequestProps {
  toggleDrawer: (_: boolean) => () => void;
  setSelectedRow: React.Dispatch<any>;
  data?: {
    fullName: string;
    requestType: string;
    responsible?: string;
    otherRequestType?: string;
    file?: File[] | null;
    startDate?: string | null;
    endDate?: string | null;
  };
}

export const Request = ({
  toggleDrawer,
  data,
  setSelectedRow,
}: RequestProps) => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: data || {
      fullName: "",
      requestType: "",
      responsible: "",
      otherRequestType: "",
      file: null,
      startDate: null,
      endDate: null,
    },
  });
  const { t } = useTranslation();
  const employeeId = useAppSelector((state) => state.auth.userId);

  useEffect(() => {
    return () => {
      setSelectedRow(null);
    };
  }, []);

  const [existingDaysOff, setExistingDaysOff] = useState<any[]>([]);
  const [existingVacations, setExistingVacations] = useState<any[]>([]);
  const [conflictingHolidays, setConflictingHolidays] = useState<string[]>([]);
  const [tempFormData, setTempFormData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [myApprovers, setMyApprovers] = useState<{
    approverId: string;
    email: string;
    name: string;
  }[]>([]);

  const getMyApprovers = async () => {
    try {
      const response = await axiosApi.get("/personal-cabinet/calendar/daysoff/requests/myapprovers");
      console.log(response.data);
      setMyApprovers(response.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const fetchHolidays = async () => {
    setIsLoading(true);
    try {
      const response = await axiosApi.get("/personal-cabinet/calendar", {
        params: { employeeId },
      });
      setExistingDaysOff(response.data.filter((item: any) => item.type === 3));
      setExistingVacations(
        response.data.filter((item: any) => item.type === 1),
      );
    } catch (error) {
      console.error("Error fetching holidays:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHolidays();
    getMyApprovers();
  }, [employeeId]);

  const checkHolidaysConflict = (startDate: Dayjs, endDate: Dayjs) => {
    const holidaysInRange = existingDaysOff.filter((holiday) => {
      const holidayDate = dayjs(holiday.startDate);
      return holidayDate.isBetween(startDate, endDate, null, "[]");
    });

    return holidaysInRange.map((holiday) =>
      dayjs(holiday.startDate).format("DD.MM.YYYY"),
    );
  };

  const checkVacationConflicts = (startDate: Dayjs, endDate: Dayjs) => {
    return existingVacations.filter((vacation) => {
      const vacStart = dayjs(vacation.startDate);
      const vacEnd = dayjs(vacation.finishDate);

      return (
        startDate.isBetween(vacStart, vacEnd, "day", "[]") ||
        endDate.isBetween(vacStart, vacEnd, "day", "[]") ||
        (startDate.isBefore(vacStart) && endDate.isAfter(vacEnd))
      );
    });
  };

  const submitVacationRequest = async (
    formData: any,
    holidaysCount: number,
    adjustmentType: "extend" | "reduce" | "none",
  ) => {
    try {
      const startDate = dayjs(formData.startDate);
      let endDate = dayjs(formData.endDate);
      let pureDays = endDate.diff(startDate, "day") + 1;

      switch (adjustmentType) {
        case "extend":
          endDate = endDate.add(holidaysCount, "day");
          break;
        case "reduce":
          pureDays -= holidaysCount;
          break;
        case "none":
          break;
      }

      const formatDateForServer = (date: Dayjs) => {
        const localDate = date.startOf("day");
        const offset = localDate.utcOffset();
        return localDate
          .utc()
          .add(offset, "minute")
          .format("YYYY-MM-DDTHH:mm:ss[Z]");
      };

      const selectedApprover = myApprovers.find(approver => approver.name === formData.responsible)?.approverId;

      const requestBody = {
        employeeId: employeeId,
        daysOffDto: [
          {
            startDate: formatDateForServer(startDate),
            finishDate: formatDateForServer(endDate),
            sign: 1,
            remark: formData.fullName || null,
            pureDays: pureDays,
            nextApproverId: selectedApprover,
          },
        ],
      };

      await axiosApi.post("/personal-cabinet/calendar/daysoff", requestBody);
      sendNotification(t("requests.notification.createSuccess"), "success");
      reset();
      toggleDrawer(false)();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors?.[""]?.[0];
      sendNotification(t("requests.notification.createError"), "error");
      if (errorMessage) {
        sendNotification(errorMessage, "error");
      }
    }
  };

  const handleExtendVacation = async () => {
    await submitVacationRequest(
      tempFormData,
      conflictingHolidays.length,
      "extend",
    );
    setIsModalOpen(false);
  };

  const handleReduceDays = async () => {
    await submitVacationRequest(
      tempFormData,
      conflictingHolidays.length,
      "reduce",
    );
    setIsModalOpen(false);
  };

  const onSubmit = async (formData: any) => {
    const startDate = dayjs(formData.startDate);
    const endDate = dayjs(formData.endDate);

    if (endDate.isBefore(startDate)) {
      sendNotification(t("requests.notification.endBeforeStart"), "error");
      return;
    }
    const vacationConflicts = checkVacationConflicts(startDate, endDate);
    if (vacationConflicts.length > 0) {
      sendNotification(
        t("requests.notification.alreadyExists", {
          count: vacationConflicts.length,
          start: dayjs(vacationConflicts[0].startDate).format("DD.MM.YYYY"),
          end: dayjs(vacationConflicts[0].finishDate).format("DD.MM.YYYY"),
        }),
        "error",
      );
      return;
    }

    const conflicts = checkHolidaysConflict(startDate, endDate);
    if (conflicts.length > 0) {
      setConflictingHolidays(conflicts);
      setTempFormData(formData);
      setIsModalOpen(true);
    } else {
      await submitVacationRequest(formData, 0, "none");
    }
  };

  return (
    <Box
      className={styles.container}
      role="presentation"
      onClick={(event) => event.stopPropagation()}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.formElementWrapper}>
          <TextField
            placeholder={t("requests.createRequest.requestName") as string}
            variant="outlined"
            fullWidth
            {...register("fullName", {
              required: t("validation.requiredField") as string,
            })}
            error={!!errors.fullName}
          />
          <ErrorMessage
            errors={errors}
            name="fullName"
            render={({ message }) => (
              <span className={styles.errorMsg}>{message}</span>
            )}
          />
        </div>

        <FormControl fullWidth>
          <Controller
            name="requestType"
            control={control}
            rules={{
              required: t("requests.createRequest.enterRequestType") as string,
            }}
            render={({ field }) => (
              <div className={styles.formElementWrapper}>
                <Select
                  error={!!errors.requestType}
                  {...field}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "#aaa" }}>
                          {t("requests.createRequest.requestType")}
                        </span>
                      );
                    }
                    return selected;
                  }}
                >
                  {/* <MenuItem value="Запрос оборудования">Запрос на оборудование</MenuItem> */}
                  {/* <MenuItem value="Командировка">Командировка</MenuItem> */}
                  <MenuItem value={t("requests.dayOfType.vacation")}>
                    {t("requests.dayOfType.vacation")}
                  </MenuItem>
                  {/* <MenuItem value="Другое">Другое</MenuItem> */}
                </Select>
                <ErrorMessage
                  name="requestType"
                  errors={errors}
                  render={({ message }) => (
                    <span className={styles.errorMsg}>{message}</span>
                  )}
                />
              </div>
            )}
          />
        </FormControl>

        {watch("requestType") === "Другое" && (
          <div className={styles.formElementWrapper}>
            <TextField
              error={!!errors.requestType}
              placeholder="Напишите тип заявки"
              variant="outlined"
              fullWidth
              {...register("otherRequestType", {
                required: "Укажите тип заявки",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="otherRequestType"
              render={({ message }) => (
                <span className={styles.errorMsg}>{message}</span>
              )}
            />
          </div>
        )}

        <FormControl fullWidth>
          <Controller
            name="responsible"
            control={control}
            rules={{ required: 'Выберите ответственного' }}
            render={({ field }) => (
              <div className={styles.formElementWrapper}>
                <Select
                  error={!!errors.requestType}
                  {...field}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span style={{ color: '#aaa' }}>Ответственный</span>;
                    }
                    return selected;
                  }}
                >
                  {
                    myApprovers.map((approver) => (
                      <MenuItem key={approver.approverId} className={styles.responsibleMenuItem} value={approver.name}>
                        <span>{approver.name}</span>
                        <span className={styles.responsiblePost}>{approver.email}</span>
                      </MenuItem>
                    ))
                  }
                </Select>
                <ErrorMessage
                  errors={errors}
                  name="responsible"
                  render={({ message }) => (
                    <span className={styles.errorMsg}>{message}</span>
                  )}
                />
              </div>
            )}
          />
        </FormControl>

        {/* <div className={styles.formElementWrapper}>
          <input
            type="file"
            {...register('file', { required: 'Выберите файл' })}
            id="file"
            className={styles.fileInput}
          />
          <label htmlFor="file" className={classNames(styles.fileInputDescription, !!errors.file && styles.fileInputError)}>
            {watch('file')?.[0]?.name || 'Выберите файл'}
          </label>
          <ErrorMessage
            errors={errors}
            name="file"
            render={({ message }) => <span className={styles.errorMsg}>{message}</span>}
          />
        </div> */}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: t("requests.createRequest.enterStartDate") as string,
            }}
            render={({ field, fieldState: { error } }) => (
              <div className={styles.formElementWrapper}>
                <DatePicker
                  label={t("requests.createRequest.startDate")}
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </div>
            )}
          />
          <Controller
            name="endDate"
            control={control}
            rules={{
              required: t("requests.createRequest.enterEndDate") as string,
            }}
            render={({ field, fieldState: { error } }) => (
              <div className={styles.formElementWrapper}>
                <DatePicker
                  label={t("requests.createRequest.endDate")}
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              </div>
            )}
          />
        </LocalizationProvider>

        <Box className={styles.buttonGroup}>
          <Button type="submit" variant="contained" color="primary">
            {t("requests.createRequest.saveBtn")}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              reset();
              toggleDrawer(false)();
            }}
          >
            {t("requests.createRequest.cancelBtn")}
          </Button>
        </Box>
      </form>
      <VacationConflictModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onExtendVacation={handleExtendVacation}
        onReduceDays={handleReduceDays}
        conflictingDays={conflictingHolidays}
      />
    </Box>
  );
};
