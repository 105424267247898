import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchVacationBalances = createAsyncThunk(
  "vacationBalances/fetchVacationBalances",
  async (year: number, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get(`daysoff/vacationbalances`, {
        params: { year },
      });
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateVacationBalances = createAsyncThunk(
  "vacationBalances/updateVacationBalances",
  async (
    balances: { employeeId: string; balance: string }[],
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosApi.put(`daysoff/vacationbalances`, {
        balances,
      });
      return response.data;
    } catch (error: any) {
      const generalMessage =
        error.response?.data?.message || "Failed to update balances.";

      const validationErrors = error.response?.data?.errors || {};

      const validationMessages = Object.entries(validationErrors)
        .map(([field, messages]) => `${(messages as string[]).join(", ")}`)
        .join("; ");

      const errorMessage = validationMessages
        ? `${generalMessage} ${validationMessages}`
        : generalMessage;

      return rejectWithValue(errorMessage);
    }
  },
);
