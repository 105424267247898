import { FC, useEffect, useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";

import closeIcon from "../../assets/icons/closeIcon.svg";
import { IFormInput, IOrganizationContacts } from "../../models/organizations";
import { activateOrDeactivateBtn, activateOrDeactivateModal, cancelBtn } from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";

import styles from "../../views/Catalogs/CatalogsLayout.module.scss";

const ContactsModal: FC<{
    isModalOpen: boolean,
    clearForm: () => void,
    actionName: string,
    contact: IOrganizationContacts | null,
    activeEntityId: string | number | null,
    getValues: UseFormGetValues<IFormInput>,
    setValue: UseFormSetValue<IFormInput>,
    alterEditedContactsArray: (contact: IOrganizationContacts) => void
}> = ({ isModalOpen, clearForm, actionName, contact, getValues, setValue, alterEditedContactsArray }) => {
    const { t } = useTranslation();

    const [data, setData] = useState<IOrganizationContacts>({ id: null, name: '', phoneNumber: '', emailAddress: '', isSaved: false });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validateField = (fieldName: string, value: string) => {
        let error = '';
        const maxLength = fieldName === 'name' ? 250 : fieldName === 'emailAddress' ? 150 : undefined;

        if (!value.trim() && fieldName === 'name') {
            error = t('validation.requiredField');
        } else if (maxLength && value.trim().length > maxLength) {
            error = t(`validation.maxLength${maxLength}`);
        } else if (fieldName === 'emailAddress' && value.trim() && !/\S+@\S+\.\S+/.test(value.trim())) {
            error = t('validation.invalidEmail');
        }
        return error;
    };


    const handleInputChange = (fieldName: string, value: string) => {
        const updatedErrors = { ...errors };
        updatedErrors[fieldName] = validateField(fieldName, value);
        setErrors(updatedErrors);
    };

    const validateForm = () => {
        let valid = true;
        const errors: { [key: string]: string } = {};

        if (!data.name.trim()) {
            errors.name = t('validation.requiredField');
            valid = false;
        }

        if (data.emailAddress.trim() && !/\S+@\S+\.\S+/.test(data.emailAddress.trim())) {
            errors.emailAddress = t('validation.invalidEmail');
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const contactSave = async () => {
        setValue("addContacts", []);
        // reset({addContacts: []}, {keepValues: true})

        if (validateForm()) {
            let newContacts = [];
            const contacts = getValues("contacts");
            if (data.id) {
                if (contacts?.length) {
                    const index = contacts?.findIndex(el => el.id === data.id);
                    newContacts = [...contacts?.slice(0, index), data, ...contacts?.slice(index + 1, contacts?.length)];
                    setValue("contacts", newContacts);
                    setValue("addContacts", []);
                    setValue("editContacts", []);
                    alterEditedContactsArray(data);
                    clearForm();
                }
            } else {
                if (contacts?.length) {
                    newContacts = [...contacts, { ...data, id: Date.now() }];
                } else {
                    newContacts.push({ ...data, id: Date.now() });
                }
                setValue("contacts", newContacts);
                setValue("addContacts", []);
                setValue("editContacts", []);
                clearForm();
            }
            clearForm();
        }
        setValue("addContacts", []);

    };


    useEffect(() => {
        if (isModalOpen && actionName === 'Create') {
            setData({ id: null, name: '', phoneNumber: '', emailAddress: '', isSaved: false });
        } else if (isModalOpen && contact) {
            setData({ ...contact });
        }
    }, [isModalOpen, actionName, contact]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isModalOpen || false}
                onClose={() => {setData({ id: null, name: '', phoneNumber: '', emailAddress: '', isSaved: false }); clearForm();} }
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isModalOpen}>
                    <Box sx={activateOrDeactivateModal}>
                        <div className={styles.activateModalTopWrapper}>
                            <h3 className={styles.activateModalTitle}>
                                {t(`buttons.${actionName}`)} {' '} {t('catalogActions.contacts') || ' '}
                            </h3>
                            <img src={closeIcon} alt="" onClick={() => {setData({ id: null, name: '', phoneNumber: '', emailAddress: '', isSaved: false }); clearForm();}} />
                        </div>

                        <div>
                            <label htmlFor="name" className={styles.drawerTopSubTitle}>{t('data.name')}</label>
                            <div>
                                <input
                                    value={data.name}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t('data.name') as string}
                                    className={errors.name ?
                                        styles.catalogsDrawerInputError : styles.catalogsDrawerInput}
                                    onChange={(event) => {
                                        setData({ ...data, name: event.target.value });
                                        handleInputChange('name', event.target.value);
                                    }}
                                />
                                {errors.name && <span className={styles.error}>{errors.name}</span>}
                            </div>

                            <label htmlFor="phoneNumber" className={styles.drawerTopSubTitle}>{t('data.phoneNumber')}</label>
                            <div>
                                <input
                                    value={data.phoneNumber}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t('data.phoneNumber') as string}
                                    className={errors.phoneNumber ?
                                        styles.catalogsDrawerInputError : styles.catalogsDrawerInput}
                                    onChange={(event) => {
                                        setData({ ...data, phoneNumber: event.target.value });
                                        handleInputChange('phoneNumber', event.target.value);
                                    }}
                                />
                                {errors.phoneNumber && <span className={styles.error}>{errors.phoneNumber}</span>}
                            </div>

                            <label htmlFor="emailAddress" className={styles.drawerTopSubTitle}>{t('data.email')}</label>
                            <div>
                                <input
                                    value={data.emailAddress}
                                    type="text"
                                    autoComplete="off"
                                    placeholder={t('data.email') as string}
                                    className={errors.emailAddress ?
                                        styles.catalogsDrawerInputError : styles.catalogsDrawerInput}
                                    onChange={(event) => {
                                        setData({ ...data, emailAddress: event.target.value });
                                        handleInputChange('emailAddress', event.target.value);
                                    }}
                                />
                                {errors.emailAddress && <span className={styles.error}>{errors.emailAddress}</span>}
                            </div>
                        </div>

                        <div className={styles.activateModalButtonWrapper}>
                            <Button
                                onClick={() => { setData({ id: null, name: '', phoneNumber: '', emailAddress: '', isSaved: false }); clearForm(); }}
                                variant="text"
                                sx={{ ...cancelBtn, margin: '5px' }}>
                                {t('buttons.Cancel')}
                            </Button>
                            <ButtonWithProgress
                                loading={false}
                                disabled={false}
                                variant="contained"
                                onClick={() => contactSave()}
                                sx={activateOrDeactivateBtn}>
                                {t(`buttons.${actionName}`)}
                            </ButtonWithProgress>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ContactsModal;
