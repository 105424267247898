import { useState } from "react";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { sendNotification } from "ui/Toast";

import AdapterDayjs from "@date-io/date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DesktopDatePicker, PickersDay } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import styles from "./styles.module.scss";

export const SickCalendar = ({
  id,
  date,
  note,
  isNew,
  onAdd,
  onDelete,
  dateEnd,
  handleUpdateExistingHoliday,
}: {
  id?: string | null;
  date?: Dayjs | null;
  note?: string;
  isNew?: boolean;
  onAdd?: (holiday: {
    date: Dayjs | null;
    dateEnd: Dayjs | null;
    note: string;
    id: string | null;
    pureDays?: number;
  }) => void;
  onDelete?: (id: string) => void;
  dateEnd?: Dayjs | null;
  handleUpdateExistingHoliday?: (id: number, newDateEnd: Dayjs | null) => void;
}) => {
  const { t } = useTranslation();
  const [localNote, setLocalNote] = useState(note || "");
  const [localDate, setLocalDate] = useState<Dayjs | null>(date || null);
  const [localDateEnd, setLocalDateEnd] = useState<Dayjs | null>(
    dateEnd || null,
  );

  const today = dayjs().startOf("day");
  const isExisting = !isNew;
  const isEndDateEditable = isNew
    ? true
    : localDateEnd
      ? !dayjs(localDateEnd).isBefore(today)
      : false;

  const handleAdd = () => {
    if (localDate && localDateEnd) {
      onAdd?.({
        date: localDate,
        dateEnd: localDateEnd,
        note: localNote,
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        pureDays: dayjs(localDateEnd).diff(localDate, "day") + 1,
      });
      setLocalDate(null);
      setLocalDateEnd(null);
      setLocalNote("");
    }
  };

  const handleDelete = async () => {
    try {
      onDelete?.(String(id));
    } catch (error) {
      sendNotification(t("statuses.Error"), "error");
      console.error("Error deleting sick leave", error);
    }
  };

  const handleChange = (newValue: Date | null) => {
    setLocalDate(dayjs(newValue));
  };

  const handleChangeEndDate = (newValue: Date | null) => {
    setLocalDateEnd(dayjs(newValue));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.container_wrapper}>
        <div>
          <div className={styles.card_wrapper}>
            <div className={styles.card_container}>
              <div className={styles.card_row}>
                <span className={styles.card_row_text}>
                  {t("data.startDate")}
                </span>
                <DesktopDatePicker
                  className={styles.date_picker}
                  defaultCalendarMonth={new Date()}
                  disabled={!isNew}
                  inputFormat="dd/MM/yyyy"
                  value={localDate}
                  onChange={handleChange}
                  renderDay={(day, _value, DayComponentProps) => (
                    <PickersDay {...DayComponentProps} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t("data.dateFormat") || "dd/MM/yyyy",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.card_row}>
                <span className={styles.card_row_text}>
                  {t("data.endDate")}
                </span>
                <DesktopDatePicker
                  className={styles.date_picker}
                  // @ts-ignore
                  defaultCalendarMonth={new Date()}
                  disabled={isNew ? false : !isEndDateEditable}
                  inputFormat="dd/MM/yyyy"
                  value={localDateEnd}
                  minDate={localDate || undefined}
                  onChange={(newValue) => {
                    // @ts-ignore
                    handleChangeEndDate(newValue);
                    if (!isNew && id) {
                      handleUpdateExistingHoliday?.(
                        Number(id),
                        dayjs(newValue),
                      );
                    }
                  }}
                  renderDay={(day, _value, DayComponentProps) => (
                    <PickersDay {...DayComponentProps} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t("data.dateFormat") || "dd/MM/yyyy",
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.card_row}>
                <span className={styles.card_row_text}>{t("data.note")}</span>
                <textarea
                  disabled={!isNew && isExisting}
                  placeholder={
                    isNew ? t("data.note") || "" : t("data.noteIsMissing") || ""
                  }
                  className={styles.note}
                  name="note"
                  value={localNote}
                  onChange={(e) => setLocalNote(e.target.value)}
                />
              </div>
            </div>
            <CloseIcon
              className={classNames(styles.closeIcon, {
                [styles.hide_icon]: isNew,
              })}
              color="error"
              fontSize="large"
              onClick={handleDelete}
            />
          </div>
          {isNew && (
            <div className={styles.button_wrapper}>
              <Button
                variant="contained"
                disabled={!localDate || !localDateEnd}
                className={styles.button_add}
                onClick={handleAdd}
              >
                {t("buttons.Add")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};
