import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { CreateIcon, EditIcon, FilterIcon, ViewIcon } from "assets/icons";
import ConfirmationModal from "features/ConfirmationModal";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useDebounce,
    useSortColumn,
} from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    changeActiveTabInEditProject,
    clearEmployeesSearch,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    setProject,
} from "store";
import {
    activateProject,
    deactivateProject,
    getClientsLookUp,
    getEmployeesLookUp,
    getProjects,
} from "store/thunks/projectsThunk";
import { createOrEditBtn } from "styles/MUIStyles";
import { sendNotification } from "ui/Toast";

import SearchIcon from "@mui/icons-material/Search";
import { Badge, FormControl, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import ProjectFilters from "./ProjectsFilter/ProjectFilters";
import ProjectsTable from "./ProjectsTable";

import styles from "./Projects.module.scss";

const Projects: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const projectsFilters = JSON.parse(
        localStorage.getItem("projectsFilters") || "{}",
    );

    const activeProject = useAppSelector(
        (state) => state.projects.activeProject,
    );
    const totalProjectsCount = useAppSelector(
        (state) => state.projects.totalProjectsCount,
    );
    const isActivateOrDeactivateProjectLoading = useAppSelector(
        (state) => state.projects.isActivateOrDeactivateProjectLoading,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const { role } = useAppSelector((state) => state.auth);

    const isProjectsSearchingRef = useRef<boolean | null>(null);
    const [search, setSearch] = useLocalStorage("MRTProjectsSearch", "");
    const debouncedSearch = useDebounce(search, 1000);
    const [isProjectFiltersOpen, setIsProjectFiltersOpen] = useState(false);
    const [
        isActivateOrDeactivateProjectModalOpen,
        setIsActivateOrDeactivateModalOpen,
    ] = useState(false);
    const [projectsPageNumber, setProjectsPageNumber] = useLocalStorage(
        "projectsPageNumber",
        1,
    );
    const [projectsPageSize, setProjectsPageSize] = useLocalStorage(
        "projectsPageSize",
        10,
    );
    const { sortConfig, handleColumnClick } = useSortColumn(
        "projectsTableSortConfig",
    );
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "projectsFilterBadge",
        0,
    );

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setProjectsPageSize(+event.target.value);
        setProjectsPageNumber(1);
        dispatch(
            getProjects({
                pageNumber: 1,
                pageSize: +event.target.value,
                searchProject: debouncedSearch,
                client: projectsFilters?.client,
                employee: projectsFilters?.employee,
                startFTE: projectsFilters?.startFTE,
                endFTE: projectsFilters?.endFTE,
                selectedStatuses: projectsFilters?.selectedStatuses,
                fromStartDate: projectsFilters?.fromStartDate,
                beforeStartDate: projectsFilters?.beforeStartDate,
                fromEndDate: projectsFilters?.fromEndDate,
                beforeEndDate: projectsFilters?.beforeEndDate,
                sortBy: sortConfig,
            }),
        );
    };

    const handleOnProjectsPageChange = (
        event: ChangeEvent<unknown>,
        page: number,
    ) => {
        dispatch(setProject(null));
        setProjectsPageNumber(page);
    };

    const handleDeactivateProject = async (projectId: number) => {
        const response = await dispatch(deactivateProject(projectId));

        if (response.meta.requestStatus === "fulfilled") {
            dispatch(
                getProjects({
                    pageNumber: projectsPageNumber,
                    pageSize: projectsPageSize,
                    client: projectsFilters?.client,
                    employee: projectsFilters?.employee,
                    startFTE: projectsFilters?.startFTE,
                    endFTE: projectsFilters?.endFTE,
                    selectedStatuses: projectsFilters?.selectedStatuses,
                    fromStartDate: projectsFilters?.fromStartDate,
                    beforeStartDate: projectsFilters?.beforeStartDate,
                    fromEndDate: projectsFilters?.fromEndDate,
                    beforeEndDate: projectsFilters?.beforeEndDate,
                    sortBy: sortConfig,
                }),
            );
            sendNotification(
                t("notifications.successfullyDeactivated"),
                "success",
            );
            setIsActivateOrDeactivateModalOpen(false);
        }
    };

    const handleActivateProject = async (projectId: number) => {
        const response = await dispatch(activateProject(projectId));

        if (response.meta.requestStatus === "fulfilled") {
            dispatch(
                getProjects({
                    pageNumber: projectsPageNumber,
                    pageSize: projectsPageSize,
                    client: projectsFilters?.client,
                    employee: projectsFilters?.employee,
                    startFTE: projectsFilters?.startFTE,
                    endFTE: projectsFilters?.endFTE,
                    selectedStatuses: projectsFilters?.selectedStatuses,
                    fromStartDate: projectsFilters?.fromStartDate,
                    beforeStartDate: projectsFilters?.beforeStartDate,
                    fromEndDate: projectsFilters?.fromEndDate,
                    beforeEndDate: projectsFilters?.beforeEndDate,
                    sortBy: sortConfig,
                }),
            );
            sendNotification(
                t("notifications.successfullyActivated"),
                "success",
            );
            setIsActivateOrDeactivateModalOpen(false);
        }
    };

    useEffect(() => {
        localStorage.removeItem("projectEmployeesFilter");
        dispatch(clearEmployeesSearch());

        //Здесь нужно брать лукапы из slice lookup
        dispatch(getClientsLookUp());
        dispatch(getEmployeesLookUp());
        dispatch(changeActiveTabInEditProject(0));

        const menu = allowedMenus.find((menu) => menu.name === "Projects");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
        // return () => {
        //     dispatch(setProject(null));
        // }
    }, [dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/projects`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon />}
                        variant="text"
                        sx={createOrEditBtn}
                        onClick={() => navigate("management/create")}
                    >
                        {t("buttons.Create")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon />}
                        variant="text"
                        disabled={!activeProject}
                        sx={createOrEditBtn}
                        onClick={() => {
                            if (activeProject) {
                                navigate(`management/edit/${activeProject.id}`);
                            }
                        }}
                    >
                        {t("buttons.Edit")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="View"
                >
                    <Button
                        startIcon={<ViewIcon />}
                        variant="text"
                        sx={createOrEditBtn}
                        disabled={!activeProject}
                        onClick={() => {
                            if (activeProject) {
                                navigate(`view/${activeProject.id}`);
                            }
                        }}
                    >
                        {t("buttons.View")}
                    </Button>
                </Guard>
                <Box
                    sx={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <span>{t("filters.elementsOnPage")}</span>
                    <FormControl
                        variant="standard"
                        sx={{ margin: "4px 2rem 0 .8rem" }}
                    >
                        <Select
                            value={String(projectsPageSize)}
                            onChange={handlePageSizeChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    {["accountant", "administrator", "ceo"].includes(
                        role?.toLowerCase()!,
                    ) && (
                        <Badge
                            color="primary"
                            badgeContent={filtersCount}
                            sx={{ "& span": { fontSize: "12px" } }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <Button
                                startIcon={<FilterIcon />}
                                type="button"
                                variant="text"
                                sx={{ ...createOrEditBtn, marginLeft: "auto" }}
                                onClick={() => setIsProjectFiltersOpen(true)}
                            >
                                {t("buttons.Filters")}
                            </Button>
                        </Badge>
                    )}
                </Box>
            </div>
            <div
                className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
            >
                <div className={styles.searchWrapper}>
                    <input
                        name="search"
                        type="text"
                        autoComplete="off"
                        value={search}
                        onChange={(e) => {
                            isProjectsSearchingRef.current = true;
                            setSearch(e.target.value);
                        }}
                        placeholder={
                            t("filters.searchByProjectsName") as string
                        }
                        className={styles.search}
                    />
                    <button type="button" className={styles.searchIconWrapper}>
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <ProjectsTable
                  projectsPageSize={projectsPageSize}
                  setProjectsPageNumber={setProjectsPageNumber}
                    debouncedSearch={debouncedSearch}
                    projectsPageNumber={projectsPageNumber}
                    isProjectsSearchingRef={isProjectsSearchingRef}
                    setIsActivateOrDeactivateModalOpen={
                        setIsActivateOrDeactivateModalOpen
                    }
                    projectsSortConfig={sortConfig}
                    projectsHandleColumnClick={handleColumnClick}
                />
            </div>
            <Stack sx={{ width: "500px", margin: "0 auto" }}>
                <Pagination
                    sx={{ margin: "auto" }}
                    count={
                        totalProjectsCount
                            ? Math.ceil(
                                  totalProjectsCount / Number(projectsPageSize),
                              )
                            : 1
                    }
                    page={projectsPageNumber}
                    size="medium"
                    onChange={handleOnProjectsPageChange}
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Stack>
            {["accountant", "administrator", "ceo"].includes(
                role?.toLowerCase()!,
            ) && (
                <ProjectFilters
                    isProjectFiltersOpen={isProjectFiltersOpen}
                    setIsProjectFiltersOpen={setIsProjectFiltersOpen}
                    projectsPageNumber={projectsPageNumber}
                    setProjectsPageNumber={setProjectsPageNumber}
                    projectsPageSize={projectsPageSize}
                    debouncedSearch={debouncedSearch}
                    projectsSortConfig={sortConfig}
                    setFiltersCount={setFiltersCount}
                />
            )}
            <ConfirmationModal
                isModalOpen={isActivateOrDeactivateProjectModalOpen}
                setIsModalOpen={() => setIsActivateOrDeactivateModalOpen(false)}
                actionName={activeProject?.isActive ? "Deactivate" : "Activate"}
                instanceName={"project"}
                loading={isActivateOrDeactivateProjectLoading}
                handleAction={() => {
                    if (activeProject) {
                        if (activeProject.isActive) {
                            handleDeactivateProject(activeProject.id);
                        } else {
                            handleActivateProject(activeProject.id);
                        }
                    }
                }}
            />
        </div>
    );
};

export default Projects;
