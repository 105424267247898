import React from "react";
import { EditIcon, ViewIcon } from "assets/icons";
import dayjs from "dayjs";
import Guard from "features/Guard";
import { useAppSelector } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { materialReactTableCell } from "styles/MUIStyles";

import { Box } from "@mui/material";

import { IAllowedMenu } from "../../../models/auth";
import { IEmployeeTimes } from "../../../models/tracking";

export const CustomProjectCellRenderer = (props: any) => {
  const { data, colDef } = props;
  const allowedPermissions = useAppSelector(
    (state) => state.auth.allowedPermissions,
  );
  const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

  switch (colDef.field) {
    case "nameAndPosition":
      return <NamePositionProjectCellRenderer data={data} />;
    case "client":
      return (
        <ClientProjectCellRenderer allowedMenus={allowedMenus} data={data} />
      );
    case "project":
      return <ProjectProjectCellRenderer data={data} />;
    case "organization":
      return <VendorProjectCellRenderer data={data} />;
    case "projectCell":
      return <ProjectCellRenderer data={data} />;
    case "fromDate":
      return <FromDateProjectCellRenderer data={data} />;
    case "fte":
      return <FteProjectCellRenderer data={data} />;
    case "hours":
      return <TotalTimeProjectCellRenderer data={data} />;
    case "personnelNumber":
      return <PersonnelNumberProjectCellRenderer data={data} />;
    case "skill":
      return <MainSkillProjectCellRenderer data={data} />;
    case "actions":
      return (
        <ActionsCellRenderer
          data={data}
          allowedPermissions={allowedPermissions}
        />
      );
    default:
      return null;
  }
};

const ActionsCellRenderer = (props: any) => {
  const navigate = useNavigate();
  const allowedPermissions = props.allowedPermissions;

  return (
    <Box
      className={"actions_cell"}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "40%",
        cursor: "pointer",
      }}
    >
      <Guard allowedPermissions={allowedPermissions} permissionName="View">
        <p
          onClick={() => navigate(`/tracking/project/${props.data.projectId}`)}
        >
          <ViewIcon />
        </p>
      </Guard>
      <Guard allowedPermissions={allowedPermissions} permissionName="Edit">
        <p onClick={() => navigate(`management/edit/${props.data.projectId}`)}>
          <EditIcon />
        </p>
      </Guard>
    </Box>
  );
};

export function ClientProjectCellRenderer(params: any) {
  return (
    <Box
      sx={materialReactTableCell}
      onClick={() =>
        localStorage.setItem("redirectOrganizationId", params.data?.clientId)
      }
    >
      {params.allowedMenus.some(
        (menu: IAllowedMenu) => menu.name === "Organizations",
      ) ? (
        <Link to="/organizations">{params.data?.client}</Link>
      ) : (
        <p>params.data?.client</p>
      )}
      {/*<p>{params.data.client}</p>*/}
    </Box>
  );
}

export const NamePositionProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => {
  return (
    <Box sx={materialReactTableCell}>
      {data.employeesTimes?.map((tracking: IEmployeeTimes, index: any) => (
        <Box
          key={uuid() + "Project"}
          style={{
            marginTop: index && "10px",
            textAlign: "center",
            height: "40px",
          }}
        >
          <Link to={`/employees/${tracking.employeeId}`}>
            <p>{tracking.position}</p>
          </Link>
          <Link to={`/employees/${tracking.employeeId}`}>
            <p>{tracking.fullName}</p>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export const ProjectProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => {
  return (
    <Box sx={{ ...materialReactTableCell, display: "flex" }}>
      <span>{data.projectCode}</span>
      <span>{data.shortName}</span>
    </Box>
  );
};

export const VendorProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => {
  return (
    <Box sx={materialReactTableCell} key={uuid() + "vendor"}>
      {data.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
        <Box
          key={uuid() + "project"}
          style={{
            marginTop: index && "10px",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {tracking.organization}
        </Box>
      ))}
    </Box>
  );
};

export const ProjectCellRenderer: React.FC<{ data: any }> = ({ data }) => {
  return (
    <Box sx={materialReactTableCell}>
      {data.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
        <Box
          key={uuid() + "project"}
          style={{ marginTop: index && "10px", height: "40px" }}
        >
          {tracking.organization}
        </Box>
      ))}
    </Box>
  );
};

export const FromDateProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => {
  return (
    <Box sx={materialReactTableCell}>
      {data?.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
        <p
          key={uuid() + "fromDate"}
          style={{
            marginTop: index && "10px",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {tracking.startDate
            ? dayjs(tracking.startDate).format("DD.MM.YYYY")
            : null}
        </p>
      ))}
    </Box>
  );
};

export const FteProjectCellRenderer: React.FC<{ data: any }> = ({ data }) => (
  <Box sx={materialReactTableCell}>
    {data.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
      <p
        key={uuid() + "fte"}
        style={{
          marginTop: index && "10px",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {tracking.fte ? tracking.fte : ""}
      </p>
    ))}
  </Box>
);

export const TotalTimeProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => (
  <Box sx={materialReactTableCell}>
    {data.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
      <p
        key={uuid() + "totalTime"}
        style={{
          marginTop: index && "10px",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {tracking.totalTime ? tracking.totalTime : null}
      </p>
    ))}
  </Box>
);
export const PersonnelNumberProjectCellRenderer: React.FC<{ data: any }> = ({
  data,
}) => (
  <Box sx={materialReactTableCell}>
    {data.employeesTimes?.map((tracking: IEmployeeTimes, index: number) => (
      <p
        key={uuid() + "perNum"}
        style={{
          marginTop: index && "10px",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {tracking.personnelNumber ? tracking.personnelNumber : null}
      </p>
    ))}
  </Box>
);

export function MainSkillProjectCellRenderer({ data }: any) {
  return data.employeesTimes?.map((employee: IEmployeeTimes, index: number) => {
    return (
      <Box
        sx={materialReactTableCell}
        key={uuid() + "mainSkill"}
        style={{
          marginTop: index && "10px",
          textAlign: "center",
          height: "40px",
        }}
      >
        <p>{employee.skill}</p>
        <p>{employee.skillLevel}</p>
      </Box>
    );
  });
}
