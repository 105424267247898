import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FilterIcon } from "assets/icons";
import { useAppDispatch, useAppSelector, useLocalStorage } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { cancelBtn, createOrEditBtn, saveBtn } from "styles/MUIStyles";
import Loader from "ui/Loader";
import { useDebounce } from "usehooks-ts";

import SearchIcon from "@mui/icons-material/Search";
import { Badge, Box, Breadcrumbs, Button, debounce } from "@mui/material";

import ConfirmationModal from "../../../features/ConfirmationModal";
import { getLookupByName, type RootState } from "../../../store";
import {
  fetchVacationBalances,
  updateVacationBalances,
} from "../../../store/thunks/vacationSickDaysRemainThunk";
import { sendNotification } from "../../../ui/Toast";

import VacationBalanceFilterPanel from "./VacationBalanceFilterPanel";
import RemainingDaysTable from "./VacationSickDaysRemainingTable";

import styles from "./VacationSickDaysRemainingDays.module.scss";

export interface Filters {
  organizationId: number | null;
  organizationTypes: string[];
}

const VacationSickDaysRemainingDays = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { balances, loading } = useAppSelector(
    (state: RootState) => state.vacationBalances,
  );

  const [searchQuery, setSearchQuery] = useState("");
  const searchQueryRef = useRef<string>("");
  const [searchParams] = useSearchParams();
  const yearParam = searchParams.get("year");
  const year = yearParam ? Number(yearParam) : new Date().getFullYear();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const debouncedSearch = useDebounce(searchQuery, 300);

  const [openCloseModal, setOpenCloseModal] = useState(false);

  const [filters, setFilters] = useState<Filters>({
    organizationId: null,
    organizationTypes: [],
  });
  const [isOpenFilterPanel, setIsOpenFilterPanel] = useState<boolean>(false);
  const [filtersCount, setFiltersCount] = useState<number>(0);

  const editedValuesRef = useRef<Record<string, string>>({});

  const handleFormChange = () => {
    setHasUnsavedChanges(true);
  };

  const resetFilters = () => {
    setFilters({
      organizationId: null,
      organizationTypes: [],
    });
    setHasUnsavedChanges(true);
  };

  const filteredData = useMemo(() => {
    let data = balances.map((balance) => ({
      ...balance,
      balance: editedValuesRef.current[balance.employeeId] ?? balance.balance,
    }));
    if (debouncedSearch) {
      data = data.filter((item) =>
        item.name?.toLowerCase().includes(debouncedSearch.toLowerCase()),
      );
    }
    if (filters.organizationId) {
      data = data.filter(
        (item) => item.organizationId === filters.organizationId,
      );
    }
    return data.map((balance) => ({
      personnelNumber: balance.personnelNumber,
      employeeId: balance.employeeId,
      name: balance.name ?? null,
      balance: editedValuesRef.current[balance.employeeId] ?? balance.balance,
      year: balance.year,
    }));
  }, [debouncedSearch, balances, filters, editedValuesRef.current]);

  useEffect(() => {
    dispatch(fetchVacationBalances(year));
  }, [dispatch]);

  const handleSave = (updatedData: any[]) => {
    dispatch(updateVacationBalances(updatedData))
      .unwrap()
      .then(() => {
        sendNotification(t("notifications.successfullySaved"), "success");
        setHasUnsavedChanges(false);
        dispatch(fetchVacationBalances(year));
      })
      .catch((error) => {
        const errorMessage = error;
        sendNotification(errorMessage, "error");
      });
  };

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      setOpenCloseModal(true);
    } else {
      navigate("/daysoff");
    }
  };

  const handleCloseConfirm = () => {
    navigate(-1);
    setOpenCloseModal(false);
  };

  const [noDataFound, setNoDataFound] = useState(false);

  useEffect(() => {
    if (
      (debouncedSearch && filteredData.length === 0) ||
      filteredData.length === 0
    ) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
    }
  }, [debouncedSearch, filteredData]);

  return (
    <Box sx={{ width: "100%", padding: "10px 10px" }}>
      <Breadcrumbs sx={{ padding: "10px 10px" }}>
        <Link to="/daysoff">{t("menu.daysOff")}</Link>
      </Breadcrumbs>
      <div className={styles.boxTitle}>
        <h2>
          {t("vacationsSickDays.VacationsAndSickDays")} {year}
        </h2>
        <div>
          <Button
            type="submit"
            variant="contained"
            sx={saveBtn}
            onClick={(e) => {
              e.preventDefault();
              const tableInstance = document.querySelector("form");
              if (tableInstance) {
                tableInstance.dispatchEvent(
                  new Event("submit", { bubbles: true, cancelable: true }),
                );
              }
            }}
          >
            {t("buttons.Save")}
          </Button>

          <Button variant="text" sx={cancelBtn} onClick={handleCloseModal}>
            {t("buttons.Close")}
          </Button>
        </div>
        <Badge
          color="primary"
          badgeContent={filtersCount}
          sx={{ "& span": { fontSize: "12px" } }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Button
            startIcon={<FilterIcon />}
            variant="text"
            onClick={() => setIsOpenFilterPanel(true)}
            sx={createOrEditBtn}
          >
            {t("buttons.Filters")}
          </Button>
        </Badge>
      </div>
      <div className={styles.mainText}>
        {t("vacationsSickDays.VacationBalanceText")}
      </div>
      <div className={styles.searchWrapper}>
        <input
          type="text"
          placeholder={t("filters.searchByNameAndSurname") as string}
          value={searchQuery}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "") {
              setSearchQuery("");
            } else {
              setSearchQuery(value);
            }
          }}
        />
        <button type="button" className={styles.searchWrapper_icon}>
          <SearchIcon sx={{ color: "#fff" }} />
        </button>
      </div>
      <div className={styles.tableWrapper}>
        {noDataFound && (
          <div className={styles.noUserFoundWrapper}>
            <div className={styles.noUserFound}>
              {t("data.noRecordsToShow")}
            </div>
          </div>
        )}
        <div>
          <RemainingDaysTable
            data={filteredData}
            onSave={handleSave}
            editedValuesRef={editedValuesRef}
          />
        </div>
        <div className={styles.filterWrapper}>
          <VacationBalanceFilterPanel
            isOpenFilterPanel={isOpenFilterPanel}
            setIsOpenFilterPanel={setIsOpenFilterPanel}
            filters={filters}
            setFilters={setFilters}
            resetFilters={resetFilters}
          />
        </div>
      </div>
      <ConfirmationModal
        isModalOpen={openCloseModal}
        setIsModalOpen={setOpenCloseModal}
        actionName={"Close"}
        instanceName=""
        message={"closeFormWithoutSaving"}
        handleAction={handleCloseConfirm}
      />
    </Box>
  );
};

export default VacationSickDaysRemainingDays;
