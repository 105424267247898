import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { CreateIcon, EditIcon, FilterIcon, ViewIcon } from "assets/icons";
import { getFromLocalStorage } from "core/utils";
import FiltersComponent from "features/Catalogs/FiltersComponent";
import ConfirmationModal from "features/ConfirmationModal";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector, useSortColumn } from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    activateOrganization,
    deactivateOrganization,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getEmployeesLookup,
    getLookupByName,
    getOrganization,
    getOrganizations,
    setRedirectOrganizationId,
} from "store";
import { createOrEditBtn } from "styles/MUIStyles";
import { sendNotification } from "ui/Toast";

import SearchIcon from "@mui/icons-material/Search";
import {
    Badge,
    Box,
    Button,
    FormControl,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
} from "@mui/material";

import useDebounce from "../../hooks/useDebounce";
import { ORGANIZATIONS_PATH } from "../../router/constants";

import FilterPanel from "./FilterPanel";
import OrganizationsTable from "./OrganizationsTable";

import styles from "./Organizations.module.scss";

const Organizations = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const selectedOrganization = useAppSelector(
        (state) => state.organizations.selectedOrganization,
    );
    const organizationsTotalPageCount = useAppSelector(
        (state) => state.organizations.organizationsTotalPageCount,
    );
    const organizationTypesFilter = useAppSelector(
        (state) => state.lookups.organizationTypes,
    );
    const redirectOrganizationId = useAppSelector(
        (state) => state.organizations.redirectOrganizationId,
    );

    const [createOrUpdate, setCreateOrUpdate] = useState<null | string>(null);
    const [isView, setIsView] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
        isOpen: boolean;
        actionName: string;
    }>({ isOpen: false, actionName: "" });
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
    const [page, setPage] = useLocalStorage("organizationsPage", 1);
    const [pageSize, setPageSize] = useLocalStorage(
        "organizationsPageSize",
        10,
    );
    const { sortConfig, handleColumnClick } = useSortColumn(
        "organizationsTableSort",
    );
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "organizationsFiltersBadge",
        0,
    );
    const isSearchingRef = useRef<boolean | null>(null);
    const [searchOrganizationByCode, setSearchOrganizationByCode] =
        useLocalStorage("searchOrganizationByCode", "");
    const [searchOrganizationByName, setSearchOrganizationByName] =
        useLocalStorage("searchOrganizationByName", "");
    // const [searchOrganizationByName] = useLocalStorage('searchOrganizationByName', '');
    const [searchManager] = useLocalStorage("searchManager", "");
    const debouncedSearchOrganizationByCode = useDebounce(
        searchOrganizationByCode,
        1000,
    );
    const debouncedSearchOrganizationByName = useDebounce(
        searchOrganizationByName,
        1000,
    );
    const debouncedSearchManager = useDebounce(searchManager);

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const onEditOrganization = useCallback(
        (id: number) => {
            navigate(`/${ORGANIZATIONS_PATH}/edit/${id}`);
        },
        [navigate],
    );

    const onViewOrganization = useCallback(
        (id: number) => {
            navigate(`/${ORGANIZATIONS_PATH}/${id}`);
        },
        [navigate],
    );

    const activateItem = async () => {
        if (selectedOrganization?.id) {
            const organizationsFilters = getFromLocalStorage<{
                types: string[];
            }>("organizationsFilters");
            const response = await dispatch(
                activateOrganization(selectedOrganization?.id),
            );

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullyActivated"),
                    "success",
                );
                await dispatch(
                    getOrganizations({
                        pageNumber: page,
                        pageSize: pageSize,
                        types: organizationsFilters?.types || [],
                        code: searchOrganizationByCode,
                        name: searchOrganizationByName,
                        managerName: searchManager,
                        sortBy: sortConfig,
                    }),
                );
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
        }
    };

    const deactivateItem = async () => {
        if (selectedOrganization?.id) {
            const organizationsFilters = getFromLocalStorage<{
                types: string[];
            }>("organizationsFilters");
            const response = await dispatch(
                deactivateOrganization(selectedOrganization?.id),
            );

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullyDeactivated"),
                    "success",
                );
                await dispatch(
                    getOrganizations({
                        pageNumber: page,
                        pageSize: pageSize,
                        types: organizationsFilters?.types || [],
                        code: searchOrganizationByCode,
                        name: searchOrganizationByName,
                        managerName: searchManager,
                        sortBy: sortConfig,
                    }),
                );
                setConfirmationModalOpen({ isOpen: false, actionName: "" });
            }
        }
    };

    const confirmActionHandler = () => {
        switch (confirmationModalOpen.actionName) {
            case "Activate":
                activateItem();
                break;
            case "Deactivate":
                deactivateItem();
                break;
        }
    };

    const closeFilters = () => {
        setCreateOrUpdate(null);
        setIsFiltersOpen(false);
    };

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        const organizationsFilters = getFromLocalStorage<{ types: string[] }>(
            "organizationsFilters",
        );
        setPageSize(event.target.value);
        setPage(1);
        dispatch(
            getOrganizations({
                pageNumber: 1,
                pageSize: event.target.value,
                types: organizationsFilters?.types || [],
                code: searchOrganizationByCode,
                name: searchOrganizationByName,
                managerName: searchManager,
                sortBy: sortConfig,
            }),
        );
    };

    useEffect(() => {
        if (!isDrawerOpen) {
            setIsView(false);
        }
    }, [isView, isDrawerOpen]);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Organizations");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [dispatch, allowedMenus]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/organizations`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        dispatch(getLookupByName("organizationTypes"));
        dispatch(getLookupByName("countries"));
        dispatch(getEmployeesLookup());
    }, [dispatch, t]);

    useEffect(() => {
        const getActiveOrganizationById = async () => {
            if (selectedOrganization?.id && !redirectOrganizationId) {
                setIsView(true);
            } else if (redirectOrganizationId) {
                setIsView(true);
                setCreateOrUpdate("view");
                setIsDrawerOpen(true);
                await dispatch(setRedirectOrganizationId(null));
            }
        };

        getActiveOrganizationById();
    }, [dispatch, redirectOrganizationId, selectedOrganization]);

    useEffect(() => {
        const redirectOrganization = localStorage.getItem(
            "redirectOrganizationId",
        );
        if (redirectOrganization) {
            dispatch(setRedirectOrganizationId(parseInt(redirectOrganization)));
            localStorage.removeItem("redirectOrganizationId");
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            setSearchOrganizationByCode("");
            setSearchOrganizationByName("");
        };
    }, []);

    return (
        <div>
            <div className={styles.crudButtonWrapper}>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Create"
                >
                    <Button
                        startIcon={<CreateIcon />}
                        variant="text"
                        disabled={false}
                        onClick={() =>
                            navigate(`/${ORGANIZATIONS_PATH}/create`)
                        }
                        sx={createOrEditBtn}
                    >
                        {t("buttons.Create")}
                    </Button>
                </Guard>
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="Edit"
                >
                    <Button
                        startIcon={<EditIcon />}
                        variant="text"
                        sx={createOrEditBtn}
                        disabled={!selectedOrganization?.id}
                        onClick={() =>
                            navigate(
                                `/${ORGANIZATIONS_PATH}/edit/${selectedOrganization?.id}`,
                            )
                        }
                    >
                        {t("buttons.Edit")}
                    </Button>
                </Guard>

                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="View"
                >
                    <Button
                        startIcon={<ViewIcon />}
                        variant="text"
                        onClick={() => navigate(`${selectedOrganization?.id}`)}
                        disabled={!selectedOrganization?.id}
                        sx={createOrEditBtn}
                    >
                        {t("buttons.View")}
                    </Button>
                </Guard>

                <Box sx={{ marginLeft: "auto" }}>
                    <span>{t("filters.elementsOnPage")}</span>
                    <FormControl
                        variant="standard"
                        sx={{ margin: "4px .8rem 0 .8rem" }}
                    >
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={pageSize}
                            onChange={handlePageSizeChange}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <Badge
                        color="primary"
                        badgeContent={filtersCount}
                        sx={{ "& span": { fontSize: "12px" } }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            startIcon={<FilterIcon />}
                            variant="text"
                            onClick={() => {
                                setIsFiltersOpen(!isFiltersOpen);
                            }}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.Filters")}
                        </Button>
                    </Badge>
                </Box>
            </div>
            <div
                className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
            >
                <div className={styles.searchContainer}>
                    <div className={styles.searchWrapper}>
                        <input
                            name="searchByCode"
                            type="text"
                            autoComplete="off"
                            value={searchOrganizationByCode}
                            onChange={(e) => {
                                setSearchOrganizationByCode(e.target.value);
                                isSearchingRef.current = true;
                            }}
                            placeholder={
                                t("filters.searchByOrganizationCode") as string
                            }
                            className={styles.search}
                        />
                        <button
                            type="button"
                            className={styles.searchIconWrapper}
                        >
                            <SearchIcon sx={{ color: "#fff" }} />
                        </button>
                    </div>
                    <div className={styles.searchWrapper}>
                        <input
                            name="searchByName"
                            type="text"
                            autoComplete="off"
                            value={searchOrganizationByName}
                            onChange={(e) => {
                                setSearchOrganizationByName(e.target.value);
                                isSearchingRef.current = true;
                            }}
                            placeholder={
                                t("filters.searchByOrganizationName") as string
                            }
                            className={styles.search}
                        />
                        <button
                            type="button"
                            className={styles.searchIconWrapper}
                        >
                            <SearchIcon sx={{ color: "#fff" }} />
                        </button>
                    </div>
                </div>

                <OrganizationsTable
                    page={page}
                    setPage={setPage}
                    sortConfig={sortConfig}
                    handleColumnClick={handleColumnClick}
                    isSearchingRef={isSearchingRef}
                    searchManager={debouncedSearchManager}
                    onEditOrganization={onEditOrganization}
                    onViewOrganization={onViewOrganization}
                    activeOrganizationId={selectedOrganization}
                    setConfirmationModalOpen={setConfirmationModalOpen}
                    searchOrganizationByName={debouncedSearchOrganizationByName}
                    searchOrganizationByCode={debouncedSearchOrganizationByCode}
                />
            </div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxHeight: "150px",
                }}
            >
                <Pagination
                    count={
                        organizationsTotalPageCount
                            ? Math.ceil(
                                  organizationsTotalPageCount /
                                      Number(pageSize),
                              )
                            : 1
                    }
                    page={page}
                    onChange={handleChange}
                    size="medium"
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </Box>

            <ConfirmationModal
                isModalOpen={confirmationModalOpen.isOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={confirmationModalOpen.actionName}
                instanceName={"organization"}
                handleAction={confirmActionHandler}
            />
            <FiltersComponent
                name={"filters"}
                isDrawerOpen={isFiltersOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={closeFilters}
            >
                <FilterPanel
                    organizationTypes={organizationTypesFilter}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    searchOrganizationByCode={searchOrganizationByCode}
                    searchOrganizationByName={searchOrganizationByName}
                    searchManager={searchManager}
                    setFiltersCount={setFiltersCount}
                />
            </FiltersComponent>
        </div>
    );
};

export default Organizations;
