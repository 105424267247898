import { FC, useEffect } from "react";
import { ViewIcon } from "assets/icons";
import { IPersonalProject } from "models/employees";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";

import { getProjectStatusText } from "../../../helpers/getProjectStatusText";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Text } from "../../../ui/Text/Text";

import styles from "./styles.module.scss";

interface Props {
    id?: string;
    showCloseButton?: boolean;
    setCurrentProjectInfo: (project: IPersonalProject) => void;
    setIsDetailInfo: (isDetailInfo: boolean) => void;
}

const EmployeeProjectTable: FC<Props> = (props): JSX.Element => {
    const personalProjects = useAppSelector(
        (state) => state.employees.personalProjects,
    );

    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    const dispatch = useAppDispatch();
    const { setIsDetailInfo, setCurrentProjectInfo } = props;
    const { t } = useTranslation();

    const handleClick = (project: IPersonalProject) => {
        setCurrentProjectInfo(project);
        setIsDetailInfo(true);
    };

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.code")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.fullName")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.client")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>FTE</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.manager")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("data.startDate")}{" "}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("data.finishDate")}{" "}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.status")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {t("projects.activity")}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {personalProjects?.map((row) => (
                        <TableRow
                            key={row.code}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.code}
                            </TableCell>
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell>
                                {allowedMenus.some(menu => menu.name === "Clients") ? (
                                    <Link
                                        to={`/organizations/${row.clientId}`}
                                        key={row.clientId}
                                    >
                                        <Text variant="MEDIUM">{row.client}</Text>
                                    </Link>
                                ) : (
                                    <Text variant="MEDIUM">{row.client}</Text>
                                )}
                            </TableCell>
                            <TableCell>{row.employeeFTE}</TableCell>
                            <TableCell>
                                {allowedMenus.some(menu => menu.name === "Employees") ? (
                                    row.managers?.length ? (
                                        row.managers.map((manager) => (
                                            <Link
                                                to={`/employees/${manager.id}`}
                                                key={manager.fullName}
                                            >
                                                <Text variant="MEDIUM">
                                                    {manager.fullName}
                                                </Text>
                                            </Link>
                                        ))
                                    ) : (
                                        <span>{t("projects.notAssigned")}</span>
                                    )
                                ) : (
                                    row.managers?.length ? (
                                        row.managers.map((manager) => (
                                            <Text variant="MEDIUM" key={manager.fullName}>
                                                {manager.fullName}
                                            </Text>
                                        ))
                                    ) : (
                                        <span>{t("projects.notAssigned")}</span>
                                    )
                                )}
                            </TableCell>
                            <TableCell>{row.startDate.split("T")[0]}</TableCell>
                            <TableCell>
                                {row.finishDate.split("T")[0]}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                }}
                            >
                                <Text
                                    variant="MEDIUM"
                                    className={
                                        row.projectStatus === 1 ||
                                        row.projectStatus === 2
                                            ? styles.isActive
                                            : styles.notActive
                                    }
                                >
                                    {getProjectStatusText(row.projectStatus)}
                                </Text>
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: row.isActive ? "green" : "red",
                                }}
                            >
                                {row.isActive
                                    ? t("statuses.Active")
                                    : t("statuses.Inactive")}
                            </TableCell>
                            <TableCell>
                                {/* <LaunchIcon
                                    sx={{
                                        cursor: "pointer",
                                        "&hover": { color: "transparent" },
                                    }}
                                    // onClick={() => navigate(`/projects/details?id=${row.projectId}`)}
                                    onClick={() => handleClick(row)}
                                /> */}

                                <Box
                                    sx={{
                                        height: "100%",
                                        cursor: "pointer",
                                        "&hover": { color: "transparent" },
                                    }}
                                    onClick={() => handleClick(row)}
                                >
                                    <ViewIcon />
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EmployeeProjectTable;
