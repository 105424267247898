import React, { ChangeEvent, FC } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import AdapterDayjs from "@date-io/date-fns";
import { Autocomplete, Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { getCalculatedFiltersCount } from "../../../core/utils";
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
  useUpdateEffect,
} from "../../../hooks";
import { IContractsEmployeesFilters } from "../../../models/contracts";
import { IProjectsFilters } from "../../../models/projects";
import { getContractsEmployees } from "../../../store/thunks/contractsEmployees";
import { cancelBtn as resetFiltersBtn } from "../../../styles/MUIStyles";

import styles from "../../Projects/Projects.module.scss";

interface Props {
  isContractsEmployeesFiltersOpen: boolean;
  setIsContractsEmployeesFiltersOpen: (isOpen: boolean) => void;
  contractsEmployeesPageNumber: number;
  setContractsEmployeesPageNumber: (pageNumber: number) => void;
  contractsEmployeesPageSize: number;
  debouncedSearch: string;
  sortConfig: {
    column: string | null;
    direction: string | null;
  };
  setFiltersCount: (contractEmployeesFiler: number) => void;
}

const ContractsEmployeesFilters: FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [contractsEmployeesFilters, setContractsEmployeesFilters] =
    useLocalStorage<IContractsEmployeesFilters>(
      "" + "contractsEmployeesFilters",
      {
        organization: null,
        status: null,
        fromStartDate: null,
        beforeStartDate: null,
        fromEndDate: null,
        beforeEndDate: null,
      },
    );

  const organizations = useAppSelector((state) => state.lookups.organizations);
  const contractStatuses = useAppSelector(
    (state) => state.lookups.contractStatuses,
  );

  const resetFilters = () => {
    setContractsEmployeesFilters({
      organization: null,
      status: null,
      fromStartDate: null,
      beforeStartDate: null,
      fromEndDate: null,
      beforeEndDate: null,
    });
  };

  useUpdateEffect(() => {
    const fromStartDateValid =
      !contractsEmployeesFilters.fromStartDate ||
      dayjs(contractsEmployeesFilters.fromStartDate).isValid();
    const beforeStartDateValid =
      !contractsEmployeesFilters.beforeStartDate ||
      dayjs(contractsEmployeesFilters.beforeStartDate).isValid();
    const fromEndDateValid =
      !contractsEmployeesFilters.fromEndDate ||
      dayjs(contractsEmployeesFilters.fromEndDate).isValid();
    const beforeEndDateValid =
      !contractsEmployeesFilters.beforeEndDate ||
      dayjs(contractsEmployeesFilters.beforeEndDate).isValid();

    // Проверяем, что даты начала и даты окончания либо обе выбраны, либо обе не выбраны по отдельности
    const areStartDatesBothOrNone =
      !!contractsEmployeesFilters.fromStartDate ===
      !!contractsEmployeesFilters.beforeStartDate;
    const areEndDatesBothOrNone =
      !!contractsEmployeesFilters.fromEndDate ===
      !!contractsEmployeesFilters.beforeEndDate;

    // Проверяем валидность выбранных пар дат
    const areStartDatesValid = fromStartDateValid && beforeStartDateValid;
    const areEndDatesValid = fromEndDateValid && beforeEndDateValid;

    // Условие для отправки запроса: обе пары дат либо выбраны и валидны, либо обе пары не выбраны, плюс проверка других фильтров
    const shouldFetch =
      (((areStartDatesBothOrNone && areStartDatesValid) ||
        (!contractsEmployeesFilters.fromStartDate &&
          !contractsEmployeesFilters.beforeStartDate)) &&
        ((areEndDatesBothOrNone && areEndDatesValid) ||
          (!contractsEmployeesFilters.fromEndDate &&
            !contractsEmployeesFilters.beforeEndDate))) ||
      contractsEmployeesFilters.organization ||
      contractsEmployeesFilters.status;
    if (shouldFetch) {
      props.setFiltersCount(
        getCalculatedFiltersCount(contractsEmployeesFilters),
      );
      dispatch(
        getContractsEmployees({
          search: props.debouncedSearch,
          pageSize: props.contractsEmployeesPageSize,
          pageNumber: props.contractsEmployeesPageNumber,
          organizationId: contractsEmployeesFilters.organization?.id,
          status: contractsEmployeesFilters.status,
          fromStartDate: contractsEmployeesFilters?.fromStartDate,
          beforeStartDate: contractsEmployeesFilters?.beforeStartDate,
        }),
      );
    }
  }, [contractsEmployeesFilters]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Drawer
          sx={{
            width: 300,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 300,
              top: "7.5vh",
              padding: "1.2rem 0.7rem 1.2rem 1.2rem",
              boxSizing: "border-box",
            },
          }}
          anchor="right"
          onClose={() => props.setIsContractsEmployeesFiltersOpen(false)}
          open={props.isContractsEmployeesFiltersOpen}
        >
          <Box sx={{ display: "flex", marginBottom: "1.5rem" }}>
            <Button variant="text" onClick={resetFilters} sx={resetFiltersBtn}>
              {t("buttons.Reset")}
            </Button>
          </Box>
          <span>{t("data.vendor")}</span>
          <Autocomplete
            ListboxProps={{ style: { maxHeight: "200px" } }}
            value={contractsEmployeesFilters.organization}
            onChange={(event: ChangeEvent<NonNullable<unknown>>, newValue) => {
              if (newValue) {
                setContractsEmployeesFilters((prevState: IProjectsFilters) => ({
                  ...prevState,
                  organization: newValue,
                }));
              } else {
                setContractsEmployeesFilters((prevState: IProjectsFilters) => ({
                  ...prevState,
                  organization: null,
                }));
              }
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === "" ? true : option.id === value.id
            }
            options={organizations}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderOption={(props: any, option) => {
              return (
                <span {...props} className={styles.dropDownItem}>
                  {option.name}
                </span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("data.organization") as string}
                sx={{
                  "& input::placeholder": {
                    fontFamily: "Source Sans Pro",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: 1.5,
                    color: "#495057",
                    opacity: 0.8,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0",
                    border: "1px solid #8A8886",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #8A8886",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#80bdff",
                      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                    },
                  },
                  padding: "5px 0",
                }}
                size="small"
                variant="outlined"
                // placeholder={t('projects.client') as string}
              />
            )}
          />
          <span>{t("data.status")}</span>
          <Autocomplete
            ListboxProps={{ style: { maxHeight: "200px" } }}
            value={contractsEmployeesFilters.status}
            onChange={(event: ChangeEvent<NonNullable<unknown>>, newValue) => {
              if (newValue) {
                setContractsEmployeesFilters((prevState: IProjectsFilters) => ({
                  ...prevState,
                  status: newValue,
                }));
              } else {
                setContractsEmployeesFilters((prevState: IProjectsFilters) => ({
                  ...prevState,
                  status: null,
                }));
              }
            }}
            isOptionEqualToValue={(option, value) => {
              return value === "" ? true : option === value;
            }}
            options={contractStatuses}
            getOptionLabel={(option) => (option ? t(`statuses.${option}`) : "")}
            renderOption={(props: any, option) => {
              return (
                <span {...props} className={styles.dropDownItem}>
                  {t(`statuses.${option}`)}
                </span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& input::placeholder": {
                    fontFamily: "Source Sans Pro",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: 1.5,
                    color: "#495057",
                    opacity: 0.8,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0",
                    border: "1px solid #8A8886",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #8A8886",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#80bdff",
                      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                    },
                  },
                  padding: "5px 0",
                }}
                size="small"
                variant="outlined"
                placeholder={t("data.status") as string}
              />
            )}
          />
          <Box>
            <h4>{t("contracts.conclusionDate")}</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("contracts.from")}:</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                maxDate={contractsEmployeesFilters.beforeStartDate}
                value={contractsEmployeesFilters.fromStartDate}
                onChange={(newValue) => {
                  setContractsEmployeesFilters(
                    (prevState: IContractsEmployeesFilters) => ({
                      ...prevState,
                      fromStartDate: newValue,
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("contracts.before")}:</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                minDate={contractsEmployeesFilters.fromStartDate}
                value={contractsEmployeesFilters.beforeStartDate}
                onChange={(newValue) => {
                  setContractsEmployeesFilters(
                    (prevState: IContractsEmployeesFilters) => ({
                      ...prevState,
                      beforeStartDate: newValue,
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <h4>{t("contracts.endDate")}</h4>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "20px",
                  "& > label": {
                    fontWeight: 600,
                  },
                }}
              >
                <label>{t("contracts.from")}:</label>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  maxDate={contractsEmployeesFilters.beforeEndDate}
                  value={contractsEmployeesFilters.fromEndDate}
                  onChange={(newValue) => {
                    setContractsEmployeesFilters(
                      (prevState: IContractsEmployeesFilters) => ({
                        ...prevState,
                        fromEndDate: newValue,
                      }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "1px solid #8A8886",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid #8A8886",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#80bdff",
                            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                          },
                        },
                        padding: "5px 0",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t("projects.dayMonthYear") as string,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("contracts.before")}:</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                minDate={contractsEmployeesFilters.fromEndDate}
                value={contractsEmployeesFilters.beforeEndDate}
                onChange={(newValue) => {
                  setContractsEmployeesFilters(
                    (prevState: IContractsEmployeesFilters) => ({
                      ...prevState,
                      beforeEndDate: newValue,
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      // windows ctrl shift u
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Drawer>
      </LocalizationProvider>
    </div>
  );
};

export default ContractsEmployeesFilters;
