import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ILookupItem } from "models/lookups";
import {
  IFormInput,
  IOrganization,
  IOrganizationContacts,
} from "models/organizations";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { ErrorMessage } from "@hookform/error-message";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import DeleteIcon from "../../assets/icons/delete.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import hoverEditIcon from "../../assets/icons/hoverEditIcon.svg";
import redCross from "../../assets/icons/red-cross.png";
import userLogo from "../../assets/logos/user.png";
import DocumentsComponent from "../../features/Documents";
import DocumentModal from "../../features/Documents/DocumentModal";
import ContactsModal from "../../features/Forms/ContactsModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { IDocument } from "../../models/documents";
import {
  createOrganization,
  editOrganization,
  getAllowedGroupsByMenuId,
  getLookupByName,
  getOrganization,
  uploadLogo,
} from "../../store";
import { cancelBtn, saveBtn } from "../../styles/MUIStyles";
import { autocompleteStyles } from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";
import { sendNotification } from "../../ui/Toast";

import styles from "../Catalogs/CatalogsLayout.module.scss";

const EditOrganizationForm = () => {
  const fileInput = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm<IFormInput>();

  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const organizationDetails = useAppSelector(
    (state) => state.organizations.organizationDetails,
  );
  const currentLanguage = localStorage.getItem("i18nextLng");
  const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

  const employees = useAppSelector((state) => state.lookups.employees);
  const organizationTypes = useAppSelector(
    (state) => state.lookups.organizationTypes,
  );

  const [contactModalOpen, setContactModalOpen] = useState<{
    isOpen: boolean;
    actionName: string;
  }>({ isOpen: false, actionName: "" });
  const [activeOrganization, setActiveOrganization] =
    useState<IOrganization | null>(null);
  const [documentModalOpen, setDocumentModalOpen] = useState<{
    isOpen: boolean;
    actionName: string;
  }>({ isOpen: false, actionName: "" });
  const [activeDocument, setActiveDocument] = useState<IDocument | null>(null);
  const [organizationsActiveTab, setOrganizationsActiveTab] = useLocalStorage(
    "employeesActiveTab",
    0,
  );

  const [avatar, setAvatar] = useState<string>();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const countries = useAppSelector((state) => state.lookups.countries);
  const [activeContact, setActiveContact] =
    useState<IOrganizationContacts | null>(null);

  const location = useLocation(); // Get the location object
  const isCreateMode = location.pathname.includes("create");

  const addContact = () => {
    setValue("addContacts", []);
    setValue("editContacts", []);

    setContactModalOpen({ isOpen: true, actionName: "Create" });

    forceUpdate();
  };

  const editContact = (contact: IOrganizationContacts) => {
    forceUpdate();
    setActiveContact(contact);
    setContactModalOpen({ isOpen: true, actionName: "Edit" });
  };

  const deleteContact = (
    event: SyntheticEvent,
    contact: IOrganizationContacts,
  ) => {
    event.preventDefault();

    if (contact.id) {
      const deletedContactIds = getValues("deleteContactIds") || [];
      if (!deletedContactIds.includes(contact.id!)) {
        deletedContactIds.push(contact.id!);
        setValue("deleteContactIds", deletedContactIds);
      }
    }

    if (
      getValues("editContacts")?.length &&
      getValues("editContacts")?.find((el) => el.id === contact.id)
    ) {
      const editContacts = getValues("editContacts");
      setValue(
        "editContacts",
        editContacts?.filter((el) => el.id !== contact.id),
      );
    }

    const newContacts = getValues("contacts");
    setValue(
      "contacts",
      newContacts?.filter((el) => el.id !== contact.id),
    );
    const contacts = getValues("contacts") || [];
    const updatedContacts = contacts.filter((el) => el.id !== contact.id);
    setValue("contacts", updatedContacts);

    forceUpdate();
    setValue("addContacts", []);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    reset({ addContacts: [] }, { keepValues: true });
    const body = {
      code: formData.code,
      status: formData.status,
      shortName: formData.shortName,
      fullName: formData.fullName,
      description: formData.description,
      registrationAddress: formData.registrationAddress,
      actualAddress: formData.actualAddress,
      phoneNumber: formData.phoneNumber,
      emailAddress: formData.emailAddress,
      type: formData.type,
      url: formData.url,
      city: formData.city,
      countryId: formData.countryId,
      managerIds: formData.managerIds.filter((el) => el !== ""),
      editContacts:
        getValues("editContacts")?.filter((item) => item.isSaved) || [],
      deleteContactIds: getValues("deleteContactIds") || [],
      addContacts: formData.addContacts,
      contacts: formData.contacts,
    };

    if (isCreateMode) {
      const response = await dispatch(
        createOrganization({
          ...body,
        }),
      );

      if (response.meta.requestStatus === "fulfilled") {
        sendNotification(t("notifications.successfullySaved"), "success");
        navigate("/organizations"); // Redirect to the organizations list after creation
      }
    } else if (activeOrganization) {
      let newAddContacts: Array<IOrganizationContacts> = [];
      if (formData.contacts?.length) {
        newAddContacts = formData.contacts
          .filter((el) => el.isSaved === false)
          .map((el) => ({
            name: el.name,
            phoneNumber: el.phoneNumber,
            emailAddress: el.emailAddress,
          }));
      }

      const response = await dispatch(
        editOrganization({
          ...body,
          id: activeOrganization?.id,
          addContacts: newAddContacts,
          editContacts: body.editContacts,
          deleteContactIds: body.deleteContactIds,
        }),
      );

      if (response.meta.requestStatus === "fulfilled") {
        sendNotification(t("notifications.successfullySaved"), "success");

        setValue("editContacts", []);
        setValue("deleteContactIds", []);
        setValue("addContacts", []);
      }
      getOrgzn();
    }
  };

  const getOrgzn = async () => {
    const employee = await dispatch(getOrganization(Number(id!)));
    setActiveOrganization(employee.payload);
    setAvatar(employee?.payload?.logo);
    setFormData(employee.payload);
  };

  const employeeAvatarSave = async (image: File) => {
    if (image) {
      const response = await dispatch(
        uploadLogo({ organizationId: id, logo: image }),
      );
      if (response.meta.requestStatus === "fulfilled") {
        setAvatar(response.payload);
      }
      if (response.meta.requestStatus === "rejected") {
        sendNotification(response.payload.avatar[0], "error");
      }
    }
  };
  const employeesOptions = useMemo(
    () =>
      employees.filter(
        (em) => !getValues("managerIds")?.includes(String(em.id)),
      ),
    [employees, getValues("managerIds")],
  );
  const tabChangeHandler = (event: SyntheticEvent, newValue: number) => {
    setOrganizationsActiveTab(newValue);
    // setActiveTab(newValue)
  };

  const onStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue("status", event.target.checked ? 1 : 2);
    setValue("isActive", event.target.checked ? true : false);
  };

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const editDocumentsList = (document: IDocument, action: string) => {
    setActiveOrganization((prevState) => {
      if (!prevState) return null;
      switch (action) {
        case "add":
          return {
            ...prevState,
            documents: prevState.documents.length
              ? [...prevState.documents, document]
              : [document],
          };
        case "edit":
          // eslint-disable-next-line no-case-declarations
          const index = prevState.documents.findIndex(
            (el) => el.id === document.id,
          );
          return {
            ...prevState,
            documents: [
              ...prevState.documents.slice(0, index),
              document,
              ...prevState.documents.slice(
                index + 1,
                prevState.documents.length,
              ),
            ],
          };
        case "delete":
          return {
            ...prevState,
            documents: prevState.documents.filter(
              (el) => el.id !== document.id,
            ),
          };
        default:
          return prevState;
      }
    });
  };

  const handleImageUploadClick = () => {
    // @ts-ignore
    fileInput?.current?.click();
  };

  const handleImageUpload = (event: any) => {
    employeeAvatarSave(event.target.files[0]);
  };

  const setFormData = (fields: IFormInput | null) => {
    //    fields(key, value)

    setValue("code", fields?.code || "");
    setValue("shortName", fields?.shortName || "");
    setValue("fullName", fields?.fullName || "");
    setValue("description", fields?.description || "");
    setValue("registrationAddress", fields?.registrationAddress || "");
    setValue("actualAddress", fields?.actualAddress || "");
    setValue("phoneNumber", fields?.phoneNumber || "");
    setValue("emailAddress", fields?.emailAddress || "");
    setValue("url", fields?.url || "");
    setValue("countryId", fields?.countryId || "");
    setValue("type", fields?.type || "");
    setValue("isActive", fields?.isActive || false);
    setValue("status", fields?.status);
    setValue(
      "managerIds",
      fields?.managers?.length
        ? fields.managers.map((manager) => manager.id.toString())
        : [],
    );
    setValue("contacts", fields?.contacts?.length ? [...fields?.contacts] : []);
    setValue(
      "editContacts",
      fields?.contacts?.length ? [...fields?.contacts] : [],
    );
    setValue("deleteContactIds", fields?.deleteContactIds);
    setValue("addContacts", []);
  };

  useEffect(() => {
    const menu = allowedMenus.find((menu) => menu.name === "Organizations");
    typeof menu?.id === "number" && dispatch(getAllowedGroupsByMenuId(menu.id));

    dispatch(getLookupByName("countries"));
    dispatch(getLookupByName("organizations"));
  }, [dispatch, t, allowedMenus]);

  const addManager = () => {
    if (getValues("managerIds").length) {
      if (!getValues("managerIds").includes("")) {
        const newManagers = [...getValues("managerIds"), ""];
        setValue("managerIds", newManagers);
        forceUpdate();
      }
    } else {
      setValue("managerIds", [""]);
      forceUpdate();
    }
  };
  const deleteManager = (
    event: React.SyntheticEvent,
    manager: string | null,
  ) => {
    if (manager === "") {
      const newManagers = getValues("managerIds");
      setValue("managerIds", newManagers.slice(0, newManagers.length - 1));
    }

    event.preventDefault();
    const newManagers = getValues("managerIds");
    setValue(
      "managerIds",
      newManagers.filter((el) => el !== manager),
    );
    forceUpdate();
  };

  useEffect(() => {
    if (id) {
      getOrgzn();
    }
  }, [id, t]);

  useEffect(() => {
    setAvatar("");
    setFormData(null);
  }, []);
  const [currentManagers, setCurrentManagers] = useState(
    getValues("managerIds") || [],
  );

  useEffect(() => {
    setCurrentManagers(getValues("managerIds"));
  }, [getValues("managerIds")]);

  const clearContactForm = () => {
    setContactModalOpen({ isOpen: false, actionName: "" });
    setActiveContact(null);
  };

  const alterEditedContactsArray = (contact: IOrganizationContacts) => {
    const editContacts = getValues("editContacts") || [];

    const existingContactIndex = editContacts.findIndex(
      (el) => el.id === contact.id,
    );

    if (existingContactIndex === -1) {
      editContacts.push({ ...contact, isSaved: true });
    } else {
      editContacts[existingContactIndex] = { ...contact, isSaved: true };
    }

    setValue("editContacts", editContacts);
  };

  return (
    <div style={{ padding: "10px 30px" }}>
      <div
        role="presentation"
        onClick={handleClick}
        style={{ marginBottom: "20px" }}
      >
        <Breadcrumbs sx={{ padding: "10px 10px" }}>
          <Link to="/organizations" className={styles.breadScrumbsLink}>
            {t("organizations.organizations")}
          </Link>
          <Typography sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}>
            {isCreateMode ? "" : organizationDetails?.fullName}
          </Typography>
        </Breadcrumbs>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={organizationsActiveTab} onChange={tabChangeHandler}>
          <Tab label={t("tabs.general")} value={0} />
          <Tab label={t("tabs.contacts")} value={1} disabled={isCreateMode} />
          <Tab label={t("tabs.docs")} value={2} disabled={isCreateMode} />
          {/*<Tab label={t("tabs.history")} value={3} />*/}
        </Tabs>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            margin: "15px 0",
          }}
        >
          <h3>{isCreateMode ? "" : organizationDetails?.fullName}</h3>

          <div>
            <ButtonWithProgress
              loading={false}
              disabled={false}
              type="submit"
              variant="contained"
              sx={saveBtn}
            >
              {t("buttons.Save")}
            </ButtonWithProgress>

            <Button
              variant="text"
              onClick={() => {
                if (isDirty) {
                  setConfirmationModalOpen(true);
                } else {
                  navigate(-1);
                }
              }}
              sx={cancelBtn}
            >
              {t("buttons.Cancel")}
            </Button>
          </div>
        </div>

        {organizationsActiveTab === 0 && (
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="status"
                  control={control}
                  render={() => (
                    <FormGroup
                      sx={{
                        display: "inline-block",
                        mb: "5px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={getValues("isActive") === true}
                            onChange={(event) => {
                              onStatusChange(event);
                            }}
                          />
                        }
                        label={
                          <span className={styles.checkboxLabel}>
                            {t("data.isActive")}
                          </span>
                        }
                      />
                    </FormGroup>
                  )}
                />
                {!isCreateMode && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "10px",
                      alignItems: "flex-start",
                    }}
                  >
                    <img
                      src={
                        avatar
                          ? process.env.REACT_APP_STATIC_FILES_URL + avatar
                          : userLogo
                      }
                      alt=""
                      style={{ maxWidth: "170px" }}
                    />
                    <input
                      accept="image/*"
                      type="file"
                      ref={fileInput}
                      onChange={handleImageUpload}
                      style={{ display: "none" }}
                    />
                    <img
                      src={hoverEditIcon}
                      alt=""
                      onClick={handleImageUploadClick}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                )}
              </div>
              <div>
                <textarea
                  {...register("description", {
                    maxLength: {
                      value: 500,
                      message: t("validation.maxLength500"),
                    },
                  })}
                  id="description"
                  autoComplete="off"
                  rows={6}
                  cols={50}
                  placeholder={t("data.description") as string}
                  className={
                    errors?.description
                      ? styles.catalogsDrawerInputError
                      : styles.catalogsDrawerInput
                  }
                />
                <ErrorMessage
                  name="description"
                  errors={errors}
                  render={({ message }) => <span>{message}</span>}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "500px" }}>
                <label htmlFor="fullName" className={styles.drawerTopSubTitle}>
                  {t("data.fullName")}
                </label>
                <div>
                  <input
                    {...register("fullName", {
                      required: t("validation.requiredField") as string,
                      maxLength: {
                        value: 30,
                        message: t("validation.maxLength30"),
                      },
                    })}
                    id="fullName"
                    type="text"
                    autoComplete="off"
                    placeholder={t("data.fullName") as string}
                    className={
                      errors.fullName
                        ? styles.catalogsDrawerInputError
                        : styles.catalogsDrawerInput
                    }
                  />
                  <ErrorMessage
                    name="fullName"
                    errors={errors}
                    render={({ message }) => <span>{message}</span>}
                  />
                </div>
                <label htmlFor="shortName" className={styles.drawerTopSubTitle}>
                  {t("data.shortName")}
                </label>
                <div>
                  <input
                    {...register("shortName", {
                      required: t("validation.requiredField") as string,
                      maxLength: {
                        value: 30,
                        message: t("validation.maxLength30"),
                      },
                    })}
                    id="shortName"
                    type="text"
                    autoComplete="off"
                    placeholder={t("data.shortName") as string}
                    className={
                      errors.shortName
                        ? styles.catalogsDrawerInputError
                        : styles.catalogsDrawerInput
                    }
                  />
                  <ErrorMessage
                    name="shortName"
                    errors={errors}
                    render={({ message }) => <span>{message}</span>}
                  />
                </div>

                <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                  <span className={styles.drawerTopSubTitle}>
                    {t("data.type")}
                  </span>
                  <Controller
                    name="type"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: t("validation.requiredField") as string,
                    }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={autocompleteStyles}
                            placeholder={t("data.type") || ""}
                            error={!!fieldState.error}
                          />
                        )}
                        options={organizationTypes}
                        getOptionLabel={(option) =>
                          t(`organizationTypes.${option}`)
                        }
                        onChange={(event, value: any | null) =>
                          field.onChange(
                            organizationTypes.indexOf(value) + 1 || null,
                          )
                        }
                        value={
                          organizationTypes[
                            parseInt(getValues("type")?.toString()) - 1
                          ] || null
                        }
                      />
                    )}
                  />
                  <ErrorMessage
                    name="type"
                    errors={errors}
                    render={({ message }) => (
                      <span className={styles.selectErrorMsg}>{message}</span>
                    )}
                  />
                </FormControl>
                <label htmlFor="code" className={styles.drawerTopSubTitle}>
                  {t("data.code")}
                </label>
                <div>
                  <input
                    {...register("code", {
                      required: t("validation.requiredField") as string,
                      minLength: {
                        value: 5,
                        message: t("validation.mustLength5"),
                      },
                      maxLength: {
                        value: 5,
                        message: t("validation.mustLength5"),
                      },
                    })}
                    id="code"
                    type="text"
                    autoComplete="off"
                    placeholder={t("data.code") as string}
                    className={
                      errors?.code
                        ? styles.catalogsDrawerInputError
                        : styles.catalogsDrawerInput
                    }
                  />
                  <ErrorMessage
                    name="code"
                    errors={errors}
                    render={({ message }) => <span>{message}</span>}
                  />
                </div>
                {getValues("managerIds")?.length ? (
                  <span className={styles.drawerTopSubTitle}>
                    {t("data.managers")}
                  </span>
                ) : (
                  <span />
                )}
                {currentManagers?.map((manager, index) => (
                  <div
                    key={manager}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Controller
                        name={`managerIds.${index}`}
                        defaultValue=""
                        control={control}
                        // rules={{required: createOrUpdate === 'create' ? t('validation.requiredField') as string : false}}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            ListboxProps={{
                              style: {
                                maxHeight: "200px",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                placeholder={t("data.manager") || ""}
                                error={!!fieldState.error}
                                sx={autocompleteStyles}
                                // helperText={fieldState.error?.message}
                              />
                            )}
                            options={employeesOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value: any | null) => {
                              field.onChange(value?.id);
                            }}
                            value={
                              employees.filter(
                                (el) =>
                                  el?.id === getValues(`managerIds.${index}`),
                              )[0] || null
                            }
                          />
                        )}
                      />
                      <ErrorMessage
                        name={`managerIds.${index}`}
                        errors={errors}
                        render={({ message }) => (
                          <span className={styles.selectErrorMsg}>
                            {message}
                          </span>
                        )}
                      />
                    </FormControl>

                    <button onClick={(event) => deleteManager(event, manager)}>
                      <img src={redCross} alt="img" />
                    </button>
                  </div>
                ))}
                <Button
                  variant="contained"
                  sx={saveBtn}
                  onClick={() => addManager()}
                >
                  {t("buttons.AddManager")}
                </Button>
              </div>

              <div
                className={styles.organizationFormWrapper}
                style={{ width: "500px" }}
              >
                <Box>
                  <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
                    <span className={styles.drawerTopSubTitle}>
                      {t("data.country")}
                    </span>
                    <Controller
                      name="countryId"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: t("validation.requiredField") as string,
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          ListboxProps={{
                            style: {
                              maxHeight: "200px",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={autocompleteStyles}
                              placeholder={t("data.country") || ""}
                              error={!!fieldState.error}
                            />
                          )}
                          options={[...countries].sort(
                            (a: ILookupItem, b: ILookupItem) => {
                              return a.name.localeCompare(
                                b.name,
                                currentLanguage === "en-US" ? "en" : "ru",
                              );
                            },
                          )}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value: any | null) =>
                            field.onChange(value?.id)
                          }
                          value={
                            countries.filter(
                              (el) => el?.id === getValues("countryId"),
                            )[0] || null
                          }
                        />
                      )}
                    />
                    <ErrorMessage
                      name="countryId"
                      errors={errors}
                      render={({ message }) => (
                        <span className={styles.selectErrorMsg}>{message}</span>
                      )}
                    />
                  </FormControl>

                  <label
                    htmlFor="registrationAddress"
                    className={styles.drawerTopSubTitle}
                  >
                    {t("data.registrationAddress")}
                  </label>
                  <div>
                    <input
                      {...register("registrationAddress", {
                        maxLength: {
                          value: 500,
                          message: t("validation.maxLength500"),
                        },
                      })}
                      id="registrationAddress"
                      type="text"
                      autoComplete="off"
                      placeholder={t("data.registrationAddress") as string}
                      className={
                        errors?.registrationAddress
                          ? styles.catalogsDrawerInputError
                          : styles.catalogsDrawerInput
                      }
                    />
                    <ErrorMessage
                      name="registrationAddress"
                      errors={errors}
                      render={({ message }) => <span>{message}</span>}
                    />
                  </div>

                  <label
                    htmlFor="actualAddress"
                    className={styles.drawerTopSubTitle}
                  >
                    {t("data.actualAddress")}
                  </label>
                  <div>
                    <input
                      {...register("actualAddress", {
                        maxLength: {
                          value: 500,
                          message: t("validation.maxLength500"),
                        },
                      })}
                      id="actualAddress"
                      type="text"
                      autoComplete="off"
                      placeholder={t("data.actualAddress") as string}
                      className={
                        errors?.actualAddress
                          ? styles.catalogsDrawerInputError
                          : styles.catalogsDrawerInput
                      }
                    />
                    <ErrorMessage
                      name="actualAddress"
                      errors={errors}
                      render={({ message }) => <span>{message}</span>}
                    />
                  </div>

                  <label
                    htmlFor="phoneNumber"
                    className={styles.drawerTopSubTitle}
                  >
                    {t("data.phoneNumber")}
                  </label>
                  <div>
                    <input
                      {...register("phoneNumber", {
                        maxLength: {
                          value: 200,
                          message: t("validation.maxLength200"),
                        },
                      })}
                      id="phoneNumber"
                      type="text"
                      autoComplete="off"
                      placeholder={t("data.phoneNumber") as string}
                      className={
                        errors?.phoneNumber
                          ? styles.catalogsDrawerInputError
                          : styles.catalogsDrawerInput
                      }
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      errors={errors}
                      render={({ message }) => <span>{message}</span>}
                    />
                  </div>

                  <label
                    htmlFor="emailAddress"
                    className={styles.drawerTopSubTitle}
                  >
                    {t("data.email")}
                  </label>
                  <div>
                    <input
                      {...register("emailAddress", {
                        maxLength: {
                          value: 150,
                          message: t("validation.maxLength150"),
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                          message: t("validation.invalidEmail") as string,
                        },
                      })}
                      id="emailAddress"
                      type="text"
                      autoComplete="off"
                      placeholder={t("data.email") as string}
                      className={
                        errors?.emailAddress
                          ? styles.catalogsDrawerInputError
                          : styles.catalogsDrawerInput
                      }
                    />
                    <ErrorMessage
                      name="emailAddress"
                      errors={errors}
                      render={({ message }) => <span>{message}</span>}
                    />
                  </div>

                  <label htmlFor="url" className={styles.drawerTopSubTitle}>
                    {t("data.url")}
                  </label>
                  <div>
                    <input
                      {...register("url", {
                        maxLength: {
                          value: 1024,
                          message: t("validation.maxLength1024"),
                        },
                      })}
                      id="url"
                      type="text"
                      autoComplete="off"
                      placeholder={t("data.url") as string}
                      className={
                        errors?.url
                          ? styles.catalogsDrawerInputError
                          : styles.catalogsDrawerInput
                      }
                    />
                    <ErrorMessage
                      name="url"
                      errors={errors}
                      render={({ message }) => <span>{message}</span>}
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>
        )}

        {organizationsActiveTab === 1 && (
          <div style={{ marginTop: "15px" }}>
            {getValues("contacts")?.map((contact) => (
              <div
                key={contact.id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  maxWidth: "500px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <div>
                    <img
                      src={DocumentIcon}
                      alt=""
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                  <div>
                    <h3>{contact.name}</h3>
                    <p>{contact.phoneNumber}</p>
                    <p>{contact.emailAddress}</p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "5px",
                  }}
                >
                  <img
                    src={DeleteIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={(event) => deleteContact(event, contact)}
                  />
                  <img
                    src={hoverEditIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => editContact(contact)}
                  />
                </div>
              </div>
            ))}

            <Button
              variant="contained"
              sx={saveBtn}
              onClick={() => addContact()}
            >
              {t("buttons.AddContact")}
            </Button>

            <ContactsModal
              isModalOpen={contactModalOpen.isOpen}
              clearForm={clearContactForm}
              actionName={contactModalOpen.actionName}
              contact={activeContact}
              activeEntityId={activeContact?.id ? activeContact.id : null}
              getValues={getValues}
              setValue={setValue}
              alterEditedContactsArray={alterEditedContactsArray}
            />

            {/* getValues={getValues} setValue={setValue} control={control} register={register} // можно закомментить */}
          </div>
        )}
        {organizationsActiveTab === 2 && (
          <DocumentsComponent
            documents={organizationDetails?.documents || []}
            entity="organizations"
            editDocumentsList={editDocumentsList}
            activeDocument={activeDocument}
            setActiveDocument={setActiveDocument}
            setDocumentModalOpen={setDocumentModalOpen}
          />
        )}
      </form>

      {documentModalOpen.isOpen && documentModalOpen.actionName ? (
        <DocumentModal
          isModalOpen={documentModalOpen.isOpen}
          setIsModalOpen={setDocumentModalOpen}
          actionName={documentModalOpen.actionName}
          document={activeDocument}
          instanceName="organizations"
          activeEntityId={id!}
          editDocumentsList={editDocumentsList}
        />
      ) : null}
    </div>
  );
};

export default EditOrganizationForm;
