import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { EditIcon, FilterIcon, ViewIcon } from "assets/icons";
import { getCalculatedFiltersCount } from "core/utils";
import Guard from "features/Guard";
import {
  useAppDispatch,
  useAppSelector,
  useSortColumn,
  useUpdateEffect,
} from "hooks";
import useLocalStorage from "hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAllowedGroupsByMenuId,
  getAllowedPermissionsByGroupId,
  getLookupByName,
} from "store";
import { getDaysOffAll } from "store/thunks/vacationsSickDaysThunk";
import { createOrEditBtn } from "styles/MUIStyles";
import { useDebounce } from "usehooks-ts";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import {
  Badge,
  Button,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";

import VacationsSickDaysTable from "./VacationsSickDaysTable/VacationsSickDaysTable";
import VacationsSickFilterPanel from "./VacationsSickFilterPanel/VacationsSickFilterPanel";

import styles from "./SickLeave.module.scss";

// eslint-disable-next-line react/display-name
const VacationsSickDays: FC = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentDate = new Date();

  const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
  const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);

  const vacationsSickDaysPageCount = useAppSelector(
    (state) => state.vacationsSickDays.vacationsSickDaysPageCount,
  );

  const [year, setYear] = useLocalStorage<number>(
    "vacationsSickDaysFilterYear",
    currentDate.getFullYear(),
  );
  const [vacationsSickDaysPageNumber, setVacationsSickDaysPageNumber] =
    useLocalStorage<number>("vacationsSickDaysPageNumber", 1);
  const [vacationsSickDaysPageSize, setVacationsSickDaysPageSize] =
    useLocalStorage<number>("vacationsSickDaysPageSize", 10);

  const [fullNameSearch, setFullNameSearch] = useLocalStorage<string>(
    "vacationsSickDaysFullNameSearch",
    "",
  );

  const initialStateFilters: any = {
    organizationId: null,
    positionId: null,
  };

  const [vacationsSickDaysFilters, setVacationsSickDaysFilters] =
    useLocalStorage<string>("vacationsSickDaysFilter", initialStateFilters);

  const [filtersCount, setFiltersCount] = useLocalStorage(
    "vacationsSickDaysFiltersBadge",
    0,
  );

  const activeVacationSickDay = useAppSelector(
    (state) => state.vacationsSickDays.activeVacationSickDay,
  );

  const [isOpenFilterPanel, setIsOpenFilterPanel] = useState<boolean>(false);

  const prevVacationsSickDaysFiltersRef = useRef<any>(null);

  const debouncedFullNameSearch = useDebounce(fullNameSearch, 1000);

  const prevVacationsSickDaysFilters = prevVacationsSickDaysFiltersRef.current;

  const {
    sortConfig: sortConfigVacationsSickDays,
    handleColumnClick: handleChangeSortingVacationsSickDays,
  } = useSortColumn("vacationsSickDaysSortConfig");

  useEffect(() => {
    prevVacationsSickDaysFiltersRef.current = vacationsSickDaysFilters;
  });

  const allowedPermissions = useAppSelector(
    (state) => state.auth.allowedPermissions,
  );

  //To avoid duplicate requests and reset pageNumber
  const checkPageNumber = useMemo(() => {
    return (prevVacationsSickDaysFilters &&
      vacationsSickDaysFilters !== prevVacationsSickDaysFilters) ||
      debouncedFullNameSearch
      ? 1
      : vacationsSickDaysPageNumber;

    // eslint-disable-next-line
  }, [
    vacationsSickDaysFilters,
    debouncedFullNameSearch,
    vacationsSickDaysPageNumber,
  ]);

  useEffect(() => {
    dispatch(
      getDaysOffAll({
        year,
        vacationsSickDaysPageNumber: checkPageNumber,
        vacationsSickDaysPageSize,
        debouncedFullNameSearch,
        sortConfigVacationsSickDays,
        vacationsSickDaysFilters,
      }),
    );

    setVacationsSickDaysPageNumber(checkPageNumber);

    // eslint-disable-next-line
  }, [
    t,
    year,
    vacationsSickDaysPageSize,
    checkPageNumber,
    debouncedFullNameSearch,
    sortConfigVacationsSickDays,
    vacationsSickDaysFilters,
  ]);

  useUpdateEffect(() => {
    setFiltersCount(getCalculatedFiltersCount(vacationsSickDaysFilters));
  }, [vacationsSickDaysFilters]);

  useEffect(() => {
    dispatch(getLookupByName("organizations"));
    dispatch(getLookupByName("positions"));
  }, [t, dispatch]);

  const handleChangeYear = (year: number | undefined): void => {
    if (year === undefined) {
      return;
    }

    setYear(year);
  };

  const resetFilters = () => {
    setVacationsSickDaysFilters(initialStateFilters);
  };

  //Pafination
  const handleChangeVacationsSickDaysPageSize = async (
    e: SelectChangeEvent,
  ) => {
    setVacationsSickDaysPageSize(e.target.value);
    setVacationsSickDaysPageNumber(1);
  };

  const handleChangeVacationsSickDaysPageNumber = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ): void => {
    setVacationsSickDaysPageNumber(page);
  };

  useEffect(() => {
    const menu = allowedMenus.find((menu) => menu.name === "DaysOff");
    typeof menu?.id === "number" && dispatch(getAllowedGroupsByMenuId(menu.id));
  }, [dispatch, allowedMenus]);

  useEffect(() => {
    const group = allowedGroups.find((menu) => menu.name === `api/daysOff`);
    group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
  }, [dispatch, allowedGroups]);

  return (
    <div>
      <div className={styles.box_actions}>
        <div>
          <Guard allowedPermissions={allowedPermissions} permissionName="View">
            <Button
              startIcon={<ViewIcon />}
              variant="text"
              onClick={() => {
                if (activeVacationSickDay) {
                  navigate(`${year}/${activeVacationSickDay.employeeId}`);
                }
              }}
              sx={createOrEditBtn}
              disabled={!activeVacationSickDay}
            >
              {t("buttons.View")}
            </Button>
          </Guard>
          <Guard allowedPermissions={allowedPermissions} permissionName="Edit">
            <Button
              startIcon={<EditIcon />}
              variant="text"
              onClick={() => {
                if (activeVacationSickDay) {
                  navigate(`${year}/${activeVacationSickDay.employeeId}/edit`);
                }
              }}
              sx={createOrEditBtn}
              disabled={!activeVacationSickDay}
            >
              {t("buttons.Edit")}
            </Button>
          </Guard>
        </div>
        <Stack direction="row" spacing={1} alignItems="center">
          <p>{t("filters.elementsOnPage")}</p>
          <FormControl variant="standard" sx={{ m: 1 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={vacationsSickDaysPageSize}
              onChange={handleChangeVacationsSickDaysPageSize}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Badge
            color="primary"
            badgeContent={filtersCount}
            sx={{ "& span": { fontSize: "12px" } }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Button
              startIcon={<FilterIcon />}
              variant="text"
              onClick={() => setIsOpenFilterPanel(true)}
              sx={createOrEditBtn}
            >
              {t("buttons.Filters")}
            </Button>
          </Badge>
        </Stack>
      </div>
      <div className={styles.box_year}>
        <ArrowLeftIcon
          htmlColor="gray"
          sx={{ color: "green", fontSize: "40px" }}
          onClick={() => handleChangeYear(year - 1)}
        />
        <p>{year}</p>
        <ArrowRightIcon
          htmlColor="gray"
          sx={{
            color: "green",
            fontSize: "40px",
          }}
          onClick={() => handleChangeYear(year + 1)}
        />
      </div>
      <div
        className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
      >
        <div className={styles.searchWrapper}>
          <input
            type="text"
            placeholder={t("filters.searchByNameAndSurname") as string}
            value={fullNameSearch}
            onChange={(e) => {
              setFullNameSearch(e.target.value);
            }}
          />
          <button type="button" className={styles.searchWrapper_icon}>
            <SearchIcon sx={{ color: "#fff" }} />
          </button>
        </div>
        <VacationsSickDaysTable
          sortConfig={sortConfigVacationsSickDays}
          handleChangeSorting={handleChangeSortingVacationsSickDays}
          search={debouncedFullNameSearch}
          setSearch={setFullNameSearch}
          year={year}
          handleChangeYear={handleChangeYear}
        />
      </div>
      <Stack spacing={2} alignItems="center">
        <Pagination
          sx={{ margin: "auto" }}
          count={vacationsSickDaysPageCount as number}
          page={vacationsSickDaysPageNumber}
          size="medium"
          onChange={handleChangeVacationsSickDaysPageNumber}
          shape="rounded"
          color="primary"
          variant="outlined"
        />
      </Stack>
      <VacationsSickFilterPanel
        isOpenFilterPanel={isOpenFilterPanel}
        setIsOpenFilterPanel={setIsOpenFilterPanel}
        filters={vacationsSickDaysFilters}
        setFilters={setVacationsSickDaysFilters}
        resetFilters={resetFilters}
      />
    </div>
  );
});

export default VacationsSickDays;
