import { useEffect } from "react";

import Box from "@mui/material/Box";

import { BigCalendar } from "../../../features/BigCalendar";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getCalendarData,
} from "../../../store";

export const PersonalCalendar = () => {
    const dispatch = useAppDispatch();
    const eventData = useAppSelector(
        (state) => state.personalCabinet.eventData,
    );

    // useEffect(() => {
    //     (async () => {
    //         if (userId) {
    //             setIsLoading(true);
    //             await Promise.all([
    //                 dispatch(getEmployeeById(userId)),
    //                 dispatch(getAllSkills()),
    //                 dispatch(getAllSkillLevels()),
    //             ]);
    //             setIsLoading(false);
    //         }
    //     })();
    // }, [dispatch, userId, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        dispatch(getCalendarData());
    }, []);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Employees");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/employees`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    // if (!employeeDetails || isLoading) {
    //     return <Loader />;
    // }

    return (
        <Box sx={{ width: "100%", padding: "10px 0" }}>
            <BigCalendar data={eventData} />
        </Box>
    );
};
