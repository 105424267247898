import React, { FC } from "react";
import Guard from "features/Guard";
import { useTranslation } from "react-i18next";

import locked from "../../assets/icons/locked.svg";
import unlocked from "../../assets/icons/unlocked.svg";
import { useAppSelector } from "../../hooks";
import BootstrapTooltip from "../../ui/Tooltip";

const Index: FC<{
  userId: string,
  setConfirmationModalOpen: Function,
  isUserActive: boolean
}> = ({ setConfirmationModalOpen, isUserActive }) => {

  const { t } = useTranslation();

  const allowedPermissions = useAppSelector(state => state.auth.allowedPermissions);

  const handleClick = (actionName: string) => {
    setConfirmationModalOpen({ isOpen: true, actionName: actionName });
  };

  return (
    <span>
      {
        isUserActive ? (
          <Guard
            allowedPermissions={allowedPermissions}
            permissionName='Deactivate'
          >
            <BootstrapTooltip
              sx={{ position: 'absolute' }}
              title={t('buttons.Block')}
              placement="top">
              <img
                onClick={() => {
                  handleClick('Block');
                }}
                src={locked} alt=""
              />
            </BootstrapTooltip>
          </Guard>
        ) : !isUserActive ? (
            <Guard
              allowedPermissions={allowedPermissions}
              permissionName='Activate'
            >
              <BootstrapTooltip title={t('buttons.Unblock')} placement="top">
                <img
                  onClick={() => {
                    handleClick('Unblock');
                  }}
                  src={unlocked} alt=""
                />
              </BootstrapTooltip>
            </Guard>
          )
          : null
      }
            </span>
  );
};

export default Index;
