import { configureStore, createAction } from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import catalogReducer from "./slices/catalogSlice";
import commonDocumentsReducer from "./slices/commonDocumentsSlise";
import contractsReducer from "./slices/contractsSlice";
import employeesReducer from "./slices/employeesSlice";
import importsReducer from "./slices/importSlice";
import lookupsReducer from "./slices/lookupsSlice";
import organizationsReducer from "./slices/organizationsSlice";
import personalCabinetReducer from "./slices/personalCabinetSlice";
import projectsReducer from "./slices/projectsSlice";
import rateReducer from "./slices/rateSlice";
import salariesReducer from "./slices/salariesSlice";
import trackingReducer from "./slices/trackingSlice";
import usersReducer from "./slices/usersSlice";
import vacationsSickDaysReducer from "./slices/vacationsSickDaysSlice";

export const revertAll = createAction("REVERT_ALL");

const store = configureStore({
    reducer: {
        auth: authReducer,
        catalogs: catalogReducer,
        users: usersReducer,
        organizations: organizationsReducer,
        employees: employeesReducer,
        projects: projectsReducer,
        lookups: lookupsReducer,
        salaries: salariesReducer,
        vacationsSickDays: vacationsSickDaysReducer,
        tracking: trackingReducer,
        contracts: contractsReducer,
        rate: rateReducer,
        imports: importsReducer,
        commonDocuments: commonDocumentsReducer,
        personalCabinet: personalCabinetReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
