import { saveBtn } from "styles/MUIStyles";

import { Button } from "@mui/material";

import { Text } from "../../ui/Text/Text";

interface CustomToolbarProps {
    onNavigate: (action: "TODAY" | "PREV" | "NEXT") => void;
    onView: (view: "day" | "week" | "month") => void;
    label: string;
}

export const CustomToolbar = ({
    onNavigate,
    onView,
    label,
}: CustomToolbarProps) => {
    return (
        <div className="toolbar">
            <div>
                <Button sx={saveBtn} onClick={() => onNavigate("TODAY")}>
                    <Text className="navbar-text" variant="REGULAR">
                        Сегодня
                    </Text>
                </Button>

                <Button sx={saveBtn} onClick={() => onNavigate("PREV")}>
                    <Text className="navbar-text" variant="REGULAR">
                        Назад
                    </Text>
                </Button>
                <Button
                    style={{
                        marginLeft: "auto",
                    }}
                    sx={saveBtn}
                    onClick={() => onNavigate("NEXT")}
                >
                    <Text className="navbar-text" variant="REGULAR">
                        Вперед
                    </Text>
                </Button>
            </div>
            <span>{label}</span>
            <div>
                <Button sx={saveBtn} onClick={() => onView("day")}>
                    <Text className="navbar-text" variant="REGULAR">
                        День
                    </Text>
                </Button>
                <Button sx={saveBtn} onClick={() => onView("week")}>
                    <Text className="navbar-text" variant="REGULAR">
                        Неделя
                    </Text>
                </Button>
                <Button sx={saveBtn} onClick={() => onView("month")}>
                    <Text className="navbar-text" variant="REGULAR">
                        Месяц
                    </Text>
                </Button>
            </div>
        </div>
    );
};
