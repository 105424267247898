import React, { useEffect,useState } from 'react';
import { ProfileIcon } from "assets/icons";
import { useTranslation } from "react-i18next";

  import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    getPersonalInfoEmployeeById,
  } from "../../../store";

import styles from "./styles.module.scss";

export const UserInfo = (): JSX.Element =>{
    const dispatch = useAppDispatch();
    const userId = useAppSelector((state) => state.auth.userId);
    const { t } = useTranslation();
    const { userName, role } = useAppSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(true);
    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );


    useEffect(() => {
        (async () => {
            if (userId) {
                setIsLoading(true);
                await Promise.all([
                                  dispatch(getPersonalInfoEmployeeById(userId)),
                               ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, userId, t]);


    return (
        <div className={styles.userInfo}>
        {personalInfoEmployeeDetails?.avatar ? (
                <img
                    style={{ width: "auto", height: "50px", borderRadius: "5%" }}
                    src={
                        process.env.REACT_APP_STATIC_FILES_URL &&
                        process.env.REACT_APP_STATIC_FILES_URL + personalInfoEmployeeDetails.avatar
                    }
                    alt="avatar"
                />
            ) : (
                <ProfileIcon style={{ width: "50px", height: "50px" }} />
            )}

            <h5 className={styles.fullName}>{userName}</h5>
            <p className={styles.userRole}>{t(`roles.${role}`)}</p>

        </div>
    );
};

export default UserInfo;
