import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import defaultLogo from "../../../../assets/logos/default-logo.png";
import Guard from "../../../../features/Guard";
import { useAppSelector } from "../../../../hooks";
import {
    IOrganization,
    IOrganizationContacts,
} from "../../../../models/organizations";
import {
    EMPLOYEES_PATH,
    ORGANIZATIONS_PATH,
} from "../../../../router/constants";
import { cancelBtn, saveBtn } from "../../../../styles/MUIStyles";
import ButtonWithProgress from "../../../../ui/ButtonWithProgress";
import { Text } from "../../../../ui/Text/Text";

import styles from "../../Organizations.module.scss";

interface Props {
    id?: number;
    code: string;
    shortName: string;
    fullName: string;
    description: string;
    registrationAddress: string;
    actualAddress: string;
    phoneNumber: string;
    emailAddress: string;
    url: string;
    type: number | string;
    countryId: number | string;
    // managerIds: Array<string>,
    managers: [{ id: string; fullName: string }];
    logo: string;
    contacts?: Array<IOrganizationContacts>;
    addContacts?: Array<IOrganizationContacts>;
    editContacts?: Array<IOrganizationContacts>;
    deleteContactIds?: Array<number>;
    isActive?: boolean;
    allowedPermissions: any[];
    organization: IOrganization | null;
    //  organizationTypes: string[];
}

const OrganizationGeneralInfo: FC<Props> = (props): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const typeKeys = ["Client", "Vendor", "Department", "Headquarter"];

    const organizationTypeKey =
        typeof props.type === "number" &&
        props.type >= 0 &&
        props.type < typeKeys.length
            ? typeKeys[props.type]
            : undefined;

    const organizationTypeLabel = organizationTypeKey
        ? t(`organizationTypes.${organizationTypeKey}`)
        : "";

    return (
        <>
            <div style={{
                padding: 12
            }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center'
                    }}
                >
                    <div>
                        <div style={{
                            marginBottom: 8
                        }}>
                            <span
                                className={
                                    props.isActive
                                        ? styles.userStatusActive
                                        : styles.userStatusInactive
                                }
                            >
                                {props.isActive === true
                                    ? t("statuses.Active")
                                    : t("statuses.Inactive")}
                            </span>
                            <div className={styles.userPosition}>
                                <h2 style={{ color: "#c4c7c9" }}>
                                    {" "}
                                    {props.fullName}
                                </h2>
                            </div>

                            <div className={styles.userPosition}>
                                {" "}
                                {props.description}{" "}
                            </div>
                        </div>
                        <img
                            className={styles.avatar}
                            src={
                                props.logo
                                    ? process.env.REACT_APP_STATIC_FILES_URL &&
                                    process.env.REACT_APP_STATIC_FILES_URL +
                                    props.logo
                                    : defaultLogo
                            }
                            alt=""
                            style={{
                                width: "170px",
                                borderRadius: "8px",
                            }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Guard
                            allowedPermissions={allowedPermissions}
                            permissionName="Edit"
                        >
                            <ButtonWithProgress
                                loading={false}
                                disabled={false}
                                variant="contained"
                                sx={saveBtn}
                                onClick={() =>
                                    navigate(
                                        `/${ORGANIZATIONS_PATH}/edit/${props?.id}`,
                                    )
                                }
                            >
                                {t("buttons.Edit")}
                            </ButtonWithProgress>
                        </Guard>
                        <Button
                            variant="text"
                            onClick={() => navigate(-1)}
                            sx={cancelBtn}
                        >
                            {t("buttons.Close")}
                        </Button>
                    </div>
                </div>

                <div className={styles.employeeDetailsBottom}>
                    <div>
                        <div>
                            <h3
                                className={styles.userFullName}
                                style={{ marginBottom: "10px" }}
                            >
                                {props.code}
                            </h3>
                            <h4 style={{ marginBottom: "10px" }}>
                                {organizationTypeLabel}
                            </h4>

                            {props.url
                                ? props.url.split(",").map((item) => (
                                    <Link
                                        key={item}
                                        className="hyperLink"
                                        to={item.trim()}
                                        target="_blank"
                                        style={{
                                            display: "block",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        {item.length > 40
                                            ? item.slice(0, 39) + "..."
                                            : item}
                                    </Link>
                                ))
                                : null}
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <span>{props.registrationAddress}</span>
                        </div>

                        <span>{props.actualAddress}</span>

                        <div style={{ marginBottom: "10px" }}>
                            {props?.emailAddress?.split(",").map((item) => (
                                <a
                                    key={item}
                                    className="hyperLink"
                                    href={`mailto:${item}`}
                                >
                                    {item}
                                </a>
                            ))}
                        </div>
                    </div>
                    <hr style={{ marginBottom: "10px" }}/>
                    {props?.organization?.managers?.map((el) => (
                        <p key={el.id} style={{ marginBottom: "10px" }}>
                            {t("data.manager")}:{" "}
                            <Link
                                className="hyperLink"
                                to={`/${EMPLOYEES_PATH}/${el.id}`}
                            >
                                {el.fullName}
                            </Link>
                        </p>
                    ))}
                </div>
                <Text style={{
                    marginBottom: 8
                }} variant="MEDIUM" size={18}>{t("data.contacts")}:</Text>
                {props?.organization?.contacts?.map((el) => (
                    <p key={el.id} style={{ marginBottom: "10px" }}>
                        <> {el.name}</>
                        &nbsp;
                        <a className="hyperLink" href={`mailto:${el.emailAddress}}`}>{el.emailAddress}</a>
                        <div> {el.phoneNumber}</div>
                    </p>
                ))}
                <hr style={{ marginBottom: "10px" }}/>
            </div>
        </>
    );
};

export default OrganizationGeneralInfo;
