import React from "react";

import { Button } from "@mui/material";

import { cancelBtn } from "../../styles/MUIStyles";

import { formatDate } from "./utils";

import "./LeaveOrder.css";

type Props = {
  employeeName?: string;
  startDate: Date;
  finishDate: Date;
  position?: string;
};

export const LeaveOrder = (props: Props) => {
  const {
    employeeName,
    startDate,
    finishDate,
    position = '',
  } = props;
  const handlePrint = () => {
    window.print();
  };
  const dateStart = new Date(startDate);
  const dateEnd = new Date(finishDate);

  const diffTime = dateEnd.getTime() - dateStart.getTime();
  const diffDays = diffTime / (1000 * 60 * 60 * 24) + 1;

  return (
    <div className="pageStyle">
      <div className="headerStyle">
        <div>ОсОО &#34;Канда ЦА&#34;</div>
      </div>
      <div className="titleStyle">
        <div>ПРИКАЗ</div>
        <span>о предоставлении отпуска сотруднику</span>
      </div>
      <div>
        <div className="listStyle">
          <span className="listItemStyle">
            Предоставить {employeeName}, {position.toLowerCase()}, очередной оплачиваемый отпуск
            продолжительностью {diffDays} календарных дней с {formatDate(new Date(startDate))} по {formatDate(new Date(finishDate))}.
          </span>
        </div>
      </div>
      <div className="signatureContainerStyle">
        <div className="signatureBlockStyle">
          <div>Генеральный директор</div>
          <div>Беляев А. А.</div>
        </div>
        <div className="signatureEmployeeBlockStyle">
          <div>С приказом ознакомлен</div>
          <span>{`${employeeName?.split(' ')[0]} ${employeeName?.split(' ')[1][0]}.`}</span>
        </div>
      </div>
      <div className="cellStyle">
        «&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;» __________ 20&nbsp;&nbsp;&nbsp;&nbsp;г.
      </div>
      <div style={{ textAlign: "end", marginTop: "20px" }} className="no-print">
        <Button
          variant="text"
          onClick={handlePrint}
          sx={cancelBtn}
        >
          Печать
        </Button>
      </div>
    </div>
  );
};
