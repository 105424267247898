import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import DeleteIcon from "../../assets/icons/delete.svg";
import hoverEditIcon from "../../assets/icons/hoverEditIcon.svg";
import SkillIcon from "../../assets/icons/skill.svg";
import ConfirmationModal from "../../features/ConfirmationModal";
import SkillsModal from "../../features/Forms/SkillsModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    IDetailedEmployee,
    IEmployeeSkill,
    IPutEmployeeSkills,
} from "../../models/employees";
import {
    editEmployeeSkill,
    getAllowedPermissionsByGroupId,
    getEmployeeById,
    getEmployeesLookup,
    getEmployeesLookUpThunk,
    getLookupByName,
    getSkills,
} from "../../store";
import { saveBtn } from "../../styles/MUIStyles";
import { sendNotification } from "../../ui/Toast";

interface UpdateSkillProps {
    id: string;
    onCancel: () => void;
    onSkillsUpdated: (newSkills: IEmployeeSkill[]) => void;
}

export interface UpdateSkillRef {
    onSubmit: () => void;
}

export const UpdateSkill = forwardRef<UpdateSkillRef, UpdateSkillProps>(
    ({ id, onCancel, onSkillsUpdated }: UpdateSkillProps, ref) => {
        const { handleSubmit, setValue, getValues, reset } =
            useForm<IPutEmployeeSkills>();

        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        const [activeEmployee, setActiveEmployee] =
            useState<IDetailedEmployee | null>(null);
        const [activeSkill, setActiveSkill] = useState<IEmployeeSkill | null>(
            null,
        );
        const [skillModalOpen, setSkillModalOpen] = useState<{
            isOpen: boolean;
            actionName: string;
        }>({
            isOpen: false,
            actionName: "",
        });
        const [isConfirmationModalOpen, setConfirmationModalOpen] =
            useState(false);

        const [isOpenDeleteModalSkill, setIsOpenDeleteModalSkill] =
            useState<boolean>(false);
        const [skillId, setSkillId] = useState<number | null>();

        const allowedGroups = useAppSelector(
            (state) => state.auth.allowedGroups,
        );
        const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
        const skillList = useAppSelector((state) => state.catalogs.skills);
        const skillLevels = useAppSelector(
            (state) => state.lookups.skillLevels,
        );

        const getEmpl = async () => {
            const employee = await dispatch(getEmployeeById(id!));
            setActiveEmployee(employee.payload);
            setFormData(employee.payload);
            setFormData(employee.payload);
        };

        useEffect(() => {
            if (id) {
                getEmpl();
            }
        }, [id, t]);

        const closeModalConfirm = () => {
            setConfirmationModalOpen(false);
            reset({}, { keepValues: true });
        };

        const onSubmit: SubmitHandler<any> = async (
            formData: IPutEmployeeSkills,
        ) => {
            const body = {
                employeeId: id!,
                skills: formData.skills.length
                    ? formData.skills.map(function (el) {
                          // todo ID of skills
                          return {
                              skillId: el.skillId,
                              skillLevelId: el.skillLevelId,
                              isBasicSkill: el.isBasicSkill,
                          };
                      })
                    : [],
            };

            if (activeEmployee) {
                const response = await dispatch(editEmployeeSkill({ ...body }));
                if (response.meta.requestStatus === "fulfilled") {
                    sendNotification(
                        t("notifications.successfullySaved"),
                        "success",
                    );
                    const updatedSkills: IEmployeeSkill[] = [
                        ...formData.skills,
                    ]; // Пример обновленных навыков
                    onSkillsUpdated(updatedSkills); // Обновляем навыки в родительском компоненте
                    onCancel();
                }
                await getEmpl();
            }
        };

        useImperativeHandle(ref, () => ({
            onSubmit: handleSubmit(onSubmit), // Передаем handleSubmit родительскому компоненту
        }));

        const clearSkillForm = () => {
            setSkillModalOpen({ isOpen: false, actionName: "" });
            setActiveSkill(null);
        };

        const addSkill = () => {
            setSkillModalOpen({ isOpen: true, actionName: "Create" });
        };

        const editSkill = (skill: IEmployeeSkill) => {
            console.log(skill);

            setActiveSkill(skill);
            setSkillModalOpen({ isOpen: true, actionName: "Edit" });
        };

        const deleteSkill = async () => {
            const newSkills = getValues("skills");
            setValue(
                "skills",
                newSkills.filter((el) => el.id !== skillId),
            );
            setIsOpenDeleteModalSkill(false);
        };

        // todo функция заполнения формы значениями
        const setFormData = (fields: IDetailedEmployee | null) => {
            setValue(
                "skills",
                fields?.skills?.length ? [...fields?.skills] : [],
            );
        };

        useEffect(() => {
            dispatch(getSkills("skills"));
            dispatch(getLookupByName("skillLevels"));
            dispatch(getEmployeesLookup());
            dispatch(getEmployeesLookUpThunk());
        }, [dispatch, t, allowedMenus]);

        useEffect(() => {
            const group = allowedGroups.find(
                (menu) => menu.name === `api/employees`,
            );
            group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
        }, [dispatch, allowedGroups]);

        return (
            <div style={{ padding: "10px 30px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ marginTop: "15px" }}>
                        {getValues("skills")?.map((skill) => (
                            <div
                                key={skill.id}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    maxWidth: "500px",
                                    marginBottom: "20px",
                                }}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <div>
                                        <img
                                            src={SkillIcon}
                                            alt=""
                                            style={{ marginTop: "-6px" }}
                                        />
                                    </div>
                                    <div>
                                        <p>
                                            {
                                                skillList.find(
                                                    (el) =>
                                                        el.id === skill.skillId,
                                                )?.name
                                            }
                                        </p>
                                        <p>
                                            {
                                                skillLevels.find(
                                                    (el) =>
                                                        el.id ===
                                                        skill.skillLevelId,
                                                )?.name
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <p>
                                        {skill.isBasicSkill
                                            ? t("data.mainSkill")
                                            : ""}
                                    </p>
                                    <img
                                        src={DeleteIcon}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setIsOpenDeleteModalSkill(true);
                                            setSkillId(skill.id);
                                        }}
                                    />
                                    <img
                                        src={hoverEditIcon}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        onClick={() => editSkill(skill)}
                                    />
                                </div>
                            </div>
                        ))}
                        <Button
                            variant="contained"
                            sx={saveBtn}
                            onClick={() => addSkill()}
                        >
                            {t("buttons.AddSkill")}
                        </Button>
                    </div>
                </form>

                <ConfirmationModal
                    isModalOpen={isOpenDeleteModalSkill}
                    setIsModalOpen={setIsOpenDeleteModalSkill}
                    actionName={"DeleteSkill"}
                    instanceName={"employees"}
                    handleAction={deleteSkill}
                />

                {skillModalOpen.isOpen && skillModalOpen.actionName ? (
                    <SkillsModal
                        isModalOpen={skillModalOpen.isOpen}
                        clearForm={clearSkillForm}
                        actionName={skillModalOpen.actionName}
                        skill={activeSkill}
                        activeEntityId={activeSkill?.id ? activeSkill.id : null}
                        getMainFormValues={getValues}
                        setMainFormValue={setValue}
                    />
                ) : null}
                <ConfirmationModal
                    isModalOpen={isConfirmationModalOpen}
                    setIsModalOpen={setConfirmationModalOpen}
                    actionName={"Close"}
                    instanceName=""
                    message={"closeFormWithoutSaving"}
                    handleAction={closeModalConfirm}
                />
            </div>
        );
    },
);
export default UpdateSkill;
