import React, { FC, Fragment, useEffect, useState } from "react";
import cn from "classnames";
import axiosApi from "core/request";
import { IPersonalProject } from "models/employees";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";

import { getProjectStatusText } from "../../../helpers/getProjectStatusText";
import { useAppSelector } from "../../../hooks";
import { cancelBtn } from "../../../styles/MUIStyles";
import { Text } from "../../../ui/Text/Text";
import Guard from "../../Guard";

import styles from "./styles.module.scss";

interface Props {
    isPersonal: boolean;
    showCloseButton?: boolean;
    setIsDetailInfo?: (isDetailInfo: boolean) => void;
    projects: IPersonalProject[] | null;
}

const EmployeeProjects: FC<Props> = (props): JSX.Element => {
    const { setIsDetailInfo, projects, isPersonal } = props;
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    const isLoading = useAppSelector((state) => state.employees.isLoading);
    const { t, i18n } = useTranslation();
    const [tab, setTab] = useState(0);
    const handleActiveTab = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const handleChangeLanguage = () => {
        setIsDetailInfo?.(false);
    };

    useEffect(() => {
        if (!isLoading) {
            i18n.on("languageChanged", handleChangeLanguage);

            return () => {
                i18n.off("languageChanged", handleChangeLanguage);
            };
        }
    }, [i18n, isLoading]);

    const downloadDocument = async (docData: any) => {
        try {
            const apiUrl = isPersonal
                ? `/personal-cabinet/myprojects/download?fileId=${docData?.id}`
                : "/projects/attachmentdocument/download?fileId=" + docData?.id;

            const response = await axiosApi.get(apiUrl, {
                responseType: "blob",
            });

            const contentDisposition = response.headers["content-disposition"];
            let fileNameWithExtension;

            // Если есть поле filename*=UTF-8
            if (contentDisposition.includes("filename*=UTF-8''")) {
                fileNameWithExtension = decodeURIComponent(
                    contentDisposition
                        .split("filename*=UTF-8''")[1]
                        .split(";")[0],
                );
            } else {
                fileNameWithExtension = contentDisposition
                    .split("filename=")[1]
                    .split(";")[0]
                    .replace(/"/g, "");
            }
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", decodeURI(fileNameWithExtension));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    };

    if (!projects?.length) {
        return <div></div>;
    }

    return (
        <div>
            <div className={styles.employeeFullNameWrapper}>
                <h2 className={styles.employeeFullName}></h2>
            </div>
            <div className={styles.detainInfo}>
                <div>
                    <Tabs
                        value={tab}
                        onChange={handleActiveTab}
                        className={styles.tabs}
                    >
                        <Tab
                            label={t("catalogActions.generalInfo")}
                            value={0}
                        />
                        <Tab label={t("tabs.documents")} value={1} />
                    </Tabs>
                    {tab === 0 &&
                        projects.map((project) => (
                            <Fragment key={project.code}>
                                <div className={styles.employeeProjects}>
                                    <div>
                                        <Text
                                            variant="MEDIUM"
                                            className={cn(
                                                styles.item,
                                                project.isActive
                                                    ? styles.isActive
                                                    : styles.notActive,
                                            )}
                                        >
                                            {project.isActive
                                                ? t("statuses.Active")
                                                : t("statuses.Inactive")}
                                        </Text>
                                        <Text
                                            className={
                                                project.projectStatus === 1 ||
                                                project.projectStatus === 2
                                                    ? styles.isActive
                                                    : styles.notActive
                                            }
                                            variant="MEDIUM"
                                        >
                                            {getProjectStatusText(
                                                project.projectStatus,
                                            )}
                                        </Text>
                                        <Text
                                            className={styles.item}
                                            variant="MEDIUM"
                                        >
                                            FTE {`${project.projectFTE}`}
                                        </Text>
                                        <Text
                                            className={styles.item}
                                            variant="MEDIUM"
                                        >
                                            {project.code}
                                        </Text>
                                        <Text
                                            className={cn(
                                                styles.fullName,
                                                styles.item,
                                            )}
                                            variant="MEDIUM"
                                        >
                                            {project.fullName}
                                        </Text>
                                        {project.description && (
                                            <Text
                                                className={styles.item}
                                                variant="REGULAR"
                                            >
                                                {project.description}
                                            </Text>
                                        )}
                                        <Text variant="MEDIUM">
                                            {project?.projectType === 1
                                                ? t(
                                                      "projects.projectTypeInternal",
                                                  )
                                                : t(
                                                      "projects.projectTypeExternal",
                                                  )}
                                        </Text>
                                        <Text
                                            size={18}
                                            className={styles.item}
                                            variant="REGULAR"
                                        >
                                            {project.isBillable
                                                ? t("projects.paid")
                                                : t("projects.unpaid")}
                                        </Text>
                                    </div>
                                </div>
                                <div className={styles.managers}>
                                    <Text variant="MEDIUM">
                                        {t("data.manager")}
                                    </Text>
                                    <div>
                                        {allowedMenus.some(menu => menu.name === "Employees") ? (
                                            project.managers?.map((manager) => (
                                                <Link
                                                    to={`/employees/${manager.id}`}
                                                    key={manager.fullName}
                                                >
                                                    <Text variant="MEDIUM">
                                                        {manager.fullName}
                                                    </Text>
                                                </Link>
                                            ))) : (
                                                project.managers?.map((manager) => (
                                                    <Text variant="MEDIUM" key={manager.fullName}>
                                                        {manager.fullName}
                                                    </Text>
                                                ))
                                            )
                                        }
                                    </div>
                                </div>
                                <div
                                    style={{
                                        border: "1px solid #c8cbd4",
                                        width: "150%",
                                        margin: "16px 0",
                                    }}
                                ></div>
                            </Fragment>
                        ))}
                    {tab === 1 &&
                        projects.map((project) => (
                            <div
                                key={project.code}
                                className={styles.documents}
                            >
                                <Text
                                    variant="MEDIUM"
                                    className={cn(
                                        styles.item,
                                        project.isActive
                                            ? styles.isActive
                                            : styles.notActive,
                                    )}
                                >
                                    {project.isActive
                                        ? t("statuses.Active")
                                        : t("statuses.Inactive")}
                                </Text>
                                <Text
                                    className={
                                        project.projectStatus === 1 ||
                                        project.projectStatus === 2
                                            ? styles.isActive
                                            : styles.notActive
                                    }
                                    variant="MEDIUM"
                                >
                                    {getProjectStatusText(
                                        project.projectStatus,
                                    )}
                                </Text>
                                <h4>{project.fullName}</h4>
                                <Text className={styles.item} variant="MEDIUM">
                                    {project.code}
                                </Text>
                                {project.projectDocuments.map((document) => (
                                    <div key={document.id}>
                                        <Text variant="MEDIUM">
                                            {document.name}
                                        </Text>
                                        <Text variant="REGULAR">
                                            {document.description}
                                        </Text>
                                        <Guard
                                            allowedPermissions={
                                                allowedPermissions
                                            }
                                            permissionName={
                                                isPersonal
                                                    ? "PersonalCabinetProjectDocumentDownloadFile"
                                                    : "DownloadFile"
                                            }
                                        >
                                            <Text
                                                style={{
                                                    color: "#4A8ED5",
                                                }}
                                                onClick={() =>
                                                    downloadDocument(document)
                                                }
                                                variant="REGULAR"
                                            >
                                                {t("buttons.Open")}
                                            </Text>
                                        </Guard>
                                    </div>
                                ))}
                                <div
                                    style={{
                                        border: "1px solid #c8cbd4",
                                        width: "150%",
                                        margin: "16px 0",
                                    }}
                                ></div>
                            </div>
                        ))}
                </div>
                {setIsDetailInfo && (
                    <Button
                        className={styles.buttonClose}
                        variant="text"
                        onClick={() => setIsDetailInfo(false)}
                        sx={cancelBtn}
                    >
                        {t("buttons.Close")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default EmployeeProjects;
