import { FC } from "react";
import { useAppSelector } from "hooks";
import { baseCatalogItem } from "models/catalogs";
//styles
import { ILookupItem } from "models/lookups";
import { ISalariesFilter } from "models/salaries";
//models
//components
import { useTranslation } from "react-i18next";
import { saveBtn } from "styles/MUIStyles";

import AdapterDayjs from "@date-io/date-fns";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

interface IProps {
  salariesFilter: ISalariesFilter;
  setSalariesFilter: (salariesFilter: ISalariesFilter | null) => void;
  initialStateSalariesFilter: ISalariesFilter;
}

export const TrackingFilterPanel1: FC<IProps> = ({
  salariesFilter,
  setSalariesFilter,
}: any) => {
  const { t } = useTranslation();

  const organizations = useAppSelector((state) => state.lookups.organizations);
  const skills = useAppSelector((state) => state.catalogs.skills);
  const skillLevels = useAppSelector((state) => state.catalogs.skillLevels);
  const positions = useAppSelector((state) => state.catalogs.positions);
  const costCenters = useAppSelector((state) => state.catalogs.costCenters);
  const typeOfEmployments = useAppSelector(
    (state) => state.lookups.typeOfEmployments,
  );

  const resetFilters = () => {
    setSalariesFilter({
      organizationId: null,
      positionId: null,
      skillId: null,
      skillLevelId: null,
      costCenterId: null,
      status: null,
      typeOfEmploymentId: null,
      fromStartDate: null,
      beforeStartDate: null,
    });
  };

  return (
    <div className="filter-panel">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <Button variant="contained" sx={saveBtn} onClick={resetFilters}>
            {t("buttons.Reset")}
          </Button>
        </div>
        <p style={{ marginTop: "20px" }}>
          <b>{t("employees.show")}</b>
        </p>
        <FormGroup sx={{ margin: "10px 0 10px" }}>
          <FormControlLabel
            key={"active"}
            control={
              <Checkbox
                style={{ height: "30px" }}
                checked={
                  salariesFilter.status === 1 && salariesFilter.status !== null
                }
              />
            }
            label={t("employees.active")}
            onChange={(event, checked) => {
              if (checked)
                setSalariesFilter((prevState: any) => ({
                  ...prevState,
                  status: 1,
                }));
              else
                setSalariesFilter((prevState: any) => ({
                  ...prevState,
                  status: null,
                }));
            }}
          />
          <FormControlLabel
            key={"inactive"}
            control={
              <Checkbox
                style={{ height: "30px" }}
                checked={
                  salariesFilter.status === 2 && salariesFilter.status !== null
                }
              />
            }
            label={t("employees.inactive")}
            onChange={(event, checked) => {
              if (checked)
                setSalariesFilter((prevState: any) => ({
                  ...prevState,
                  status: 2,
                }));
              else
                setSalariesFilter((prevState: any) => ({
                  ...prevState,
                  status: null,
                }));
            }}
          />
        </FormGroup>
        <hr />
        <FormControl fullWidth>
          <label htmlFor="organizationId" className="filterTopSubTitle">
            {t("data.vendor")}
          </label>
          <Autocomplete
            id="organizationId"
            autoHighlight
            options={
              organizations.slice().sort((a: ILookupItem, b: ILookupItem) => {
                const fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase();
                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              }) || []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value: ILookupItem | null) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                organizationId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              organizations.filter(
                (el) => el.id === salariesFilter.organizationId,
              )[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="positionId" className="filterTopSubTitle">
            {t("data.position")}
          </label>
          <Autocomplete
            id="positionId"
            autoHighlight
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={
              (positions &&
                positions
                  .slice()
                  .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                    const fa = a.name.toLowerCase(),
                      fb = b.name.toLowerCase();
                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  })) ||
              []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value: baseCatalogItem | null) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                positionId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              positions.filter(
                (el) => el.id === salariesFilter.positionId,
              )[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="skillId" className="filterTopSubTitle">
            {t("data.skill")}
          </label>
          <Autocomplete
            id="skillId"
            autoHighlight
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={
              (skills &&
                skills
                  .slice()
                  .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                    const fa = a.name.toLowerCase(),
                      fb = b.name.toLowerCase();
                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  })) ||
              []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value: baseCatalogItem | null) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                skillId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              skills.filter((el) => el.id === salariesFilter.skillId)[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="skillId" className="filterTopSubTitle">
            {t("data.type")}
          </label>
          <Autocomplete
            id="skillId"
            autoHighlight
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={typeOfEmployments}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                typeOfEmploymentId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              typeOfEmployments.filter(
                (el) => el.id === salariesFilter.typeOfEmploymentId,
              )[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="skillLevelId" className="filterTopSubTitle">
            {t("data.level")}
          </label>
          <Autocomplete
            id="skillLevelId"
            autoHighlight
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={
              (skillLevels &&
                skillLevels
                  .slice()
                  .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                    const fa = a.name.toLowerCase(),
                      fb = b.name.toLowerCase();
                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  })) ||
              []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value: baseCatalogItem | null) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                skillLevelId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              skillLevels.filter(
                (el) => el.id === salariesFilter.skillLevelId,
              )[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <label htmlFor="costCenterId" className="filterTopSubTitle">
            {t("data.costCenter")}
          </label>
          <Autocomplete
            id="costCenterId"
            autoHighlight
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={
              (costCenters &&
                costCenters
                  .slice()
                  .sort((a: baseCatalogItem, b: baseCatalogItem) => {
                    const fa = a.name.toLowerCase(),
                      fb = b.name.toLowerCase();
                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  })) ||
              []
            }
            getOptionLabel={(option) => option.name}
            onChange={(event, value: baseCatalogItem | null) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                costCenterId: value?.id,
              }))
            }
            sx={{ width: 200 }}
            value={
              costCenters.filter(
                (el) => el.id === salariesFilter.costCenterId,
              )[0] || null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder={t("organizations.all") || ""}
              />
            )}
          />
        </FormControl>
        <Box>
          <h4 className="filterTopSubTitle">{t("salaries.FromDate")}</h4>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: "20px",
              "& > label": {
                fontWeight: 600,
              },
            }}
          >
            <label>{t("projects.from")}</label>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={salariesFilter.fromStartDate}
              onChange={(newValue) => {
                setSalariesFilter((prevState: ISalariesFilter) => ({
                  ...prevState,
                  fromStartDate: newValue,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.dayMonthYear") as string,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginLeft: "20px",
            "& > label": {
              fontWeight: 600,
            },
          }}
        >
          <label>{t("projects.until")}</label>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            value={salariesFilter.beforeStartDate}
            onChange={(newValue) =>
              setSalariesFilter((prevState: any) => ({
                ...prevState,
                beforeStartDate: newValue,
              }))
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0",
                    border: "1px solid #8A8886",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #8A8886",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#80bdff",
                      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                    },
                  },
                  padding: "5px 0",
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: t("projects.dayMonthYear") as string,
                  autoComplete: "off",
                }}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
    </div>
  );
};
