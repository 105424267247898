import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CloseIcon from "@mui/icons-material/Close";
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import { DeleteFile } from '../../../features/DocumentsList/components/DeleteFile';
import { generateRandomId } from "../../../helpers/generateRandomId";
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { IDocument } from "../../../models/documents";
import { addPersonalDocumentLocal } from '../../../store';
import { saveBtn } from '../../../styles/MUIStyles';
import ButtonWithProgress from '../../../ui/ButtonWithProgress';

import styles from '../../../ui/SelectFile/styles.module.scss';
import stylesInput from '../../Salaries/SalariesActions/SalariesActions.module.scss';
import localStyles from './styles.module.scss';

const DocumentForm = ({
                          control,
                          errors,
                          index,
                          fileInput,
                          handleUpload,
                          handleSubmit,
                          save,
                          fileName,
                          name: initialName,
                          description: initialDescription,
                          originalFileName,
                          documentId,
                          setEdited,
                          setIsDirty
                      }: any) => {
    const [file, setFile] = useState<File | null>(null);
    const [isOpenDeleteFile, setIsOpenDeleteFile] = useState(false);
    const [name, setName] = useState(initialName || '');
    const [description, setDescription] = useState(initialDescription || '');
    const { t } = useTranslation();

    const handleUploadEditDocument = (event: any) => {
        setFile(event.target.files[0]);
    };

    const {
        control: controlEditDocument,
        formState: { errors: errorsEditDocument, isDirty: isDirtyEditDocument },
        reset,
    } = useForm({
        defaultValues: {
            description: initialDescription || '',
            name: initialName || '',
        }
    });

    useEffect(() => {
        if (isDirtyEditDocument) {
            setIsDirty(true);
        }
    }, [isDirtyEditDocument]);

    useEffect(() => {
        if (index === 0) {
            reset({
                name: initialName || '',
                description: initialDescription || ''
            }, {
                keepDefaultValues: true,
                keepErrors: true
            });
        }
    }, [index, initialName, initialDescription, reset]);

    const addDocument = ({ newName, newDescription, file }: {
        newName?: string,
        newDescription?: string,
        file?: File | null
    }) => {
        setEdited((prevState: IDocument[]) => {
            const documentToUpdate = prevState.find((item: IDocument) => item.id === documentId);
            if (typeof documentToUpdate === 'undefined') {
                return [...prevState, {
                    id: documentId,
                    name: newName || name,
                    description: newDescription || description,
                    attachment: file
                }];
            }

            const updatedDocument = {
                ...documentToUpdate,
                name: newName || name,
                description: newDescription || description,
                attachment: file
            };
            return typeof updatedDocument['id'] === 'undefined' ? prevState : [...prevState.filter((item: IDocument) => item.id !== documentId), updatedDocument];
        });
    };

    return (
        <div style={{
            position: 'relative',
            borderBottom: '1px solid #dedbdb',
            paddingBottom: 20,
            marginBottom: 20
        }}>
            <div className={styles.controllerWrapper}>
                <label style={{ display: 'block', textAlign: 'start' }} htmlFor={`documents[${index}].name`}>
                    {t('data.name')}
                </label>
                <Controller
                    name={`documents[${index}].name`}
                    control={index === 0 ? control : controlEditDocument}
                    rules={{ required: index === 0 ? true : (name.length <= 0) }}
                    render={({ field }) => (
                        <input
                            {...field}
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                field.onChange(e);
                                addDocument({
                                    newName: e.target.value
                                });
                            }}
                            placeholder={t('projects.enterDocumentName') || ''}
                            type="text"
                            className={cn(stylesInput.input, styles.label)}
                        />
                    )}
                />
            </div>
            {(index === 0 ? errors : errorsEditDocument).documents?.[index]?.name && (
                <div style={{ color: 'red', textAlign: 'start' }}>{t('validation.requiredField')}</div>
            )}
            <div className={styles.controllerWrapper}>
                <label style={{ display: 'block', textAlign: 'start' }} htmlFor={`documents[${index}].description`}>
                    {t('data.description')}
                </label>
                <Controller
                    name={`documents[${index}].description`}
                    control={index === 0 ? control : controlEditDocument}
                    render={({ field }) => (
                        <input
                            {...field}
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                field.onChange(e);
                                addDocument({
                                    newDescription: e.target.value
                                });
                            }}
                            placeholder={t('projects.enterDocumentDescription') || ''}
                            type="text"
                            className={cn(stylesInput.input, styles.label)}
                        />
                    )}
                />
            </div>
            {(index === 0 ? errors : errorsEditDocument).documents?.[index]?.description && (
                <div style={{ color: 'red', textAlign: 'start' }}>{t('validation.requiredField')}</div>
            )}
            <>
                <div>
                    <input
                        style={{ display: 'none' }}
                        id={`customFileInput${index}`}
                        type="file"
                        ref={fileInput}
                        onChange={(event) => {
                            index === 0 ? handleUpload(event) : handleUploadEditDocument(event);
                            addDocument({
                                file: event.target.files?.[0]
                            });
                        }}
                    />
                    <div className={styles.controllerWrapper}>
                        <label
                            htmlFor={`customFileInput${index}`}
                            className={localStyles.fileInputLabel}>
                            {t('projects.chooseFile')}
                        </label>
                        <label
                            className={localStyles.fileInputDescription}
                            htmlFor={`customFileInput${index}`}>
                            {file?.name || (originalFileName || (index === 0 ? fileName : file?.name)) || t('projects.chooseFile')}
                        </label>
                        {index !== 0 && (
                            <CloseIcon
                                className={localStyles.closeIcon}
                                color="error"
                                fontSize="large"
                                onClick={() => setIsOpenDeleteFile(true)}
                            />
                        )}
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={isOpenDeleteFile}
                            onClose={() => setIsOpenDeleteFile(false)}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <Box>
                        <DeleteFile
                            setEdited={setEdited}
                            id={documentId}
                            isPersonal
                            setIsOpenDeleteFile={setIsOpenDeleteFile}
                            originalFileName={`${name} ${originalFileName}`}
                        />
                            </Box>
                        </Modal>
                    </div>
                </div>
                {index === 0 && (
                    <div style={{
                        marginTop: 4,
                        paddingBottom: 16,
                    }} className={localStyles.saveButtonWrapper}>
                        <ButtonWithProgress
                            onClick={handleSubmit(save)}
                            loading={false}
                            disabled={false}
                            type="submit"
                            variant="contained"
                            sx={saveBtn}
                        >
                            {t('buttons.Create')}
                        </ButtonWithProgress>
                    </div>
                )}
            </>
        </div>
    );
};

interface AddDocumentProps {
    setEdited: (edited: IDocument[]) => void;
    setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddDocument: FC<AddDocumentProps> = ({ setEdited, setIsDirty }) => {
    const dispatch = useAppDispatch();
    const fileInput = useRef(null);
    const [file, setFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);
    const personalDocuments = useAppSelector((state) => state.employees.personalDocuments);

    const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
        defaultValues: {
            documents: [{ name: '', description: '' }, ...personalDocuments?.myDocuments || []]
        }
    });

    useEffect(() => {
        if (isDirty) {
            setIsDirty(true);
        }
    }, [isDirty]);

    const { fields } = useFieldArray({
        control,
        name: 'documents'
    });

    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
        setFileError(null);
    };

    useEffect(() => {
        reset({
            documents: [{ name: '', description: '' }, ...personalDocuments?.myDocuments || []]
        });
    }, [personalDocuments, reset]);

    const save = async (data: any) => {
        const newId = `new_id_${generateRandomId()}`;

        // @ts-ignore
        setEdited((prevState: IDocument[]) => [
            ...prevState,
            {
                id: newId,
                name: data.documents[0].name,
                description: data.documents[0].description,
                attachment: file
            }
        ]);

        try {
            dispatch(addPersonalDocumentLocal({
                id: newId,
                name: data.documents[0].name,
                description: data.documents[0].description,
                attachment: file
            }));

            setFile(null);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={localStyles.container}>
            {fields.map((field, index) => (
                <DocumentForm
                    setEdited={setEdited}
                    {...field}
                    fileName={file?.name}
                    handleSubmit={handleSubmit}
                    save={save}
                    fileInput={fileInput}
                    handleUpload={handleUpload}
                    key={field.id}
                    documentId={personalDocuments?.myDocuments?.[index - 1]?.id}
                    control={control}
                    errors={errors}
                    fileError={fileError}
                    index={index}
                    setIsDirty={setIsDirty}
                />
            ))}
        </div>
    );
};
