import React, { FC, useEffect, useMemo, useState } from "react";
import {
    BankCardIcon,
    CalendarIcon,
    CatalogsIcon,
    ContractsIcon,
    DisturbIcon,
    DocumentIcon,
    EmployeesIcon,
    ProjectsIcon,
    ResetIcon,
    SaveAs,
    SystemSettingsIcon,
    TrackingIcon,
    UsersIcon } from "assets/icons";
import LeaveModal from "features/LeaveModal/LeaveModal";
import { useAppDispatch, useAppSelector } from "hooks";
import { IAllPermissions } from "models/user";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { returnRoleToInitialPermissions } from "store";
import {
    getAllPermissions,
    getListOfPermissionsByRoleName,
    updateRolePermissions,
} from "store/thunks/rolesThunk";
import { rolesToolbarButtons } from "styles/MUIStyles";
import Loader from "ui/Loader";
import { sendNotification } from "ui/Toast";

import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import RolePermissionsList from "./RolePermissionsList";

import styles from "./Roles.module.scss";

interface Icon {
    [key: string]: React.ReactElement;
}

const iconMap: Icon = {
    Catalogs: <CatalogsIcon />,
    Organizations: <SystemSettingsIcon />,
    Administration: <UsersIcon />,
    Projects: <ProjectsIcon />,
    Salaries: <BankCardIcon />,
    Employees: <EmployeesIcon />,
    DaysOff: <CalendarIcon />,
    Tracking: <TrackingIcon />,
    Contracts: <ContractsIcon />,
    CommonDocuments: <DocumentIcon />,
    PersonalCabinet: <UsersIcon />,
};

const RoleSettings: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const allPermissions = useAppSelector(
        (state) => state.users.allPermissions,
    );
    const rolePermissions = [
        ...useAppSelector((state) => state.users.rolePermissions),
    ].sort();
    const initialRolePermissions = [
        ...useAppSelector((state) => state.users.initialRolePermissions),
    ].sort();
    const isUpdatingPermissionsLoading = useAppSelector(
        (state) => state.users.isUpdatingPermissionsLoading,
    );

    const isInitialRolePermissionsChanged = useMemo(
        () =>
            JSON.stringify(rolePermissions) ===
            JSON.stringify(initialRolePermissions),
        [rolePermissions, initialRolePermissions],
    );

    const [activeMenuItem, setActiveMenuItem] =
        useState<null | IAllPermissions>(null);
    const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdateRolePermissions = async () => {
        if (params && params.roleName) {
            const updatedRolePermissionsResponse = await dispatch(
                updateRolePermissions({
                    roleName: params.roleName,
                    permissionIds: rolePermissions,
                }),
            );

            if (
                updatedRolePermissionsResponse.meta.requestStatus ===
                "fulfilled"
            ) {
                await dispatch(getListOfPermissionsByRoleName(params.roleName));
                sendNotification(
                    t("notifications.successfullySaved"),
                    "success",
                );
            }
        }
    };

    const handleReturnRoleToInitialPermissions = () => {
        dispatch(returnRoleToInitialPermissions());
    };

    const goBack = () => {
        if (rolePermissions.length !== initialRolePermissions.length) {
            setIsLeaveModalOpen(true);
            return;
        }
        navigate(-1);
    };

    const handleLeaveModalClose = () => setIsLeaveModalOpen(false);

    useEffect(() => {
        (async () => {
            if (params && params.roleName) {
                setIsLoading(true);
                if (params.roleName === "Administrator") {
                    navigate(-1);
                    return;
                }
                await Promise.all([
                    dispatch(getAllPermissions()),
                    dispatch(getListOfPermissionsByRoleName(params.roleName)),
                ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, params]);

    useEffect(() => {
        if (allPermissions.length) {
            setActiveMenuItem(allPermissions[0]);
        }
    }, [allPermissions]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <section>
            <Backdrop
                sx={{
                    backgroundColor: "rgba(0, 0, 0, .1)",
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isUpdatingPermissionsLoading}
            >
                <CircularProgress sx={{ color: "#6C6463" }} />
            </Backdrop>
            <div className={styles.navHeader}>
                <h3>{t("roles.rolesTitle")}</h3>
            </div>
            <div className={styles.toolbar}>
                <Button
                    variant="text"
                    startIcon={<ResetIcon />}
                    onClick={handleReturnRoleToInitialPermissions}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Reset")}
                </Button>
                <Button
                    variant="text"
                    startIcon={<DisturbIcon />}
                    onClick={goBack}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Close")}
                </Button>
                <Button
                    variant="text"
                    startIcon={<SaveAs />}
                    disabled={isInitialRolePermissionsChanged}
                    onClick={handleUpdateRolePermissions}
                    sx={rolesToolbarButtons}
                >
                    {t("buttons.Save")}
                </Button>
            </div>
            <div className={styles.roleSettingsTitle}>
                <h5>
                    {t("roles.roleEditing")}:{" "}
                    <span>{t(`roles.${params.roleName}`)}</span>
                </h5>
            </div>
            <div className={styles.roleSettings}>
                <div className={styles.roleSettingsSidebar}>
                    <ul className={styles.roleSettingsSidebarList}>
                        {allPermissions.map((permission) => (
                            <li
                                key={permission.id}
                                className={
                                    activeMenuItem?.name === permission.name
                                        ? styles.roleSettingsSidebarListItemActive
                                        : styles.roleSettingsSidebarListItem
                                }
                                onClick={() => setActiveMenuItem(permission)}
                            >
                                {iconMap[permission.name]}
                                <span>{t(`menu.${permission.name}`)}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.roleSettingsPermissions}>
                    <RolePermissionsList
                        activeMenuItem={activeMenuItem}
                        rolePermissions={rolePermissions}
                    />
                </div>
                <LeaveModal
                    handleUpdatePermissions={handleUpdateRolePermissions}
                    handleReturnToInitialPermissions={
                        handleReturnRoleToInitialPermissions
                    }
                    isLeaveModalOpen={isLeaveModalOpen}
                    handleLeaveModalClose={handleLeaveModalClose}
                />
            </div>
        </section>
    );
};

export default RoleSettings;
