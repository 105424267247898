import axios from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "../../core/request";
import { ICountryErrResponse } from "../../models/countries";

export const uploadDocument = createAsyncThunk<
  any,
  Record<string, any>,
  { rejectValue: any | string }
>("documents/uploadDocument", async (data, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);

    if (data.attachment && data.attachment[0]) {
      formData.append("attachment", data.attachment[0]);
    }

    switch (data.endpoint) {
      case "employees":
        formData.append("employeeId", data.id);
        break;
      case "organizations":
        formData.append("organizationId", data.id);
        break;
    }

    const response = await axiosApi.post(
      `/${data.endpoint}/attachmentdocument`,
      formData,
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(
          "Что-то пошло не так... Попробуйте обновить страницу",
        );
      }
    }

    if (!navigator.onLine) {
      return rejectWithValue("Проверьте подключение к интернету");
    }

    return rejectWithValue(
      "Что-то пошло не так... Попробуйте обновить страницу",
    );
  }
});

export const editDocument = createAsyncThunk<
  any,
  Record<string, any>,
  { rejectValue: any | string }
>("documents/editDocument", async (data, { rejectWithValue }) => {
  try {
    if (data.endpoint === "employees") {
      const formData = new FormData();
      formData.append(
        "listOfUpdatedEmployeeAttachmentDocuments[0].id",
        data.id,
      );
      formData.append(
        "listOfUpdatedEmployeeAttachmentDocuments[0].name",
        data.name,
      );
      formData.append(
        "listOfUpdatedEmployeeAttachmentDocuments[0].description",
        data.description,
      );
      if (data.attachment) {
        formData.append(
          "listOfUpdatedEmployeeAttachmentDocuments[0].attachment",
          data.attachment[0],
        );
      }

      const response = await axiosApi.put(
        `/${data.endpoint}/attachmentdocument`,
        formData,
      );
      return response.data;
    } else if (data.endpoint === "organizations") {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("name", data.name);
      formData.append("description", data.description);
      if (data.attachment) {
        formData.append("attachment", data.attachment[0]);
      }

      const response = await axiosApi.put(
        `/${data.endpoint}/attachmentdocument`,
        formData,
      );
      return response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(
          "Что-то пошло не так... Попробуйте обновить страницу",
        );
      }
    }

    if (!navigator.onLine) {
      return rejectWithValue("Проверьте подключение к интернету");
    }

    return rejectWithValue(
      "Что-то пошло не так... Попробуйте обновить страницу",
    );
  }
});

export const deleteDocument = createAsyncThunk<
  any,
  any,
  { rejectValue: ICountryErrResponse | string }
>("documents/deleteDocument", async (data, { rejectWithValue }) => {
  try {
    const response = await axiosApi.delete(
      `/${data.endpoint}/attachmentdocument?id=${data.id}`,
    );
    return response.data;
  } catch (err) {
    return rejectWithValue("Error");
  }
});
