import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { Breadcrumbs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// import PersonalDocumentsTab from "../../../features/PersonalDocumentsTab/PersonalDocumentsTab";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    getPersonalDocuments,
    getPersonalInfoEmployeeById,
} from "../../../store";
import Loader from "../../../ui/Loader";
// import EmployeeDocuments from '../EmployeeDocuments/EmployeeDocuments';

interface Notification {
    id: number;
    text: string;
    link: string;
    date: string;
}

export const PersonalNotifications = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const personalDocuments = useAppSelector(
        (state) => state.employees.personalDocuments,
    );
    const userId = useAppSelector((state) => state.auth.userId);
    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const [employeesActiveTab, setEmployeesActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [newNotifications, setNewNotifications] = useState<Notification[]>([
        {
            id: 1,
            text: "В Календарь добавлено новое Корпоративное мероприятие",
            link: "/projects",
            date: "2024-15-07 17:00",
        },
        {
            id: 2,
            text: "Необходимо ознакомиться с новым документом",
            link: "/projects",
            date: "2024-14-07 10:32",
        },
        {
            id: 3,
            text: "Заявка перешла в новый статус",
            link: "/projects",
            date: "2024-12-07 03:17",
        },
    ]);

    const [oldNotifications, setOldNotifications] = useState<Notification[]>([
        {
            id: 3,
            text: "Заявка перешла в новый статус",
            link: "/projects",
            date: "2024-12-07 03:17",
        },
    ]);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, notification: Notification) => {
        event.preventDefault();
        setNewNotifications((prev) => {
            const updatedNewNotifications = prev.filter(
                (n) => n.id !== notification.id,
            );
            return updatedNewNotifications;
        });

        setOldNotifications((prev) => {
            const updatedOldNotifications = [notification, ...prev];
            return updatedOldNotifications;
        });

        window.open(notification.link, '_blank');
        
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await dispatch(getPersonalDocuments());
            if (userId !== null) {
                dispatch(getPersonalInfoEmployeeById(userId));
            }
            setIsLoading(false);
        };

        fetchData();
    }, [dispatch]);

    if (!personalDocuments || isLoading) {
        return <Loader />;
    }

    return (
        <Box sx={{ width: "100%", padding: "10px 0" }}>
            <Breadcrumbs sx={{ padding: "10px 10px" }}>
                <Link
                    to="/personalcabinet"
                    // className={styles.breadScrumbsLink}
                >
                    {t("menu.personalAccount")}
                </Link>
                <Typography
                    sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                >
                    {`${personalInfoEmployeeDetails?.firstName} ${personalInfoEmployeeDetails?.lastName}`}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={employeesActiveTab} onChange={handleActiveTab}>
                    <Tab label={t("tabs.newNotifications")} value={0} />
                    <Tab label={t("tabs.oldNotifications")} value={1} />
                </Tabs>
                {employeesActiveTab === 0 &&
                    newNotifications.map((notification) => (
                        <Box key={notification.id} sx={{ margin: '10px' }}>
                            <Link
                                to={notification.link}
                                onClick={(event) => handleClick(event, notification)}>
                                <span style={{ marginRight: '20px' }}>{notification.date} </span>
                                {notification.text}
                            </Link>
                        </Box>
                    ))}
                {employeesActiveTab === 1 &&
                    oldNotifications.map((notification) => (
                        <Box key={notification.id} sx={{ margin: '10px' }}>
                            <Link to={notification.link}>
                            <span style={{ marginRight: '20px' }}>{notification.date}</span>
                            
                            {notification.text}
                                
                            </Link>
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};
