// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__e-FRX {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 16px;\n}\n\n.styles_responsibleMenuItem__JdzX- {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\n\n.styles_responsiblePost__kpJ\\+y {\n  color: #495057;\n  opacity: 0.7;\n  font-size: 12px;\n}\n\n.styles_opacity__ycSjZ {\n  opacity: 0.7;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Requests/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: 16px;\n}\n\n.responsibleMenuItem {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\n\n.responsiblePost {\n  color: #495057;\n  opacity: 0.7;\n  font-size: 12px;\n}\n\n.opacity {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__e-FRX",
	"responsibleMenuItem": "styles_responsibleMenuItem__JdzX-",
	"responsiblePost": "styles_responsiblePost__kpJ+y",
	"opacity": "styles_opacity__ycSjZ"
};
export default ___CSS_LOADER_EXPORT___;
