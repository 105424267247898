import { useState } from "react";
import moment from "moment";
import {
  Calendar,
  Event,
  EventPropGetter,
  momentLocalizer,
} from "react-big-calendar";

import { CustomToolbar } from "./CustomToolbar";

import "./styles.css";

const localizer = momentLocalizer(moment);

export interface ICalendarEvent extends Event {
  title: string;
  startDate: Date;
  endDate: Date;
  allDay?: boolean;
  description?: string;
  type: "vacation" | "meeting" | "meal" | "medical";
}

const eventStyleGetter: EventPropGetter<ICalendarEvent> = (event) => {
  let backgroundColor = "";
  switch (event.type) {
    case "vacation":
      backgroundColor = "#2561f4";
      break;
    case "meeting":
      backgroundColor = "#1E90FF";
      break;
    case "meal":
      backgroundColor = "#32CD32";
      break;
    case "medical":
      backgroundColor = "#F7007B";
      break;
  }
  return {
    className: "custom-event",
    style: {
      backgroundColor,
      color: "white",
      borderRadius: "5px",
      border: "none",
    },
  };
};

interface Props {
  data: ICalendarEvent[];
}

export const BigCalendar = ({ data }: Props) => {
  const dataNew = data.map((event) => ({
    ...event,
    start: moment.utc(event.startDate).toDate(),
    end: moment.utc(event.endDate).toDate(),
    title: "Отпуск",
  }));

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const handleEventClick = (event: Event, e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const rect = target.getBoundingClientRect();

    setPopupPosition({
      x: rect.left + window.scrollX + rect.width / 2,
      y: rect.top + window.scrollY + rect.height,
    });

    setSelectedEvent(event);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedEvent(null);
  };

  return (
    <div>
      <Calendar
        // Убираем Agenda
        views={{
          month: true,
          week: true,
          day: true,
        }}
        eventPropGetter={eventStyleGetter}
        localizer={localizer}
        events={dataNew}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        // TODO пока не используется
        // onSelectEvent={(event, e) => handleEventClick(event, e)}
        popup
        components={{
          event: EventComponent,
          toolbar: CustomToolbar,
        }}
      />
      {popupVisible && selectedEvent && (
        <div
          style={{
            zIndex: 20,
            position: "absolute",
            top: popupPosition?.y,
            left: (popupPosition?.x || 0) - 100,
            transform: "translate(-50%, 0)",
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <h3>{selectedEvent.title}</h3>
          <p>
            {selectedEvent.start && `Start: ${selectedEvent.start.toString()}`}{" "}
            <br />
            {selectedEvent.end && `End: ${selectedEvent.end.toString()}`}
          </p>
          <button onClick={closePopup}>Close</button>
        </div>
      )}
    </div>
  );
};

const EventComponent = ({ event }: { event: ICalendarEvent }) => (
  <div title={event.description}>
    {" "}
    <strong>{event.title}</strong>
    {event.description && (
      <p className="event-description">{event.description}</p>
    )}
  </div>
);
