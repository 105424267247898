import axiosApi from 'core/request';

import { createAsyncThunk } from '@reduxjs/toolkit';

const BASE_URL = '/common-documents';

export const getDocuments = createAsyncThunk('common-documents',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axiosApi.get(BASE_URL);
            return {
                data: res.data,
                status: res.status,
                statusText: res.statusText,
            };
        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const addDocument = createAsyncThunk('common-documents-add',
    async (data: {
        name: string;
        description?: string | null;
        file?: File | null;
    }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            if (data.file) {
                formData.append('file', data.file);
            }
            if (data.description) {
                formData.append('description', data.description);
            }
            formData.append('name', data.name);

            const res = await axiosApi.post(BASE_URL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(res);
            return res.data;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const editDocument = createAsyncThunk('common-documents-edit',
    async (data: {
        id: number;
        name: string;
        description: string
        file?: File | null;
    }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('id', String(data.id));
            formData.append('name', data.name);
            formData.append('description', data.description);

            if (data?.file) {
                formData.append('file', data.file);
            }

            const res = await axiosApi.put(BASE_URL, formData);

            return res.data;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);

export const deleteDocument = createAsyncThunk('common-documents-edit',
    async (data: {
        id: number;
    }, { rejectWithValue }) => {
        try {
            const res = await axiosApi.delete(`${BASE_URL}/${data.id}`);

            return res.data;

        } catch (e) {
            return rejectWithValue('Error');
        }
    }
);
