import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '@hookform/error-message';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import CatalogItemActionsComponent from '../../../features/Catalogs/CatalogItemActionsComponent';
import ConfirmationModal from '../../../features/ConfirmationModal';
import FormButtons from '../../../features/FormButtons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useSetServerError from '../../../hooks/useSetServerError';
import { ICountry, IFormInput } from '../../../models/countries';
import { clearCatalogTextFieldErrors, createCountry, editCountry } from '../../../store';
import { StyledSelect, StyledSelectError } from '../../../ui/Select';

import styles from '../CatalogsLayout.module.scss';

export const CountryForm: FC<{
    name: string;
    activeCountry: ICountry | null;
    setActiveCountry: (activeCountry: ICountry | null) => void;
    createOrUpdate: string | null;
    isDrawerOpen: boolean;
    setCreateOrUpdate: Function;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    fulfilledStatusHandler: Function;
}> = ({
          name,
          activeCountry,
          setActiveCountry,
          createOrUpdate,
          isDrawerOpen,
          setCreateOrUpdate,
          setIsDrawerOpen,
          fulfilledStatusHandler,
      }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        setError,
        formState: { errors },
        clearErrors,
        control,
    } = useForm<IFormInput>();

    const regions = useAppSelector((state) => state.lookups.regions);

    const countryErrors = useAppSelector(
        (state) => state.catalogs.countryErrors,
    );
    const isCreatingOrUpdatingLoading = useAppSelector(
        (state) => state.catalogs.isCreatingOrUpdatingLoading,
    );

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const onSubmit: SubmitHandler<IFormInput> = async (
        formData: IFormInput,
    ) => {
        if (createOrUpdate === "create") {
            const response = await dispatch(
                createCountry({
                    code: formData.code,
                    name: formData.name,
                    regionId: formData.regionId,
                    countryEndpointName: name,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullyCreated");
                setIsDrawerOpen(false);
            }
            return;
        }

        if (activeCountry && createOrUpdate === "update") {
            const response = await dispatch(
                editCountry({
                    id: activeCountry.id,
                    code: formData.code,
                    name: formData.name,
                    regionId: formData.regionId,
                    countryEndpointName: name,
                }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                fulfilledStatusHandler("notifications.successfullySaved");
                setActiveCountry(null);
                setIsDrawerOpen(false);
            }
        }
    };

    const setFormData = (fields: IFormInput | null) => {
        setValue("name", fields?.name || "");
        setValue("code", fields?.code || "");
        setValue("regionId", fields?.regionId || "");
    };

    // returns true, if form was updated
    const formChangesChecking = () => {
        if (createOrUpdate === "create") {
            return Boolean(
                getValues("name") || getValues("code") || getValues("regionId"),
            );
        } else {
            return Boolean(
                getValues("name") !== activeCountry?.name ||
                getValues("code") !== activeCountry?.code ||
                Number(getValues("regionId")) !== activeCountry?.regionId,
            );
        }
    };

    const formClose = () => {
        if (formChangesChecking()) setConfirmationModalOpen(true);
        else closeFormDrawer();
    };

    const closeModalConfirm = () => {
        setFormData(null);
        setIsDrawerOpen(false);
        setConfirmationModalOpen(false);
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsDrawerOpen(false);
    };

    useSetServerError({
        serverError: countryErrors,
        setError,
        clearErrors,
        errorNames: ["name", "code"],
    });

    useEffect(() => {
        if (isDrawerOpen) {
            setFormData(activeCountry);
        } else {
            clearErrors();
            dispatch(clearCatalogTextFieldErrors());
        }
    }, [dispatch, isDrawerOpen]);

    return (
        <>
            <CatalogItemActionsComponent
                name={name}
                isDrawerOpen={isDrawerOpen}
                createOrUpdate={createOrUpdate}
                closeFormDrawer={formClose}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.formInputWrapper}>
                        <label
                            htmlFor="countryCode"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.countryCode")}
                        </label>
                        <div>
                            <input
                                {...register("code", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 3,
                                        message: t("validation.maxLength3"),
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z]+$/,
                                        message: t("validation.onlyEngLetters"),
                                    },
                                })}
                                id="countryCode"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.countryCode") as string}
                                className={
                                    errors?.code
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="code"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <label
                            htmlFor="countryName"
                            className={styles.drawerTopSubTitle}
                        >
                            {t("data.name")}
                        </label>
                        <div>
                            <input
                                {...register("name", {
                                    required: t(
                                        "validation.requiredField",
                                    ) as string,
                                    maxLength: {
                                        value: 30,
                                        message: t("validation.maxLength30"),
                                    },
                                })}
                                id="countryName"
                                type="text"
                                autoComplete="off"
                                placeholder={t("data.name") as string}
                                className={
                                    errors?.name
                                        ? styles.catalogsDrawerInputError
                                        : styles.catalogsDrawerInput
                                }
                            />
                            <ErrorMessage
                                name="name"
                                errors={errors}
                                render={({ message }) => <span>{message}</span>}
                            />
                        </div>
                        <FormControl
                            fullWidth
                            sx={{ margin: ".4rem 0 1.7rem 0" }}
                        >
                            <span className={styles.drawerTopSubTitle}>
                                {t("data.region")}
                            </span>
                            <Controller
                                name="regionId"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required:
                                        createOrUpdate === "create"
                                            ? (t(
                                                "validation.requiredField",
                                            ) as string)
                                            : false,
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        input={
                                            errors?.regionId ? (
                                                <StyledSelectError />
                                            ) : (
                                                <StyledSelect />
                                            )
                                        }
                                    >
                                        {regions.map((region) => (
                                            <MenuItem
                                                key={region.id}
                                                value={region.id}
                                            >
                                                {region.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <ErrorMessage
                                name="regionId"
                                errors={errors}
                                render={({ message }) => (
                                    <span className={styles.selectErrorMsg}>
                                        {message}
                                    </span>
                                )}
                            />
                        </FormControl>
                    </div>

                    <FormButtons
                        isLoading={isCreatingOrUpdatingLoading}
                        createOrUpdate={createOrUpdate}
                        cancelHandler={formClose}
                    />
                </form>
            </CatalogItemActionsComponent>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={"Close"}
                instanceName=""
                message={"closeFormWithoutSaving"}
                handleAction={() => closeModalConfirm()}
            />
        </>
    );
};
