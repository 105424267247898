export const rowData = [
  {
    fullName: "Запрос на отпуск",
    requestType: "Отпуск",
    responsible: "admin@admin.com",
    document: "отпуск_2024.pdf",
    submissionDate: "2024-10-08",
    status: "На рассмотрении",
  },
  {
    fullName: "Запрос на командировку",
    requestType: "Командировка",
    responsible: "ceo@mail.net",
    document: "командировка_2024.pdf",
    submissionDate: "2024-09-01",
    status: "Одобрено",
  },
  {
    fullName: "Запрос на обучение",
    requestType: "Обучение",
    responsible: "hr@mail.com",
    document: "обучение_2024.pdf",
    submissionDate: "2024-08-04",
    status: "На рассмотрении",
  },
  {
    fullName: "Запрос на оборудование",
    requestType: "Запрос оборудования",
    responsible: "warehouse@mail.net",
    document: "оборудование_2024.pdf",
    submissionDate: "2024-08-01",
    status: "Отклонено",
  },
];
