import { SyntheticEvent, useState } from 'react';
import i18n from 'i18next';

import { Tab, Tabs } from '../../../ui/Tabs/Tabs';

import { Automatic } from './components/Automatic';
import { Journal } from './components/Journal';
import { Manual } from './components/Manual';
import { Settings } from './components/Settings';

import styles from './styles.module.css';

const TABS: Tab[] = [
    {
        label: i18n.t('tabs.manual'),
        value: 0
    },
    {
        label: i18n.t('tabs.settings'),
        value: 1
    },
    {
        label: i18n.t('tabs.automatic'),
        value: 2
    },
    {
        label: i18n.t('tabs.journal'),
        value: 3
    },

];

export const Synchronization = () => {
    const [value, setValue] = useState(0);

    const handleChangeTabs = (_: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const Components: { [key: number]: JSX.Element } = {
        [0]: <Manual/>,
        [1]: <Automatic/>,
        [2]: <Settings/>,
        [3]: <Journal/>,
    };

    return (
        <div>
            <Tabs handleChangeTabs={handleChangeTabs} value={value} tabs={TABS}/>
            <div className={styles.container}>
                {Components[value]}
            </div>
        </div>
    );
};
