import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import { DocumentsList } from "../../../features/DocumentsList/DocumentsList";
import Guard from "../../../features/Guard";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAllowedPermissionsByGroupId } from "../../../store";
import {
    addDocument,
    getDocuments,
} from "../../../store/thunks/commonDocumentsThunk";
import Loader from "../../../ui/Loader";
import { SelectFile } from "../../../ui/SelectFile";
import { ETextVariant, Text } from "../../../ui/Text/Text";
import { sendNotification } from "../../../ui/Toast";

import styles from "./styles.module.css";

interface DocumentsProps {
    name: string;
    createOrUpdate: null | string;
    setCreateOrUpdate: Function;
}

export const Documents: FC<DocumentsProps> = () => {
    const fileInput = useRef(null);
    const [file, setFile] = useState<File | null>(null);
    const [newDocumentName, setNewDocumentName] = useState("");
    const [newDocumentDescription, setNewDocumentDescription] = useState("");
    const [isOpenAddFile, setIsOpenAddFile] = useState(false);
    const { t } = useTranslation();
    const documents = useAppSelector(
        (state) => state.commonDocuments.documents,
    );
    const isLoading = useAppSelector(
        (state) => state.commonDocuments.isLoading,
    );
    const dispatch = useAppDispatch();
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
    };

    const save = async () => {
        dispatch(
            addDocument({
                name: newDocumentName,
                description: newDocumentDescription,
                file,
            }),
        )
            .unwrap()
            .then(() => {
                setIsOpenAddFile(false);
                dispatch(getDocuments());
                sendNotification(t("statuses.Success"), "success");
                setNewDocumentName("");
                setNewDocumentDescription("");
                setFile(null);
            })
            .catch((e) => {
                console.log(e);
                sendNotification(t("statuses.Error"), "error");
            });
    };

    useEffect(() => {
        dispatch(getDocuments());

        const group = allowedGroups.find(
            (menu) => menu.name === "api/common-documents",
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, []);

    // if (isLoading) {
    //     return <Loader/>;
    // }

    return (
        <div className={styles.container}>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: 20,
                        }}
                    >
                        <Text size={24} variant={ETextVariant.REGULAR}>
                            {t("tabs.common-documents")}
                        </Text>
                        <Guard
                            allowedPermissions={allowedPermissions || []}
                            permissionName="Create"
                        >
                            <Button
                                variant="contained"
                                sx={{ margin: "0 10px" }}
                                onClick={() => setIsOpenAddFile(true)}
                            >
                                {t("buttons.AddDocument")}
                            </Button>
                        </Guard>
                    </div>
                    <DocumentsList
                        documents={documents}
                        allowedPermissions={allowedPermissions}
                    />
                </div>
            )}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpenAddFile}
                onClose={() => {
                    setIsOpenAddFile(false);
                    setNewDocumentName("");
                    setNewDocumentDescription("");
                    setFile(null);
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Box>
                    <SelectFile
                        file={file}
                        name={newDocumentName}
                        description={newDocumentDescription}
                        setName={setNewDocumentName}
                        setDescription={setNewDocumentDescription}
                        fileInput={fileInput}
                        handleCancelClick={() => setIsOpenAddFile(false)}
                        handleUpload={handleUpload}
                        save={save}
                    />
                </Box>
            </Modal>
        </div>
    );
};
