import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonWithProgress from "ui/ButtonWithProgress";

import { Box, Button } from "@mui/material";

import { useAppSelector } from "../../hooks";
import {
    IAllSkillLevels,
    IAllSkills,
    IEmployeeSkill,
} from "../../models/employees";
import { cancelBtn, saveBtn } from '../../styles/MUIStyles';
import EmployeeSkills from "../Employee/EmployeeSkills/EmployeeSkills";

import UpdateSkill, { UpdateSkillRef } from "./UpdateSkill";

import styles from "../../views/Employees/Employees.module.scss";
interface Props {
    id: string;
    employeeFirstName: string;
    employeeLastName: string;
    employeeSkills: IEmployeeSkill[];
    allSkills: IAllSkills[];
    allSkillLevels: IAllSkillLevels[];
    allowedPermissions: any[];
    showCloseButton?: boolean;
}

const EditEmployeeSkills: React.FC<Props> = ({
    id,
    employeeFirstName,
    employeeLastName,
    employeeSkills,
    allSkills,
    allSkillLevels,
    allowedPermissions
}: Props) => {
    const { t } = useTranslation();
    const [isOpenEditSkills, setisOpenEditSkills] = useState(false);
    const [updatedEmployeeSkills, setUpdatedEmployeeSkills] = useState<IEmployeeSkill[]>(employeeSkills);

    const EmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const userRole = useAppSelector(state => state.auth.role);
    const isAdmin = userRole === 'Administrator' || userRole === 'CEO';
    const addSkillRef = useRef<UpdateSkillRef | null>(null);

    const handleSave = () => {
        if (addSkillRef.current) {
            addSkillRef.current.onSubmit();
        }
    };

    const handleSkillsUpdated = (newSkills: IEmployeeSkill[]) => {
        setUpdatedEmployeeSkills(newSkills);
        setisOpenEditSkills(false);
    };

    return (
        <div style={{ padding: "10px 0px" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "16px",
                }}
                className={styles.employeeFullNameWrapper}
            >
                <h4
                    style={{
                        flex: 1,
                    }}
                >
                    {employeeFirstName} {employeeLastName}
                </h4>
                {
                    isAdmin &&
                    (
                        isOpenEditSkills ?
                        (
                            <>
                                <ButtonWithProgress
                                    loading={false}
                                    disabled={false}
                                    onClick={handleSave}
                                    variant="contained"
                                    sx={saveBtn}
                                >
                                    {t('buttons.Save')}
                                </ButtonWithProgress>
                                <Button
                                    variant="text"
                                    sx={cancelBtn}
                                    onClick={() =>
                                        setisOpenEditSkills(!isOpenEditSkills)
                                    }
                                >
                                    {t("buttons.Cancel")}
                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                sx={saveBtn}
                                onClick={() => setisOpenEditSkills(!isOpenEditSkills)}
                            >
                                {t("buttons.Edit")}
                            </Button>
                        )
                    )
                }
            </Box>

            {isOpenEditSkills ? (
                <>
                    <UpdateSkill
                        id={id}
                        onCancel={() => setisOpenEditSkills(!isOpenEditSkills)}
                        ref={addSkillRef}
                        onSkillsUpdated={handleSkillsUpdated}
                    />
                </>
            ) : (
                <>
                    <EmployeeSkills
                        id={EmployeeDetails?.id ?? ""}
                        allowedPermissions={allowedPermissions}
                        fte={EmployeeDetails?.fte ?? 0}
                        employeeFirstName={EmployeeDetails?.firstName ?? ""}
                        employeeLastName={EmployeeDetails?.lastName ?? ""}
                        employeeSkills={updatedEmployeeSkills}
                        allSkills={allSkills}
                        allSkillLevels={allSkillLevels}
                    />
                </>
            )}
        </div>
    );
};

export default EditEmployeeSkills;
