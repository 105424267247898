import React, { useEffect, useRef, useState } from "react";
import Guard from "features/Guard";
import type { IAllowedPermission } from "models/auth";
import { IDocument } from "models/documents";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PERSONAL_ACCOUNT_EDIT } from "router/constants";
import { useLocalStorage } from "usehooks-ts";

import {
    Backdrop,
    Box,
    Breadcrumbs,
    Button,
    Modal,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

import ConfirmationModal from "../../../features/ConfirmationModal";
import { DocumentsList } from "../../../features/DocumentsList/DocumentsList";
import { EmployeeGeneralInfo } from "../../../features/Employee/EmployeeGeneralInfo";
import EmployeeProjects from "../../../features/Employee/EmployeeProjects/EmployeeProjects";
import EmployeeProjectsTable from "../../../features/Employee/EmployeeProjects/EmployeeProjectTable";
import EmployeeSkills from "../../../features/Employee/EmployeeSkills/EmployeeSkills";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IPersonalProject } from "../../../models/employees";
import {
    addPersonalDocument,
    editPersonalDocuments,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getAllSkillLevels,
    getAllSkills,
    getPersonalDocuments,
    getPersonalInfoEmployeeById,
    getPersonalProjects,
    removePersonalDocumentsLocal,
} from "../../../store";
import Loader from "../../../ui/Loader";
import { SelectFile } from "../../../ui/SelectFile";
import { sendNotification } from "../../../ui/Toast";

import { AddDocument } from "./AddDocument";

import styles from "../../../features/Employee/EmployeeProjects/styles.module.scss";

export const PersonalInfo = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const fileInput = useRef(null);
    const [file, setFile] = useState<File | null>(null);
    const [newDocumentName, setNewDocumentName] = useState("");
    const [newDocumentDescription, setNewDocumentDescription] = useState("");
    const [isOpenAddFile, setIsOpenAddFile] = useState(false);
    const [isOpenConfigDocument, setIsOpenConfigDocument] = useState(false);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isDetailInfo, setIsDetailInfo] = useState(false);
    const [currentProjectInfo, setCurrentProjectInfo] =
        useState<IPersonalProject | null>(null);

    const closeModalConfirm = () => {
        if (isDirty) {
            close();
            setConfirmationModalOpen(false);
        }
    };

    const close = () => {
        isOpenConfigDocument
            ? setIsOpenConfigDocument(!isOpenConfigDocument)
            : navigate(-1);
        setIsDirty(false);

        const removedNotSavedDocuments = personalDocuments?.myDocuments?.filter(
            (item) => typeof item.id === "number",
        );

        dispatch(removePersonalDocumentsLocal(removedNotSavedDocuments));
    };

    const employeesIsLoading = useAppSelector(
        (state) => state.employees.isLoading,
    );
    const personalInfoEmployeeDetails = useAppSelector(
        (state) => state.employees.personalInfoEmployeeDetails,
    );
    const allSkills = useAppSelector((state) => state.employees.allSkills);
    const userId = useAppSelector((state) => state.auth.userId);
    const allSkillLevels = useAppSelector(
        (state) => state.employees.allSkillLevels,
    );
    const personalDocuments = useAppSelector(
        (state) => state.employees.personalDocuments,
    );
    const [employeesActiveTab, setEmployeesActiveTab] = useLocalStorage(
        "employeesActiveTab",
        0,
    );
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsDetailInfo(false);
    }, [employeesActiveTab]);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        setEmployeesActiveTab(newValue);
    };

    const handleUpload = (event: any) => {
        setFile(event.target.files[0]);
    };
    const [edited, setEdited] = useState<IDocument[]>([]);

    const addDocument = () => {
        dispatch(
            editPersonalDocuments(
                edited.filter((item) => typeof item.id !== "undefined"),
            ),
        )
            .unwrap()
            .then(() => {
                setEdited([]);
                dispatch(getPersonalDocuments());
                setIsOpenConfigDocument(!isOpenConfigDocument);
                sendNotification(t("statuses.Success"), "success");
                setIsDirty(false);
            })
            .catch((err) => {
                console.log(err);
                sendNotification(t("statuses.Error"), "error");
            });
    };

    const save = async () => {
        if (file) {
            dispatch(
                addPersonalDocument({
                    name: newDocumentName,
                    description: newDocumentDescription,
                    file,
                }),
            )
                .unwrap()
                .then(() => {
                    setIsOpenAddFile(false);
                    dispatch(getPersonalDocuments());
                    setIsDirty(false);
                })
                .catch((e) => {
                    console.log(e);
                    sendNotification(t("statuses.Error"), "error");
                });
        }
    };

    useEffect(() => {
        (async () => {
            if (userId) {
                setIsLoading(true);
                await Promise.all([
                    dispatch(getPersonalDocuments()),
                    dispatch(getPersonalInfoEmployeeById(userId)),
                    dispatch(getAllSkills()),
                    dispatch(getAllSkillLevels()),
                    dispatch(getPersonalProjects()),
                ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, userId, t]);

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions:IAllowedPermission[] = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find(
            (menu) => menu.name === "PersonalCabinet",
        );
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/personal-cabinet`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    if (!personalInfoEmployeeDetails || isLoading) {
        return <Loader />;
    }

    return (
        <Box sx={{ width: "100%", padding: "10px 0" }}>
            <Breadcrumbs sx={{ padding: "10px" }}>
                <Link
                    to="/personalcabinet"
                    // className={styles.breadScrumbsLink}
                >
                    {t("menu.personalAccount")}
                </Link>
                <Typography
                    sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                >
                    {`${personalInfoEmployeeDetails?.firstName} ${personalInfoEmployeeDetails?.lastName}`}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={employeesActiveTab} onChange={handleActiveTab}>
                    <Tab label={t("tabs.generalInfo")} value={0} />
                    <Tab label={t("tabs.skills")} value={1} />
                    <Tab label={t("tabs.projects")} value={2} />
                    <Tab label={t("tabs.documents")} value={3} />
                    {/*<Tab label={t("tabs.history")} value={4} />*/}
                    {/*<Tab label={t("tabs.calendar")} value={5} />*/}
                    {/*<Tab label={t("tabs.applications")} value={6} />*/}
                    {/*<Tab label={t("tabs.notifications")} value={7} />*/}
                </Tabs>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpenAddFile}
                onClose={() => setIsOpenAddFile(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Box>
                    <SelectFile
                        name={newDocumentName}
                        description={newDocumentDescription}
                        setName={setNewDocumentName}
                        setDescription={setNewDocumentDescription}
                        fileInput={fileInput}
                        handleCancelClick={() => setIsOpenAddFile(false)}
                        handleUpload={handleUpload}
                        save={save}
                    />
                </Box>
            </Modal>
            {employeesActiveTab === 0 && (
                <EmployeeGeneralInfo
                    isPersonal
                    editLink={`/${PERSONAL_ACCOUNT_EDIT}`}
                    allowedPermissions={allowedPermissions}
                    {...personalInfoEmployeeDetails}
                />
            )}

            {employeesActiveTab === 1 && (
                <EmployeeSkills
                        id={personalInfoEmployeeDetails?.id ?? ""}
                        allowedPermissions={allowedPermissions}
                        fte={personalInfoEmployeeDetails?.fte ?? 0}
                        employeeFirstName={personalInfoEmployeeDetails?.firstName ?? ""}
                        employeeLastName={personalInfoEmployeeDetails?.lastName ?? ""}
                        employeeSkills={personalInfoEmployeeDetails?.skills ?? []}
                        allSkills={allSkills}
                        allSkillLevels={allSkillLevels}
                />
            )}
            {employeesActiveTab === 2 && !isDetailInfo && (
                <Guard
                    allowedPermissions={allowedPermissions}
                    permissionName="PersonalCabinetProjectsView"
                >
                    <div className={styles.employeeProjects}>
                        <EmployeeProjectsTable
                            setCurrentProjectInfo={setCurrentProjectInfo}
                            setIsDetailInfo={setIsDetailInfo}
                        />
                    </div>
                </Guard>
            )}

            {employeesActiveTab === 3 && (
                <div>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            margin: "16px",
                        }}
                    >
                        <h4
                            style={{
                                flex: 1,
                            }}
                        >
                            {t("menu.personal-documents")}
                        </h4>

                        {isOpenConfigDocument ? (
                            <Button
                                disabled={
                                    employeesIsLoading ||
                                    !edited.length ||
                                    !edited.some(
                                        (item) =>
                                            typeof item.id !== "undefined",
                                    )
                                }
                                variant="contained"
                                sx={{ margin: "0 10px" }}
                                onClick={() => addDocument()}
                            >
                                {t("buttons.Save")}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{ margin: "0 10px" }}
                                onClick={() =>
                                    setIsOpenConfigDocument(
                                        !isOpenConfigDocument,
                                    )
                                }
                            >
                                {t("buttons.AddDocument")}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (isDirty) {
                                    setConfirmationModalOpen(true);
                                } else {
                                    close();
                                }
                            }}
                            sx={{ margin: "0 10px" }}
                        >
                            {t("buttons.Close")}
                        </Button>
                    </Box>

                    {isOpenConfigDocument ? (
                        <AddDocument
                            setIsDirty={setIsDirty}
                            setEdited={setEdited}
                        />
                    ) : (
                        <Box>
                            <DocumentsList
                                allowedPermissions={allowedPermissions}
                                documents={personalDocuments?.myDocuments ?? []}
                                isPersonal
                            />
                        </Box>
                    )}
                </div>
            )}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setIsModalOpen={setConfirmationModalOpen}
                actionName={"Close"}
                instanceName=""
                message={"closeFormWithoutSaving"}
                handleAction={closeModalConfirm}
            />
            {isDetailInfo && (
                <EmployeeProjects
                    setIsDetailInfo={setIsDetailInfo}
                    isPersonal
                    // @ts-ignore
                    projects={[currentProjectInfo]}
                />
            )}
        </Box>
    );
};
