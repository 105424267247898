import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    UseFormGetValues,
    UseFormSetValue,
} from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Modal from "@mui/material/Modal";

import closeIcon from "../../assets/icons/closeIcon.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IEmployeeSkill,IPutEmployeeSkills } from "../../models/employees";
import { ILookupItem } from "../../models/lookups";
import { getSkillLevelsBySkillSetId } from "../../store";
import {
    activateOrDeactivateBtn,
    activateOrDeactivateModal,
    autocompleteStyles,
    cancelBtn,
} from "../../styles/MUIStyles";
import ButtonWithProgress from "../../ui/ButtonWithProgress";

import styles from "../../views/Catalogs/CatalogsLayout.module.scss";

const SkillsModal: FC<{
    isModalOpen: boolean;
    clearForm: () => void;
    actionName: string;
    skill: IEmployeeSkill | null;
    activeEntityId: string | number | null;
    getMainFormValues: UseFormGetValues<IPutEmployeeSkills>;
    setMainFormValue: UseFormSetValue<IPutEmployeeSkills>;
}> = ({
    isModalOpen,
    clearForm,
    actionName,
    skill,
    getMainFormValues,
    setMainFormValue,
}) => {
    const {
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        control,
    } = useForm<IEmployeeSkill>();

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentLanguage = localStorage.getItem("i18nextLng");

    const skillList = useAppSelector((state) =>
        state.catalogs.skills?.filter((skillItem) =>
            skillItem.id === skill?.skillId
                ? skill
                : !getMainFormValues("skills")?.some(
                      (userSkill) => userSkill.skillId === skillItem.id,
                  ),
        ),
    );
    const skillLevelsBySkillSet = useAppSelector(
        (state) => state.lookups.skillLevels,
    );

    const setFormData = (fields: IEmployeeSkill | null) => {
        setValue("id", fields?.id);
        setValue("skillId", fields?.skillId || -1);
        setValue("skillLevelId", fields?.skillLevelId || -1);
        setValue("isBasicSkill", fields?.isBasicSkill || false);
        if (fields?.skillId) {
            const skillSetId = skillList.filter(
                (el) => el.id == fields.skillId,
            );
            if (skillSetId && skillSetId.length > 0)
                dispatch(getSkillLevelsBySkillSetId(skillSetId[0].skillSetId));
        }
    };

    const getFormData = () => {
        return {
            id: getValues("id"),
            skillId: getValues("skillId"),
            skillLevelId: getValues("skillLevelId"),
            isBasicSkill: getValues("isBasicSkill"),
        };
    };

    const invalidSubmit = (errors: any, event: any) => {
        // event.preventDefault();
        event.stopPropagation();
    };

    const onSkillChange = (id: number | string | undefined) => {
        if (id) {
            // setValue("skillId", id);
            setValue("skillLevelId", null);
            const skillSetId = skillList.filter((el) => el.id == id);
            if (skillSetId && skillSetId.length > 0)
                dispatch(getSkillLevelsBySkillSetId(skillSetId[0].skillSetId));
        }
        setValue("skillLevelId", null);
    };

    const onSubmit = async () => {
        let newSkills = [];
        const skills = getMainFormValues("skills");
        if (getValues("id")) {
            if (skills?.length) {
                const index = skills?.findIndex(
                    (el) => el.id === getValues("id"),
                );
                newSkills = [
                    ...skills?.slice(0, index),
                    getFormData(),
                    ...skills?.slice(index + 1, skills?.length),
                ];
                setMainFormValue("skills", newSkills);
            }
        } else {
            if (skills?.length) {
                // @ts-ignore
                newSkills = [...skills, { ...getFormData(), id: Date.now() }];
            } else {
                newSkills.push({ ...getFormData(), id: Date.now() });
            }
            setMainFormValue("skills", newSkills);
        }
        clearForm();
        setFormData(null);
    };

    useEffect(() => {
        if (isModalOpen && skill) {
            setFormData(skill);
        }
    }, [isModalOpen, skill]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isModalOpen || false}
                onClose={() => {
                    setFormData(null);
                    clearForm();
                }}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={isModalOpen}>
                    <Box sx={activateOrDeactivateModal}>
                        <div className={styles.activateModalTopWrapper}>
                            <h3 className={styles.activateModalTitle}>
                                {t(`buttons.${actionName}`)}{" "}
                                {t("catalogActions.skills") || " "}
                            </h3>
                            <img
                                src={closeIcon}
                                alt=""
                                onClick={() => {
                                    setFormData(null);
                                    clearForm();
                                }}
                            />
                        </div>

                        <form onSubmit={handleSubmit(onSubmit, invalidSubmit)}>
                            <FormControl
                                fullWidth
                                sx={{ margin: ".4rem 0 1.7rem 0" }}
                            >
                                <span className={styles.drawerTopSubTitle}>
                                    {t("data.skill")}
                                </span>
                                <Controller
                                    name="skillId"
                                    control={control}
                                    rules={{
                                        required: t(
                                            "validation.requiredField",
                                        ) as string,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            ListboxProps={{
                                                style: { maxHeight: "200px" },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    sx={autocompleteStyles}
                                                    placeholder={
                                                        t("data.skill") || ""
                                                    }
                                                    error={!!fieldState.error}
                                                />
                                            )}
                                            options={[...skillList].sort(
                                                (
                                                    a: ILookupItem,
                                                    b: ILookupItem,
                                                ) => {
                                                    return a.name.localeCompare(
                                                        b.name,
                                                        currentLanguage ===
                                                            "en-US"
                                                            ? "en"
                                                            : "ru",
                                                    );
                                                },
                                            )}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(
                                                event,
                                                value: ILookupItem | null,
                                            ) => {
                                                field.onChange(value?.id);
                                                onSkillChange(value?.id);
                                            }}
                                            value={
                                                skillList.filter(
                                                    (el) =>
                                                        el?.id ===
                                                        getValues("skillId"),
                                                )[0] || null
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    name="skillId"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span className={styles.selectErrorMsg}>
                                            {message}
                                        </span>
                                    )}
                                />
                            </FormControl>

                            <FormControl
                                fullWidth
                                sx={{ margin: ".4rem 0 1.7rem 0" }}
                            >
                                <span className={styles.drawerTopSubTitle}>
                                    {t("data.level")}
                                </span>
                                <Controller
                                    name="skillLevelId"
                                    control={control}
                                    rules={{
                                        required: t(
                                            "validation.requiredField",
                                        ) as string,
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            {...field}
                                            ListboxProps={{
                                                style: { maxHeight: "200px" },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    sx={autocompleteStyles}
                                                    placeholder={
                                                        t("data.level") || ""
                                                    }
                                                    error={!!fieldState.error}
                                                />
                                            )}
                                            options={[
                                                ...skillLevelsBySkillSet,
                                            ].sort(
                                                (
                                                    a: ILookupItem,
                                                    b: ILookupItem,
                                                ) => {
                                                    return a.name.localeCompare(
                                                        b.name,
                                                        currentLanguage ===
                                                            "en-US"
                                                            ? "en"
                                                            : "ru",
                                                    );
                                                },
                                            )}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(
                                                event,
                                                value: ILookupItem | null,
                                            ) => field.onChange(value?.id)}
                                            value={
                                                skillLevelsBySkillSet.filter(
                                                    (el) =>
                                                        el?.id ===
                                                        getValues(
                                                            "skillLevelId",
                                                        ),
                                                )[0] || null
                                            }
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    name="skillLevelId"
                                    errors={errors}
                                    render={({ message }) => (
                                        <span className={styles.selectErrorMsg}>
                                            {message}
                                        </span>
                                    )}
                                />
                            </FormControl>

                            <Controller
                                name="isBasicSkill"
                                control={control}
                                render={() => (
                                    <FormGroup
                                        sx={{
                                            display: "inline-block",
                                            mb: "5px",
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={getValues(
                                                        "isBasicSkill",
                                                    )}
                                                    onChange={(event) =>
                                                        setValue(
                                                            "isBasicSkill",
                                                            event.target
                                                                .checked,
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <span
                                                    className={
                                                        styles.checkboxLabel
                                                    }
                                                >
                                                    {t("data.mainSkill")}
                                                </span>
                                            }
                                        />
                                    </FormGroup>
                                )}
                            />

                            <div className={styles.activateModalButtonWrapper}>
                                <Button
                                    onClick={() => {
                                        setFormData(null);
                                        clearForm();
                                    }}
                                    variant="text"
                                    sx={{ ...cancelBtn, margin: "5px" }}
                                >
                                    {t("buttons.Cancel")}
                                </Button>
                                <ButtonWithProgress
                                    loading={false}
                                    disabled={false}
                                    variant="contained"
                                    type="submit"
                                    sx={activateOrDeactivateBtn}
                                >
                                    {t(`buttons.${actionName}`)}
                                </ButtonWithProgress>
                            </div>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default SkillsModal;
