import { useEffect, useState } from "react";
import axiosApi from "core/request";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "hooks";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { sendNotification } from "ui/Toast";

import { Button } from "@mui/material";

import Loader from "../../../../ui/Loader";
import { Text } from "../../../../ui/Text/Text";

import { SickCalendar } from "./components/SickCalendar";

import styles from "./styles.module.scss";

interface CalendarData {
  allDay: boolean | null;
  description: string | null;
  endDate: string;
  id: number | null;
  startDate: string | null;
  title: string | null;
  type: number | null;
}

export const SickLeave = () => {
  const { t } = useTranslation();
  const employeeId = useAppSelector((state) => state.auth.userId);
  const [isLoading, setIsLoading] = useState(false);
  const [holidaysToDelete, setHolidaysToDelete] = useState<number[] | string[]>([]);
  const [calendarData, setCalendarData] = useState<CalendarData[]>([]);
  const [addedHolidays, setAddedHolidays] = useState<
    {
      id: string | null;
      date: Dayjs | null;
      dateEnd: Dayjs | null;
      note: string;
      pureDays?: number;
    }[]
  >([]);

  const [metaInfo, setMetaInfo] = useState({
    maxHolidaysPerYear: 0,
    isHolidaysModifiable: true,
    isSickLeaveModifiable: true,
    maxSickLeaveDaysPerYear: 5,
    sickLeaveBalance: 0,
  });
  const [isEdit, setIsEdit] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [calendarRes, metaRes] = await Promise.all([
        axiosApi.get<CalendarData[]>("/personal-cabinet/sick-leave", {
          params: { employeeId },
        }),
        axiosApi.get(`/personal-cabinet/sick-leave/meta?employeeId=${employeeId}`),
      ]);

      // Фильтруем по типу – например, type === 1 для больничных
      setCalendarData(calendarRes.data.filter((item: any) => item.type === 1));
      setMetaInfo(metaRes.data);
    } catch (error) {
      console.error("Error fetching sick leave data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [employeeId]);

  const handleSave = async () => {
    try {
      // const allDates = [...calendarData, ...addedHolidays];
      //
      // if (allDates.length !== 0) {
      //   const hasLongSickLeave = allDates?.some((d) =>
      //     dayjs(typeof d.id === "string" ? d.dateEnd : d.endDate)
      //       .diff(dayjs(typeof d.id === "string" ? d.date : d.startDate), "day") +
      //     1 >=
      //     14
      //   );
      //
      //   if (!hasLongSickLeave) {
      //     throw new Error(t("sickLeave.durationError"));
      //   }
      // }

      setIsLoading(true);

      const daysOffDto = addedHolidays.map((holiday) => {
        const localStartDate = dayjs(holiday.date).startOf("day");
        const localEndDate = dayjs(holiday.dateEnd).startOf("day");
        const offset = localStartDate.utcOffset();
        const offsetInHours = dayjs().utcOffset() / 60;

        const adjustedDate = localStartDate
          .utc()
          .add(offset + offsetInHours, "minute")
          .format();

        const adjustedEndDate = localEndDate
          .utc()
          .add(offset + offsetInHours, "minute")
          .format();

        return {
          startDate: adjustedDate,
          finishDate: adjustedEndDate,
          sign: 1,
          remark: holiday.note || null,
          pureDays: holiday.pureDays,
        };
      });

      if (holidaysToDelete.length > 0) {
        await axiosApi.delete("/personal-cabinet/sick-leave/daysoff", {
          data: { ids: holidaysToDelete },
        });
      }

      if (daysOffDto.length > 0) {
        const payload = {
          employeeId,
          daysOffDto,
        };

        await axiosApi.post("/personal-cabinet/sick-leave/daysoff", payload);
      }

      sendNotification(t("notifications.successfullySaved"), "success");

      setAddedHolidays([]);
      setHolidaysToDelete([]);
      setIsEdit(false);
      fetchData();
    } catch (error) {
      // if (typeof error.message === "string") {
      //   sendNotification(error.message, "error");
      //   return;
      // }
      sendNotification(t("statuses.Error"), "error");
      console.error("Error saving sick leave:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNewHoliday = (holiday: {
    date: Dayjs | null;
    dateEnd: Dayjs | null;
    note: string;
    id: string | null;
    pureDays?: number;
  }) => {
    setAddedHolidays((prev) => [...prev, holiday]);
  };

  const handleMarkForDelete = (id: number | string) => {
    // @ts-ignore
    setHolidaysToDelete((prev) => [...prev, id]);
    setCalendarData((prev) => prev.filter((item) => item.id !== id));
    setAddedHolidays((prev) => prev.filter((holiday) => holiday.id !== id));
  };

  const handleRemoveAddedHoliday = (id: string | null) => {
    setAddedHolidays((prev) => prev.filter((holiday) => holiday.id !== id));
  };

  return (
    <div>
      {!isLoading && (
        <div className={styles.meta_info}>
          <div>
            <div className={styles.box_year}>
              <p>2025</p>
            </div>
            <Text style={{ marginBottom: "10px" }} size={20} variant="REGULAR">
              Общее кол-во больничных дней в году: &nbsp;
              <span className={styles.value_color}>
                {metaInfo.sickLeaveBalance}
              </span>
            </Text>
            <Text style={{ marginBottom: "10px" }} size={20} variant="REGULAR">
              Оплачиваемые больничные без справки: &nbsp;
              <span className={styles.value_color}>
                {metaInfo.maxSickLeaveDaysPerYear}
              </span>
            </Text>
          </div>
          <div style={{ marginLeft: "50px" }} className={styles.meta_info}>
            {metaInfo.isSickLeaveModifiable && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={isEdit ? handleSave : () => setIsEdit(true)}
              >
                {isEdit ? t("buttons.Save") : t("buttons.Edit")}
              </Button>
            )}
            {isEdit && (
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={() => {
                  setIsEdit(false);
                  setAddedHolidays([]);
                  setHolidaysToDelete([]);
                  fetchData();
                }}
              >
                {t("buttons.Close")}
              </Button>
            )}
          </div>
        </div>
      )}
      <div className={styles.cards_container}>
        {isLoading ? (
          <div style={{ width: "100%" }}>
            <Loader />
          </div>
        ) : isEdit ? (
          <>
            {metaInfo.isSickLeaveModifiable && (
              <SickCalendar
                isNew
                onAdd={handleAddNewHoliday}
              />
            )}
            {[...calendarData, ...addedHolidays].map((item: any) => (
              <SickCalendar
                dateEnd={
                  typeof item.id === "number" ? item.endDate : item.dateEnd
                }
                key={item.id || item.id}
                id={item.id || null}
                date={
                  typeof item.id === "string" ? item.date : item.startDate
                }
                note={
                  typeof item.id === "string" ? item.note : item.description
                }
                onDelete={
                  typeof item.id === "number"
                    ? () => handleMarkForDelete(item.id!)
                    : () => handleRemoveAddedHoliday(item.id)
                }
              />
            ))}
          </>
        ) : (
          <div>
            <div className={styles.event_wrapper}>
              {calendarData.map((item: CalendarData, idx) => {
                const startDate = moment(item.startDate);
                const endDate = moment(item.endDate);
                const daysDifference = endDate.diff(startDate, "days") + 1;

                return (
                  <div key={item.id} className={styles.event}>
                    <span>
                      <b>{idx + 1}</b>
                    </span>
                    <span>{startDate.format("DD.MM.YYYY")}</span>
                    <span>-</span>
                    <span>{endDate.format("DD.MM.YYYY")}</span>
                    <b>{daysDifference}</b>
                    <span>{item.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
