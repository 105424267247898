import React from 'react';

import { EditEmployee } from '../../features/EditEmployee/EditEmployee';
import { useAppSelector } from '../../hooks';

export const PersonalAccountEdit = () => {
    const id = useAppSelector((state) => state.auth.userId);
    const allowedPermissions = useAppSelector( (state) => state.auth.allowedPermissions);

    return (
        <EditEmployee id={id!} isMyAccount allowedPermissions={allowedPermissions}/>
    );
};
