import { createSlice } from "@reduxjs/toolkit";

import { ICalendarEvent } from "../../features/BigCalendar";
import { getCalendarData } from "../thunks/personalCabinetThunk";

interface PersonalCabinetSlice {
    isLoading: boolean;
    eventData: ICalendarEvent[];
}

const initialState: PersonalCabinetSlice = {
    isLoading: false,
    eventData: [],
};

const personalCabinetSlice = createSlice({
    name: "salaries",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCalendarData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCalendarData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.eventData = action.payload;
            });
    },
});

export default personalCabinetSlice.reducer;
