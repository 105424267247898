import React, { FC } from "react";
import { ITabPanelProps } from "models/projects";
import { useTranslation } from "react-i18next";

import { Box, Tab, Tabs } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITrackingFilters } from "../../models/tracking";
import { changeTrackingActiveTab } from "../../store";

import AccountantEmployeesTrackingTable from "./AccountantEmployeesTrackingTable";
import AccountantProjectsTrackingTable from "./AccountantProjectsTrackingTable";

interface Props {
    setTrackingEmployeesPageNumber: (pageNumber: number) => void;
    setTrackingProjectsPageNumber: (pageNumber: number) => void;
    debouncedEmployeesSearch: string;
    debouncedProjectsSearch: string;
    trackingEmployeesPageNumber: number;
    trackingProjectsPageNumber: number;
    isTrackingEmployeesSearchingRef: {
        current: boolean | null;
    };
    isTrackingProjectsSearchingRef: {
        current: boolean | null;
    };
    trackingEmployeesSortConfig: {
        column: string | null;
        direction: string | null;
    };
    trackingEmployeesHandleColumnClick: any;
    trackingProjectsSortConfig: {
        column: string | null;
        direction: string | null;
    };
    trackingProjectsHandleColumnClick: any;
    year: number;
    month: number;
    trackingFilters: ITrackingFilters;
    setTrackingFilters: () => void;
    trackingPageSize: number;
    handleColumnClick: any;
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const AccountantTrackingTable: FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        debouncedEmployeesSearch,
        debouncedProjectsSearch,
        setTrackingEmployeesPageNumber,
        setTrackingProjectsPageNumber,
        isTrackingEmployeesSearchingRef,
        isTrackingProjectsSearchingRef,
        trackingEmployeesSortConfig,
        trackingEmployeesHandleColumnClick,
        trackingProjectsSortConfig,
        trackingProjectsHandleColumnClick,
        trackingEmployeesPageNumber,
        trackingProjectsPageNumber,
        year,
        month,
        trackingFilters,
        setTrackingFilters,
        trackingPageSize,
        handleColumnClick,
    } = props;

    // const trackings = useAppSelector(
    //     (state) => state.tracking.employeesTracking
    // );
    // const activeTracking = useAppSelector(
    //     (state) => state.tracking.activeTracking
    // );
    const activeTab = useAppSelector((state) => state.tracking.activeTab);

    // const getArrowForSortDirection = (column: string) => {
    //     if (trackingSortConfig.column !== column) {
    //         return <DefaultSortIcon/>;
    //     }
    //
    //     return trackingSortConfig.direction === 'asc' ? <AscSortIcon/> : <DescSortIcon/>;
    // };

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(changeTrackingActiveTab(newValue));
    };

    return (
        <div>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    paddingLeft: "20px",
                }}
            >
                <Box sx={{ width: "60%" }}>
                    <Tabs value={activeTab} onChange={handleActiveTab}>
                        <Tab label={t("tabs.employees")}
                        />
                        <Tab
                            // disabled={params?.operation === 'create' && !projectId}
                            label={t("tabs.projects")}
                        />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel index={0} value={activeTab}>
                <AccountantEmployeesTrackingTable
                    trackingPageSize={trackingPageSize}
                    setTrackingPageNumber={setTrackingEmployeesPageNumber}
                    debouncedSearch={debouncedEmployeesSearch}
                    trackingPageNumber={trackingEmployeesPageNumber}
                    isTrackingSearchingRef={isTrackingEmployeesSearchingRef}
                    trackingSortConfig={trackingEmployeesSortConfig}
                    trackingHandleColumnClick={
                        trackingEmployeesHandleColumnClick
                    }
                    year={year}
                    month={month}
                    trackingFilters={trackingFilters}
                    setTrackingFilters={setTrackingFilters}
                />
            </TabPanel>
            <TabPanel index={1} value={activeTab}>
                <AccountantProjectsTrackingTable
                    trackingPageSize={trackingPageSize}
                    setTrackingPageNumber={setTrackingProjectsPageNumber}
                    debouncedSearch={debouncedProjectsSearch}
                    trackingPageNumber={trackingProjectsPageNumber}
                    isTrackingSearchingRef={isTrackingProjectsSearchingRef}
                    trackingSortConfig={trackingProjectsSortConfig}
                    trackingHandleColumnClick={
                        trackingProjectsHandleColumnClick
                    }
                    handleColumnClick={handleColumnClick}
                    year={year}
                    month={month}
                    trackingFilters={trackingFilters}
                    setTrackingFilters={setTrackingFilters}
                />
            </TabPanel>
        </div>
    );
};

export default AccountantTrackingTable;
