import * as React from 'react';
import { useEffect } from "react";
import { Controller, useForm } from 'react-hook-form';

import AdapterDayjs from "@date-io/date-fns";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import styles from './styles.module.css';

interface RequestProps {
  toggleDrawer: (_: boolean) => () => void
  setSelectedRow:  React.Dispatch<any>
  data?: {
    fullName: string;
    requestType: string;
    responsible?: string;
    otherRequestType?: string;
    file?: File[] | null,
    startDate?: string | null,
    endDate?: string | null,
  };
}

export const Request = ({ toggleDrawer, data, setSelectedRow }: RequestProps) => {
  const { control, handleSubmit, register, reset, watch } = useForm({
    defaultValues: data || {
      fullName: '',
      requestType: '',
      responsible: '',
      otherRequestType: '',
      file: null,
      startDate: null,
      endDate: null,
    },
  });

  useEffect(() => {
    return () => {
      setSelectedRow(null);
    };
  }, []);

  const onSubmit = (data: any) => {
    console.log('Form Data:', data);
  };

  return (
    <Box className={styles.container} role="presentation" onClick={(event) => event.stopPropagation()}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <TextField
          placeholder="Название заявки"
          variant="outlined"
          fullWidth
          {...register('fullName', { required: true })}
        />

        <FormControl fullWidth>
          <Controller
            name="requestType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <span style={{ color: '#aaa' }}>Тип заявки</span>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="Запрос оборудования">Запрос на оборудование</MenuItem>
                <MenuItem value="Командировка">Командировка</MenuItem>
                <MenuItem value="Отпуск">Отпуск</MenuItem>
                <MenuItem value="Другое">Другое</MenuItem>
              </Select>
            )}
          />
        </FormControl>


        {watch('requestType') === 'Другое' && <TextField
          placeholder="Напишите тип заявки"
          variant="outlined"
          fullWidth
          {...register('otherRequestType', { required: false })}
        />}

        <TextField
          placeholder="Ответственный"
          variant="outlined"
          fullWidth
          {...register('responsible', { required: false })}
        />

        <div>
          <input
            type="file"
            {...register('file')}
            id="file"
            className={styles.fileInput}
          />
          <label htmlFor="file" className={styles.fileInputDescription}>
            {watch('file')?.[0]?.name || 'Выберите файл'}
          </label>

        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker label="Дата начала" {...field}
                          renderInput={(params) => <TextField {...params} fullWidth label={undefined}/>}/>
            )}
          />

          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker label="Дата окончания" {...field}
                          renderInput={(params) => <TextField {...params} fullWidth label={undefined}/>}/>
            )}
          />
        </LocalizationProvider>

        <Box className={styles.buttonGroup}>
          <Button type="submit" variant="contained" color="primary">Сохранить</Button>
          <Button type="button" variant="outlined" onClick={() => {
            reset();
            toggleDrawer(false)();
          }}>Отменить</Button>
        </Box>
      </form>
    </Box>
  );
};
