import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import styled from "@emotion/styled";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Button from "@mui/material/Button";

import { getFromLocalStorage } from "../../core/utils";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTrackingByEmployeeId } from "../../store/thunks/trackingThunk";
import {
  cancelBtn,
  projectsListTableCell,
  projectsTableContainer,
} from "../../styles/MUIStyles";

import styles from "./Tracking.module.scss";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    color: "#3E444A",
    padding: "8px 8px 8px 6px",
    fontFamily: "Source Sans Pro",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "700 !important",
    "&:first-of-type": {
      padding: "8px 8px 8px 20px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
    wordBreak: "break-word",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&:first-of-type": {
      padding: "16px 16px 16px 0",
    },
    "&:last-of-type": {
      padding: "16px",
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "& img": {
    visibility: "hidden",
  },
  "&": {
    cursor: "pointer",
  },
  "&:hover": {
    background: "#f4f3f2",
    "& img": {
      visibility: "visible",
      cursor: "pointer",
    },
  },
}));

interface Props {
  // year: number,
  // month: number
}

const TrackingEmployeeTable: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const year = getFromLocalStorage<number>("trackingFilterYear");
  const month = getFromLocalStorage<number>("trackingFilterMonth");
  // const {
  //     year,
  //     month
  // } = props;

  const [totalFte, setTotalFte] = useState<number | null>(null);
  const [totalHours, setTotalHours] = useState<number | null>(null);

  // const activeTracking = useAppSelector(state => state.tracking.activeTracking);
  const { userId } = useAppSelector((state) => state.auth);
  const employeeTracking = useAppSelector(
    (state) => state.tracking.employeeTracking,
  );

  useEffect(() => {
    dispatch(
      getTrackingByEmployeeId({
        employeeId: params?.employeeId || null,
        // employeeId: '5e58e21e-8a58-40bb-d84d-08db39b21bcd',
        year: year,
        month: month,
      }),
    );
  }, [year, month, userId, dispatch, params?.employeeId]);

  const getFteColor = (fte: number) => {
    if (fte > 0.5 && fte < 1.3) return "#01AE52";
    else if ((fte >= 0.1 && fte <= 0.5) || (fte >= 1.3 && fte <= 1.8))
      return "#F5A96A";
    else return "#FE0A0B";
  };

  const getHoursColor = (hours: number) => {
    if (hours > 80 && hours < 208) return "#01AE52";
    else if ((hours >= 16 && hours <= 80) || (hours >= 208 && hours <= 288))
      return "#F5A96A";
    else return "#FE0A0B";
  };

  useEffect(() => {
    const fte = employeeTracking.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.fte;
    }, 0);

    setTotalFte(fte);
    const hours = employeeTracking.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.totalTime;
    }, 0);
    setTotalHours(hours);
  }, [employeeTracking]);

  return (
    <>
      <div className={styles.crudButtonWrapper}></div>
      
      <div className={styles.headerWrapper}>
        <div>
        <div className={styles.box_year}>
          <ArrowLeftIcon
            htmlColor="gray"
            sx={{ color: "gray", fontSize: "40px" }}
            // onClick={() => handleChangeYear(year - 1)}
          />
          <p>{year}</p>
          <ArrowRightIcon
            htmlColor="gray"
            sx={{
              color: "gray",
              fontSize: "40px",
            }}
            // onClick={() => handleChangeYear(year + 1)}
          />
        </div>
        <div className={styles.box_year}>
          <ArrowLeftIcon
            htmlColor="gray"
            sx={{ color: "gray", fontSize: "40px" }}
            // onClick={() => handleChangeMonth(monthOfTracking - 1)}
          />
          <p>{t(`months.${month}`)}</p>
          <ArrowRightIcon
            htmlColor="gray"
            sx={{
              color: "gray",
              fontSize: "40px",
            }}
            // onClick={() => handleChangeMonth(monthOfTracking + 1)}
          />
        </div>
        </div>

        <Button
          className={styles.backButton}
          variant="text"
          onClick={() => navigate(-1)}
          sx={cancelBtn}
        >
          {t("buttons.Close")}
        </Button>
      </div>


      <div style={{ marginRight: "1px" }}>
        <TableContainer sx={{ ...projectsTableContainer }}>
          <Table stickyHeader sx={{ minWidth: 200 }}>
            <TableHead>
              <TableRow sx={{ border: "none", borderBottom: "none" }}>
                <StyledTableCell
                  colSpan={3}
                  sx={{ border: "none" }}
                  align="right"
                >
                  {t("tracking.total").toUpperCase()}
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <b
                    style={{
                      color: getFteColor(totalFte || 0),
                    }}
                  >
                    {totalFte}
                  </b>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <b
                    style={{
                      color: getHoursColor(totalHours || 0),
                    }}
                  >
                    {totalHours}
                  </b>
                </StyledTableCell>
              </TableRow>
              <TableRow sx={{ border: "none" }}>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <TableSortLabel>{t("data.client")}</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <TableSortLabel>{t("data.project")}</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <TableSortLabel>{t("data.fromDate")}</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <TableSortLabel>{t("data.fte")}</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell sx={{ border: "none" }} align="center">
                  <TableSortLabel>{t("data.hours")}</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                  sx={{ border: "none" }}
                  align="left"
                ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeTracking?.map((tracking) => (
                <StyledTableRow
                  key={tracking.projectId}
                  // onClick={() => dispatch(setTracking(tracking))}
                  // sx={{
                  //     background: tracking.projectId === activeTracking?.projectId ? "#f4f3f2" : "transparent",
                  //     "& span img": {
                  //         visibility: tracking.projectId === activeTracking?.projectId ? "visible" : "hidden",
                  //     }
                  // }}
                >
                  <StyledTableCell align="center" sx={projectsListTableCell}>
                    <b>{tracking.client}</b>
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={projectsListTableCell}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={3}>
                        <Link to={`/projects/view/${tracking.projectId}`}>
                          <b>{tracking.projectCode}</b>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to={`/projects/view/${tracking.projectId}`}>
                          <b>{tracking.shortName}</b>
                        </Link>
                      </Grid>
                    </Grid>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={projectsListTableCell}>
                    {tracking.startDate
                      ? dayjs(tracking.startDate).format("DD.MM.YYYY")
                      : null}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={projectsListTableCell}>
                    <b>{tracking.fte}</b>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={projectsListTableCell}>
                    <b>{tracking.totalTime}</b>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default TrackingEmployeeTable;
