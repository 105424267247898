import axios from "axios";
import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { IEmployeePutArgs, IPersonalProject, IPutEmployeeSkills } from "../../models/employees";

export const getEmployees = createAsyncThunk(
    "employees/getEmployees",
    async (
        searchQuery: {
            organizationId?: number | null | string;
            guildId?: number | null | string;
            positionId?: number | null | string;
            skillId?: number | null | string;
            skillLevelId?: number | null | string;
            costCenterId?: number | null | string;
            status?: number | null | string;
            fullName?: string | null;
            sortBy?: { column: string | null; direction: string | null };
            pageNumber?: number | null;
            pageSize?: number | null | string;
            typeOfEmployments?: string[];
        },
        { rejectWithValue },
    ) => {
        try {
            const params = new URLSearchParams();

            if (searchQuery) {
                const {
                    organizationId,
                    guildId,
                    positionId,
                    skillId,
                    skillLevelId,
                    costCenterId,
                    status,
                    fullName,
                    pageNumber,
                    pageSize,
                    typeOfEmployments,
                } = searchQuery;

                if (typeOfEmployments && typeOfEmployments.length > 0) {
                    typeOfEmployments.forEach((type) => {
                        params.append("filter.typeOfEmployments", type);
                    });
                }

                if (pageNumber)
                    params.append("pageNumber", pageNumber.toString());
                if (pageSize) params.append("pageSize", pageSize.toString());
                if (organizationId)
                    params.append(
                        "filter.organizationId",
                        organizationId.toString(),
                    );
                if (skillId)
                    params.append("filter.skillId", skillId.toString());
                if (skillLevelId)
                    params.append(
                        "filter.skillLevelId",
                        skillLevelId.toString(),
                    );
                if (costCenterId)
                    params.append(
                        "filter.costCenterId",
                        costCenterId.toString(),
                    );
                if (status) params.append("filter.status", status.toString());
                if (guildId)
                    params.append("filter.guildId", guildId.toString());
                if (positionId)
                    params.append("filter.positionId", positionId.toString());
                if (fullName) params.append("filter.fullName", fullName);
                if (
                    searchQuery.sortBy?.column &&
                    searchQuery.sortBy.direction
                ) {
                    const column = searchQuery.sortBy?.column;
                    const direction = searchQuery.sortBy.direction;
                    params.append("sortBy", `${column}.${direction}`);
                }
            }

            const response = await axiosApi.get("/employees/all", { params });
            return response.data;
        } catch (err) {
            return rejectWithValue("Error");
        }
    },
);

export const getEmployeeById = createAsyncThunk(
    "employees/getEmployeeById",
    async (employeeId: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `employees/details?id=${employeeId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
export const getPersonalInfoEmployeeById = createAsyncThunk(
    "employees/getPersonalInfoEmployeeById",
    async (employeeId: string, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `personal-cabinet/about-me?id=${employeeId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);



export const editPersonalInfo = createAsyncThunk('personal-cabinet/about-me',
    async (employee: IEmployeePutArgs, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put('/personal-cabinet/about-me', { ...employee });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
                }
            }

            return rejectWithValue('Что-то пошло не так... Попробуйте обновить страницу');
        }
    }
);

export const getPersonalDocuments = createAsyncThunk(
    "employees/getPersonalDocuments",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/personal-cabinet/documents");
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                return rejectWithValue("Network response was not received");
            }

            return rejectWithValue(error.response.data.error || "Error");
        }
    },
);

export const editPersonalDocument = createAsyncThunk(
    "personal-documents-edit",
    async (
        data: {
            id: number;
            name: string;
            description: string;
            file?: File | null;
        },
        { rejectWithValue },
    ) => {
        try {
            const formData = new FormData();

            formData.append("id", String(data.id));
            formData.append("name", String(data.name));
            formData.append("description", data.description);
            if (data?.file) {
                formData.append("attachment", data.file);
            }
            const res = await axiosApi.put(
                "/personal-cabinet/attachmentdocument",
                formData,
            );

            return res.data;
        } catch (e) {
            return rejectWithValue("Error");
        }
    },
);

export const editPersonalDocuments = createAsyncThunk(
    "personal-documents-edit-2",
    async (
        data: {
            id?: number | null;
            name: string;
            description: string;
            attachment?: File | null;
        }[],
        { rejectWithValue },
    ) => {
        try {
            const formData = new FormData();

            data.forEach((item, index) => {
                formData.append(
                    `listOfUpdatedEmployeeAttachmentDocuments[${index}].id`,
                    (item?.id || "").toString(),
                );
                formData.append(
                    `listOfUpdatedEmployeeAttachmentDocuments[${index}].name`,
                    item.name,
                );
                formData.append(
                    `listOfUpdatedEmployeeAttachmentDocuments[${index}].description`,
                    item.description,
                );

                if (item.attachment) {
                    formData.append(
                        `listOfUpdatedEmployeeAttachmentDocuments[${index}].attachment`,
                        item.attachment,
                    );
                }
            });

            const res = await axiosApi.putForm(
                "/personal-cabinet/attachmentdocument",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            return res.data;
        } catch (e) {
            console.error(e);
            return rejectWithValue("Error");
        }
    },
);

export const addPersonalDocument = createAsyncThunk(
    "personal-documents-add",
    async (
        data: {
            name: string;
            description: string;
            file: File | null;
        },
        { rejectWithValue },
    ) => {
        try {
            const { name, description, file } = data;

            const res = await axiosApi.post(
                "/personal-cabinet/attachmentdocument",
                {
                    name,
                    description,
                    attachment: file,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );

            return res.data;
        } catch (e) {
            return rejectWithValue("Error");
        }
    },
);

export const deletePersonalDocument = createAsyncThunk(
    "personal-documents-delete",
    async (
        data: {
            id: number;
        },
        { rejectWithValue },
    ) => {
        try {
            const res = await axiosApi.delete(
                `/personal-cabinet/attachmentdocument?id=${data.id}`,
            );

            return res.data;
        } catch (e) {
            return rejectWithValue("Error");
        }
    },
);

export const editEmployee = createAsyncThunk(
    "employees/editEmployee",
    async ( { employee, endpoint }: {employee: IEmployeePutArgs, endpoint: string}, { rejectWithValue }) => {
        try {
            const response = await axiosApi.put(endpoint, { ...employee });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);

export const editEmployeeSkill = createAsyncThunk(
    "employees/updateemployeeskills",
    async (employee: IPutEmployeeSkills, { rejectWithValue }) => {
        try {
            //TODO TYPE THE RESPONSE FROM THE SERVER
            const response = await axiosApi.put("employees/updateemployeeskills", { ...employee });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 400) {
                    return rejectWithValue(error.response.data.errors);
                } else {
                    return rejectWithValue(
                        "Что-то пошло не так... Попробуйте обновить страницу",
                    );
                }
            }

            if (!navigator.onLine) {
                return rejectWithValue("Проверьте подключение к интернету");
            }

            return rejectWithValue(
                "Что-то пошло не так... Попробуйте обновить страницу",
            );
        }
    },
);


export const getPersonalProjects = createAsyncThunk<IPersonalProject[]>(
    "employees/getPersonalProjects",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                "/personal-cabinet/myprojects",
                {},
            );
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                return rejectWithValue("Network response was not received");
            }

            return rejectWithValue(error.response.data.error || "Error");
        }
    },
);

export const uploadAvatar = createAsyncThunk<
    any,
    Record<string, any>,
    { rejectValue: any | string }
>("employees/uploadAvatar", async (data, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("employeeId", data.employeeId);
        formData.append("avatar", data.avatar);


        const response = await axiosApi.post(
            "/employees/attachmentlogo",
            formData,
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return rejectWithValue(error.response.data.errors);
            } else {
                return rejectWithValue(
                    "Что-то пошло не так... Попробуйте обновить страницу",
                );
            }
        }       

        return rejectWithValue(
            "Что-то пошло не так... Попробуйте обновить страницу",
        );
    }
});


export const uploadAvatarPersonal = createAsyncThunk<
    any,
    Record<string, any>,
    { rejectValue: any | string }
>("employees/uploadAvatar", async (data, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append("employeeId", data.employeeId);
        formData.append("avatar", data.avatar);


        const response = await axiosApi.post(
            "personal-cabinet/attachmentlogo",
            formData,
        );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                return rejectWithValue(error.response.data.errors);
            } else {
                return rejectWithValue(
                    "Что-то пошло не так... Попробуйте обновить страницу",
                );
            }
        }       

        return rejectWithValue(
            "Что-то пошло не так... Попробуйте обновить страницу",
        );
    }
});

export const getAllSkills = createAsyncThunk(
    "employees/getAllSkills",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/lookups/skills");
            return response.data;
        } catch (error) {            
            return rejectWithValue("Error");
        }
    },
);

export const getAllSkillLevels = createAsyncThunk(
    "employees/getAllSkillLevels",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("/lookups/skilllevels");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);

export const getProjectByEmployee = createAsyncThunk(
    "employees/getProjectByEmployee",
    async ({ employeeId }: { employeeId?: string }, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get(
                `/employees/${employeeId}/projects`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
