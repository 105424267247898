import React, { type FC, useEffect, useMemo, useState } from "react";
import closeIcon from "assets/icons/closeIcon.svg";
import { useAppDispatch, useAppSelector } from "hooks";
import { ILookupItem } from "models/lookups";
import type { IOrganizations } from "models/organizations";
import { useTranslation } from "react-i18next";
import { getLookupByName, getOrganizations } from "store";
import { fetchVacationBalances } from "store/thunks/vacationSickDaysRemainThunk";
import { cancelBtn, saveBtn } from "styles/MUIStyles";

import {
  Autocomplete,
  type AutocompleteChangeDetails,
  type AutocompleteChangeReason,
  Box,
  Drawer,
  FormControl,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import type { Filters } from "..";

import styles from "../VacationSickDaysRemainingDays.module.scss";
interface IProps {
  isOpenFilterPanel: boolean;
  setIsOpenFilterPanel: (state: boolean) => void;
  filters: Filters;
  setFilters: (state: any) => void;
  resetFilters: () => void;
}

const VacationBalanceFilterPanel: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    filters,
    setFilters,
    resetFilters,
    isOpenFilterPanel,
    setIsOpenFilterPanel,
  } = props;

  const organizations = useAppSelector(
    (state) => state.organizations.organizations,
  );

  const organizationTypes = useAppSelector(
    (state) => state.lookups.organizationTypes,
  );

  useEffect(() => {
    dispatch(getOrganizations({ pageSize: 1000 }));
    dispatch(getLookupByName("organizationTypes"));
  }, [t, dispatch]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setFilters((prev: Filters) => {
      const newTypes = checked
        ? [...prev.organizationTypes, value]
        : prev.organizationTypes.filter((type: any) => type !== value);

      return { ...prev, organizationTypes: newTypes };
    });
  };

  const filteredOrganizations = organizations.filter((org) =>
    filters.organizationTypes.length > 0
      ? filters.organizationTypes.includes(org.type)
      : true,
  );

  const handleOrganizationChange = (_: any, value: IOrganizations | null) => {
    setFilters((prev: Filters) => ({
      ...prev,
      organizationId: value ? value.id : null,
    }));
  };

  const closeFilterPanel = () => {
    setIsOpenFilterPanel(false);
  };

  return (
    <Drawer
      sx={{
        width: 300,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 300,
          top: "7.5vh",
          padding: "1.2rem 0.7rem 1.2rem 1.2rem",
          boxSizing: "border-box",
        },
      }}
      anchor="right"
      onClose={closeFilterPanel}
      open={isOpenFilterPanel}
    >
      <Box
        sx={{
          display: "flex",
          "& > img": { cursor: "pointer", marginLeft: "auto" },
        }}
      >
        <img onClick={closeFilterPanel} src={closeIcon} alt="close filter" />
      </Box>
      <div className={styles.filterPanelWrapper}>
        <Box sx={{ display: "flex" }}>
          <Button variant="contained" onClick={resetFilters} sx={saveBtn}>
            {t("buttons.Reset")}
          </Button>
        </Box>
        <FormGroup>
          <label htmlFor="organizationId" className="filterTopSubTitle">
            {t("data.companyType")}
          </label>
          {organizationTypes?.map((type) => (
            <FormControlLabel
              key={type}
              control={
                <Checkbox
                  style={{ height: "30px" }}
                  value={type}
                  checked={filters?.organizationTypes?.includes(type)}
                  onChange={handleFilterChange}
                />
              }
              label={t(`organizationTypes.${type}`)}
            />
          ))}
        </FormGroup>
        <FormControl fullWidth>
          <label htmlFor="organizationId" className="filterTopSubTitle">
            {t("data.vendor")}
          </label>
          <Autocomplete
            ListboxProps={{ style: { maxHeight: "200px" } }}
            options={filteredOrganizations}
            getOptionLabel={(option) => option.name}
            onChange={handleOrganizationChange}
            value={
              filteredOrganizations.find(
                (org) => org.id === filters.organizationId,
              ) || null
            }
            renderInput={(params) => (
              <TextField {...params} label={t("data.vendor")} />
            )}
            sx={{ width: 200, marginTop: "1rem" }}
          />
        </FormControl>
      </div>
    </Drawer>
  );
};

export default VacationBalanceFilterPanel;
