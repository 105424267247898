import i18n from 'i18next';

export const getProjectStatusText = (status: number) => {
    switch (status) {
        case 1:
            return i18n.t('statuses.Preparation');
        case 2:
            return i18n.t('statuses.InProgress');
        case 3:
            return i18n.t('statuses.OnHold');
        case 4:
            return i18n.t('statuses.Cancelled');
        case 5:
            return i18n.t('statuses.Completed');
        default:
            return '';
    }
};
