import React, { ChangeEvent, FC, useEffect } from "react";
import closeIcon from "assets/icons/closeIcon.svg";
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
  useUpdateEffect,
} from "hooks";
import { useTranslation } from "react-i18next";
import { getLookupByName } from "store";
import { getEmployeesByProjectId } from "store/thunks/projectsThunk";

import AdapterDayjs from "@date-io/date-fns";
import { Autocomplete, Drawer, FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { IProjectEmployeesFilters } from "../../../models/projects";
import { cancelBtn as resetFiltersBtn } from "../../../styles/MUIStyles";

import styles from "../ProjectsManagement/Employees/Employees.module.scss";

enum EmployeeStatuses {
  Active = "1",
  Inactive = "2",
  MarkedForDeletion = "3",
}

interface Props {
  params: any;
  isFilterOpen: boolean;
  setIsFilterOpen: (isOpen: boolean) => void;
  activeTab: number;
  debouncedSearch: string;
}

const ProjectEmployeesFilter: FC<Props> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { params, isFilterOpen, setIsFilterOpen, activeTab, debouncedSearch } =
    props;

  const organizations = useAppSelector((state) => state.lookups.organizations);
  const skills = useAppSelector((state) => state.lookups.skills);
  const skillLevels = useAppSelector((state) => state.lookups.skillLevels);
  const positions = useAppSelector((state) => state.lookups.positions);

  const [projectEmployeesFilter, setProjectEmployeesFilter] =
    useLocalStorage<IProjectEmployeesFilters>("projectEmployeesFilter", {
      employeeStatus: "",
      organization: null,
      skill: null,
      skillLevel: null,
      position: null,
      fromStartDate: null,
      beforeStartDate: null,
      fromEndDate: null,
      beforeEndDate: null,
      startFTE: "",
      endFTE: "",
      pageSize: "",
    });

  useUpdateEffect(() => {
    dispatch(
      getEmployeesByProjectId({
        projectId: +params.projectId,
        fullName: debouncedSearch,
        position: projectEmployeesFilter?.position,
        organization: projectEmployeesFilter?.organization,
        skill: projectEmployeesFilter?.skill,
        startFTE: projectEmployeesFilter?.startFTE,
        endFTE: projectEmployeesFilter?.endFTE,
        fromStartDate: projectEmployeesFilter?.fromStartDate,
        beforeStartDate: projectEmployeesFilter?.beforeStartDate,
        fromEndDate: projectEmployeesFilter?.fromEndDate,
        beforeEndDate: projectEmployeesFilter?.beforeEndDate,
        employeeStatus: projectEmployeesFilter?.employeeStatus,
      }),
    );
  }, [projectEmployeesFilter]);

  const handleCheckboxChange = (value: EmployeeStatuses) => {
    setProjectEmployeesFilter((prevState: IProjectEmployeesFilters) => ({
      ...prevState,
      employeeStatus: prevState.employeeStatus === value ? "" : value,
    }));
  };

  const resetFilters = () => {
    setProjectEmployeesFilter({
      employeeStatus: "",
      organization: null,
      skill: null,
      skillLevel: null,
      position: null,
      fromStartDate: null,
      beforeStartDate: null,
      fromEndDate: null,
      beforeEndDate: null,
      startFTE: "",
      endFTE: "",
    });
  };

  useEffect(() => {
    if (activeTab === 1) {
      if (!organizations.length) {
        dispatch(getLookupByName("organizations"));
      }
    }
  }, [organizations]);

  useEffect(() => {
    if (activeTab === 1) {
      if (!skillLevels.length) {
        dispatch(getLookupByName("skillLevels"));
      }
    }
  }, [skillLevels]);

  useEffect(() => {
    if (activeTab === 1) {
      if (!positions.length) {
        dispatch(getLookupByName("positions"));
      }
    }
  }, [positions]);

  useEffect(() => {
    if (activeTab === 1) {
      if (!skills.length) {
        dispatch(getLookupByName("skills"));
      }
    }
  }, [skills]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Drawer
          sx={{
            width: 310,
            flexShrink: 0,
            overflow: "auto",
            "& .MuiDrawer-paper": {
              width: 310,
              top: "7.5vh",
              height: "calc(100vh - 7.5vh)",
              padding: "1rem 0.7rem 1rem 1rem",
              boxSizing: "border-box",
              "&::-webkit-scrollbar": {
                width: "0.4444rem",
                height: "0.4444rem",
              },

              "&::-webkit-scrollbar-thumb": {
                borderRadius: "0.4444rem",
                background: "#6d6d6d",
              },

              "&::-webkit-scrollbar-track": {
                borderRadius: "0.4444rem",
              },
            },
          }}
          anchor="right"
          onClose={() => setIsFilterOpen(false)}
          open={isFilterOpen}
        >
          <Box
            sx={{
              display: "flex",
              "& > img": {
                cursor: "pointer",
                marginLeft: "auto",
              },
            }}
          >
            <img
              onClick={() => setIsFilterOpen(false)}
              src={closeIcon}
              alt="close icon"
            />
          </Box>
          <Box sx={{ display: "flex", marginBottom: "1rem" }}>
            <Button variant="text" onClick={resetFilters} sx={resetFiltersBtn}>
              {t("buttons.Reset")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormGroup sx={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="1"
                    onChange={() =>
                      handleCheckboxChange(EmployeeStatuses.Active)
                    }
                    checked={projectEmployeesFilter.employeeStatus === "1"}
                  />
                }
                label={t("filters.active")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="2"
                    onChange={() =>
                      handleCheckboxChange(EmployeeStatuses.Inactive)
                    }
                    checked={projectEmployeesFilter.employeeStatus === "2"}
                  />
                }
                label={t("filters.inactive")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value=""
                    onChange={() =>
                      handleCheckboxChange(EmployeeStatuses.MarkedForDeletion)
                    }
                    checked={projectEmployeesFilter.employeeStatus === "3"}
                  />
                }
                label={t("filters.markedForDeletion")}
              />
            </FormGroup>
          </Box>
          <Box>
            <label>{t("projects.vendorCompany")}</label>
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectEmployeesFilter.organization}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      organization: newValue,
                    }),
                  );
                } else {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      organization: null,
                    }),
                  );
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={organizations}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.all") as string,
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <label>{t("projects.position")}</label>
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectEmployeesFilter.position}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      position: newValue,
                    }),
                  );
                } else {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      position: null,
                    }),
                  );
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={positions}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.all") as string,
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <label>{t("projects.skill")}</label>
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectEmployeesFilter.skill}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      skill: newValue,
                    }),
                  );
                } else {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      skill: null,
                    }),
                  );
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={skills}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.all") as string,
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <label>{t("projects.level")}</label>
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectEmployeesFilter.skillLevel}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      skillLevel: newValue,
                    }),
                  );
                } else {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      skillLevel: null,
                    }),
                  );
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={skillLevels}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.all") as string,
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <h4>{t("projects.start")}</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.from")}</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={projectEmployeesFilter.fromStartDate}
                onChange={(newValue) => {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      fromStartDate: newValue,
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: "20px",
              "& > label": {
                fontWeight: 600,
              },
            }}
          >
            <label>{t("projects.until")}</label>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyyy"
              value={projectEmployeesFilter.beforeStartDate}
              onChange={(newValue) => {
                setProjectEmployeesFilter(
                  (prevState: IProjectEmployeesFilters) => ({
                    ...prevState,
                    beforeStartDate: newValue,
                  }),
                );
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.dayMonthYear") as string,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <h4>{t("projects.completion")}</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.from")}</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={projectEmployeesFilter.fromEndDate}
                onChange={(newValue) => {
                  setProjectEmployeesFilter(
                    (prevState: IProjectEmployeesFilters) => ({
                      ...prevState,
                      fromEndDate: newValue,
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: "20px",
              "& > label": {
                fontWeight: 600,
              },
            }}
          >
            <label>{t("projects.until")}</label>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={projectEmployeesFilter.beforeEndDate}
              onChange={(newValue) => {
                setProjectEmployeesFilter(
                  (prevState: IProjectEmployeesFilters) => ({
                    ...prevState,
                    beforeEndDate: newValue,
                  }),
                );
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("projects.dayMonthYear") as string,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Box>
          <h4>FTE</h4>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: "20px",
              "& > label": {
                fontWeight: 600,
              },
            }}
          >
            <label>{t("projects.from")}</label>
            <input
              className={styles.fte}
              placeholder="0.0"
              type="text"
              value={projectEmployeesFilter.startFTE}
              onChange={(e) => {
                setProjectEmployeesFilter(
                  (prevState: IProjectEmployeesFilters) => ({
                    ...prevState,
                    startFTE: e.target.value,
                  }),
                );
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginLeft: "20px",
              "& > label": {
                fontWeight: 600,
              },
            }}
          >
            <label>{t("projects.until")}</label>
            <input
              className={styles.fte}
              type="text"
              placeholder="0.0"
              value={projectEmployeesFilter.endFTE}
              onChange={(e) => {
                setProjectEmployeesFilter(
                  (prevState: IProjectEmployeesFilters) => ({
                    ...prevState,
                    endFTE: e.target.value,
                  }),
                );
              }}
            />
          </Box>
        </Drawer>
      </LocalizationProvider>
    </div>
  );
};

export default ProjectEmployeesFilter;
