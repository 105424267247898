import React, { FC, memo, useEffect } from "react";
import editIcon from "assets/icons/hoverEditIcon.svg";
import viewIcon from "assets/icons/view.svg";
import { getFromLocalStorage } from "core/utils";
import Guard from "features/Guard";
import { useAppDispatch, useAppSelector } from "hooks";
import { IEmployee, IEmployeeSearchParams } from "models/employees";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { getEmployees, setActiveEmployee } from "store";
import {
    materialReactTableCell,
    materialReactTableHeader,
} from "styles/MUIStyles";
import BootstrapTooltip from "ui/Tooltip";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Box from "@mui/material/Box";

import AgGrid from "../../features/AgGrid/AgGrid";

interface Props {
    sortConfig: {
        column: string | null;
        direction: string | null;
    };
    employeesPageNumber: number;
    setEmployeesPageNumber: (pageNumber: number) => void;
    handleColumnClick: any;
    debouncedEmployeeFullName: string;
    setEmployeesFullNameSearch: (search: string) => void;
    activeEmployee: null | IEmployee;
    isSearchingRef: { current: boolean };
}

const EmployeesTable: FC<Props> = memo((props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        sortConfig,
        handleColumnClick,
        activeEmployee,
        employeesPageNumber,
        setEmployeesPageNumber,
        debouncedEmployeeFullName,
        isSearchingRef,
    } = props;

    const employees = useAppSelector(
        (state) => state.employees.employees?.data,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const getArrowForSortDirection = (column: string) => {
        if (sortConfig.column !== column) {
            return null;
        }

        return sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon sx={{ color: "#605E5C" }} />
        ) : (
            <ArrowDownwardIcon sx={{ color: "#605E5C" }} />
        );
    };

    useEffect(() => {
        const employeesFilters =
            getFromLocalStorage<IEmployeeSearchParams>("employeesFilters");
        const employeesPageSize =
            getFromLocalStorage<number>("employeesPageSize");

        if (isSearchingRef.current && employeesPageNumber > 1) {
            setEmployeesPageNumber(1);
        } else {
            dispatch(
                getEmployees({
                    ...employeesFilters,
                    pageSize: employeesPageSize,
                    pageNumber: employeesPageNumber,
                    fullName: debouncedEmployeeFullName,
                    sortBy: sortConfig,
                }),
            );
        }

        isSearchingRef.current = false;
    }, [
        t,
        dispatch,
        sortConfig,
        employeesPageNumber,
        setEmployeesPageNumber,
        debouncedEmployeeFullName,
    ]);

    const HeadComponent = (p: any) => {
        switch (p.column.colId) {
            case "id":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.actions")}</span>
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("personnelNumber")}
                    >
                        <span>{t("data.tabNo")}</span>
                        {getArrowForSortDirection("personnelNumber")}
                    </Box>
                );
            case "position":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("lastName")}
                    >
                        <span>{t("data.nameAndPosition")}</span>
                        {getArrowForSortDirection("lastName")}
                    </Box>
                );
            case "skill":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("skill")}
                    >
                        <span>{t("data.mainSkill")}</span>
                        {getArrowForSortDirection("skill")}
                    </Box>
                );
            case "skillLevel":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("skillLevel")}
                    >
                        <span>{t("data.level")}</span>
                        {getArrowForSortDirection("skillLevel")}
                    </Box>
                );
            case "guild":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("guild")}
                    >
                        <span>{t("data.guild")}</span>
                        {getArrowForSortDirection("guild")}
                    </Box>
                );
            case "fte":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("fte")}
                    >
                        <span>{t("data.fte")}</span>
                        {getArrowForSortDirection("fte")}
                    </Box>
                );
            case "organization":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("organization")}
                    >
                        <span>{t("data.vendor")}</span>
                        {getArrowForSortDirection("organization")}
                    </Box>
                );
            case "costCenter":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("costCenter")}
                    >
                        <span>{t("data.costCenter")}</span>
                        {getArrowForSortDirection("costCenter")}
                    </Box>
                );
            case "resourceManager":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("resourceManager")}
                    >
                        <span>{t("data.PManager")}</span>
                        {getArrowForSortDirection("resourceManager")}
                    </Box>
                );
            case "typeOfEmployment":
                return (
                    <Box
                        sx={materialReactTableHeader}
                        onClick={() => handleColumnClick("typeOfEmployment")}
                    >
                        <span>{t("data.type")}</span>
                        {getArrowForSortDirection("typeOfEmployment")}
                    </Box>
                );
            case "email":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("tabs.contacts")}</span>
                    </Box>
                );
            case "userStatus":
                return (
                    <Box sx={materialReactTableHeader}>
                        <span>{t("data.status")}</span>
                    </Box>
                );
        }
    };

    const RowComponent = (p: any) => {
        switch (p.colDef.field) {
            case "id":
                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            height: "100%",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            "& img": {
                                cursor: "pointer",
                            },
                        }}
                    >
                        <Guard
                            permissionName="View"
                            allowedPermissions={allowedPermissions}
                        >
                            <BootstrapTooltip
                                title={t("buttons.View")}
                                onClick={() => navigate(p.data.id)}
                                placement="top"
                            >
                                <img src={viewIcon} alt="view icon" />
                            </BootstrapTooltip>
                        </Guard>

                        <Guard
                            permissionName="Edit"
                            allowedPermissions={allowedPermissions}
                        >
                            <BootstrapTooltip
                                onClick={() => navigate("edit/" + p.data.id)}
                                title={t("buttons.Edit")}
                                placement="top"
                            >
                                <img src={editIcon} alt="edit icon" />
                            </BootstrapTooltip>
                        </Guard>
                    </Box>
                );
            case "personnelNumber":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data.personnelNumber}
                    </Box>
                );
            case "position":
                return (
                    <Box sx={materialReactTableCell}>
                        <p>{`${p.data?.firstName || " "}  ${
                            p.data?.lastName || " "
                        } `}</p>
                        <p>{`${p.data?.position || " "}`}</p>
                    </Box>
                );
            case "skill":
                return <Box sx={materialReactTableCell}>{p.data?.skill}</Box>;
            case "skillLevel":
                return (
                    <Box sx={materialReactTableCell}>{p.data?.skillLevel}</Box>
                );
            case "guild":
                return <Box sx={materialReactTableCell}>{p.data?.guild}</Box>;
            case "fte":
                return <Box sx={materialReactTableCell}>{p.data?.fte}</Box>;
            case "organization":
                return (
                    <Box
                        sx={materialReactTableCell}
                        onClick={() =>
                            localStorage.setItem(
                                "redirectOrganizationId",
                                p.data?.organizationId,
                            )
                        }
                    >
                        {allowedMenus.some(
                            (menu) => menu.name === "Organizations",
                        ) ? (
                            <Link to={`/organizations/${p.data.organizationId}`}>
                                {p.data?.organization}
                            </Link>
                        ) : (
                            p.data?.organization
                        )}
                    </Box>
                );
            case "costCenter":
                return (
                    <Box sx={materialReactTableCell}>{p.data?.costCenter}</Box>
                );
            case "resourceManager":
                return (
                    <Box sx={materialReactTableCell}>
                        <Link to={`/employees/${p.data?.resourceManagerId}`}>
                            {p.data?.resourceManager}
                        </Link>
                    </Box>
                );
            case "typeOfEmployment":
                return (
                    <Box sx={materialReactTableCell}>
                        {p.data?.typeOfEmployment}
                    </Box>
                );
            case "email":
                return (
                    <Box sx={materialReactTableCell}>
                        <p>{p.data?.email}</p>
                        <p>{p.data?.phone}</p>
                    </Box>
                );
            case "userStatus":
                return (
                    <Box
                        sx={{
                            ...materialReactTableCell,
                            fontWeight: "600",
                            color:
                                p.data?.userStatus === "Active"
                                    ? "#25c00e"
                                    : "#FA0E00",
                        }}
                    >
                        {p.data?.userStatus === "Active"
                            ? t("data.isActive")
                            : t("data.notActive")}
                    </Box>
                );
        }
    };

    const generateColumnDefs = () => {
        const savedColumnState = getFromLocalStorage("agGridEmployeesTable");

        let updatedColumnDefs: any[] = [
            {
                field: "id",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "personnelNumber",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 100,
            },
            {
                field: "position",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "skill",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 160,
            },
            {
                field: "skillLevel",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "guild",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "fte",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 70,
            },
            {
                field: "organization",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 170,
            },
            {
                field: "costCenter",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 150,
            },
            {
                field: "resourceManager",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 130,
            },
            {
                field: "typeOfEmployment",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
            {
                field: "email",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 120,
            },
            {
                field: "userStatus",
                resizable: true,
                headerComponent: HeadComponent,
                cellRenderer: RowComponent,
                width: 110,
            },
        ];

        if (Array.isArray(savedColumnState)) {
            updatedColumnDefs = savedColumnState.map((columnState: any) => {
                return {
                    field: columnState.colId,
                    width: columnState.width,
                    pinned: columnState.pinned,
                    headerComponent: HeadComponent,
                    cellRenderer: RowComponent,
                    resizable: true,
                };
            });
        }

        return updatedColumnDefs;
    };

    const saveColumnState = (columnState: any) => {
        localStorage.setItem(
            "agGridEmployeesTable",
            JSON.stringify(columnState),
        );
    };

    const columnDefs: any[] = generateColumnDefs();

    return (
        <div>
            <AgGrid
                rowData={employees || []}
                columnDefs={columnDefs}
                saveColumnState={saveColumnState}
                activeRow={activeEmployee}
                setActiveRow={setActiveEmployee}
                height="65vh"
            />
        </div>
    );
});

export default EmployeesTable;
