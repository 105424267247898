import React, { useEffect, useState } from "react";
import axiosApi from "core/request";
import { useTranslation } from "react-i18next";
import Loader from "ui/Loader";
import { sendNotification } from "ui/Toast";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";

import AgGrid from "../../../../features/AgGrid/AgGrid";
import { cancelBtn } from "../../../../styles/MUIStyles";
import { Request as RequestType } from "../../../Requests";
import { formatDate, readStatus } from "../../../Requests/utils";

import { Request } from "./components/Request";

import styles from "./styles.module.css";

export const MyRequests = () => {
  const { t } = useTranslation();
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);
  const [rowData, setRowData] = useState<RequestType[]>([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const { data, status } = await axiosApi.get<{data: RequestType[], metadata: any;}>(
          "/personal-cabinet/calendar/daysoff/myrequests",
          {
            params: {
              pageSize: 100,
            }
          }
        );
        if (status !== 200) throw new Error("Ошибка при загрузке данных");

        setRowData(data.data);
      } catch (error) {
        console.error("Ошибка при загрузке заявок:", error);
      }
    };

    fetchRequests();
  }, [fetchTrigger]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpenModalEdit(newOpen);
    setFetchTrigger((prev) => !prev);
  };

  const openMenu = Boolean(anchorEl);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCancelRequest = () => {
    setIsOpenModalCancel(true);
    handleCloseMenu();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const cancel = async (id: number) => {
    try {
      setIsLoading(true);
      await axiosApi.post(
        `/personal-cabinet/calendar/daysoff/request/${id}/cancel`,
      );
      sendNotification(t("requests.notification.cancelSuccess"), "success");
      setFetchTrigger((prev) => !prev);
    } catch (e) {
      console.log(e);
      sendNotification(t("requests.notification.cancelError"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmCancel = async () => {
    await cancel(selectedRow.id);
    setIsOpenModalCancel(false);
  };

  const columnDefs = [
    {
      headerName: t("requests.column.remark"),
      field: "remark",
      resizable: true,
      width: 300,
    },
    {
      headerName: t("requests.column.sign"),
      field: "sign",
      resizable: true,
      width: 200,
      valueFormatter: () => t("requests.dayOfType.vacation"),
    },
    {
      headerName: t("requests.column.nextApproverName"),
      field: "nextApproverName",
      resizable: true,
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <span className={styles.responsibleMenuItem}>
            <span>{params.data.nextApproverName}</span>
            {!!params.data.nextApproverEmail && (
              <span className={styles.responsiblePost}>
                {params.data.nextApproverEmail}
              </span>
            )}
          </span>
        );
      },
    },
    {
      headerName: t("requests.column.createdDate"),
      field: "createdDate",
      resizable: true,
      width: 190,
      valueGetter: (params: any) => {
        const createdDate = new Date(params.data.createdDate);
        return formatDate(createdDate);
      },
    },
    {
      headerName: t("requests.column.period"),
      resizable: true,
      width: 220,
      valueGetter: (params: any) => {
        const createdDate = new Date(params.data.startDate);
        const finishDate = new Date(params.data.finishDate);
        return `${formatDate(createdDate)} - ${formatDate(finishDate)}`;
      },
    },
    {
      headerName: t("requests.column.status"),
      field: "requestStatus",
      valueFormatter: (params: any) => readStatus(params.value),
      resizable: true,
      width: 320,
    },
    {
      headerName: "",
      field: "actions",
      width: 70,
      resizable: false,
      cellRenderer: (params: any) => {
        return (
          <IconButton onClick={(event) => handleMenuClick(event, params.data)}>
            <MoreVertIcon />
          </IconButton>
        );
      },
    },
  ];

  const getRowStyle = (params: any) => {
    // if (params.data.status === "На рассмотрении") {
    //   return { backgroundColor: "#FFF3CD" };
    // }
    // if (params.data.status === "Одобрено") {
    //   return { backgroundColor: "#D4EDDA" };
    // }
    // if (params.data.status === "Отклонено") {
    //   return { backgroundColor: "#F8D7DA" };
    // }
    return {
      backgroundColor: "white",
    };
  };

  // const handleEdit = () => {
  //   console.log(selectedRow);
  //   setIsOpenModalEdit(true);
  //   handleCloseMenu();
  // };

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.header}>
        <h4
          style={{
            flex: 1,
          }}
        >
          {t("tabs.myRequests")}
        </h4>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={() => setIsOpenModalEdit(true)}
        >
          {t("buttons.NewRequest")}
        </Button>
      </div>
      <AgGrid
        rowData={rowData}
        columnDefs={columnDefs}
        height="500px"
        getRowStyleCustom={getRowStyle}
        activeRow={{}}
      />
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/*<MenuItem onClick={handleEdit}>*/}
        {/*  Редактировать*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={handleCancelRequest}>
          {t("requests.setCancel")}
        </MenuItem>
      </Menu>
      <Modal
        open={isOpenModalCancel}
        onClose={() => setIsOpenModalCancel(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t("requests.cancelModalTitle")} {selectedRow?.fullName}?
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t("requests.cancelModalDescription")}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmCancel}
            sx={{ mr: 2, textTransform: "none" }}
          >
            {t("requests.cancelButton")}
          </Button>
          <Button
            sx={{
              ...cancelBtn,
            }}
            variant="outlined"
            onClick={() => setIsOpenModalCancel(false)}
          >
            {t("requests.noButton")}
          </Button>
        </Box>
      </Modal>
      <Drawer
        anchor="right"
        open={isOpenModalEdit}
        onClose={() => setIsOpenModalEdit(false)}
      >
        <Request
          data={selectedRow}
          toggleDrawer={toggleDrawer}
          setSelectedRow={setSelectedRow}
        />
      </Drawer>
    </div>
  );
};
