import React, { FC } from "react";
import { useAppDispatch } from "hooks";
import { IAllPermissions, IUsersPermissionsId, PermissionAction, PermissionGroup } from "models/user";
import { useTranslation } from "react-i18next";
import {
    handlePermissionsUsersChildCheckbox,
    handlePermissionsUsersParentCheckbox,
} from "store/slices/usersSlice";

import { Accordion, Box, Checkbox, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import {
    CheckedDisabledIcon,
    CheckedIcon,
    ExpandMoreIcon,
    UncheckedIon,
} from "../../../assets/icons";
import {
    rolePermissionActionBox,
    rolePermissionActionName,
    roleSettingsAccordionSummary,
} from "../../../styles/MUIStyles";

interface Props {
    activeMenuItem: IAllPermissions | null;
    usersPermissions: IUsersPermissionsId | null;
    initialUserPermissionIds: number[];
}

const UsersPermissionsList: FC<Props> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { activeMenuItem, usersPermissions, initialUserPermissionIds } =
        props;

    const getPageName = (text: string) => {
        const newStr = text.replace("api/", "");

        if (newStr.includes("/")) {
            return newStr.replace("/", "");
        }
        return newStr;
    };

    const permissionActionClick = (permissionAction: PermissionAction, permissionGroup: PermissionGroup) => {
        const checked = usersPermissions?.userPermissionIds.includes(permissionAction.id);
        dispatch(
            handlePermissionsUsersChildCheckbox(
                {
                    permissionId:
                    permissionAction.id,
                    checked,
                },
            ),
        );
    };

    return (
        <>
            {activeMenuItem?.permissionGroups.map((permissionGroup) => {
                return (
                    <Accordion
                        style={{ margin: 0 }}
                        key={permissionGroup.id}
                        sx={{
                            boxShadow: "none",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            overflow: "auto",
                        }}
                    >
                        <AccordionSummary
                            style={{ minHeight: "0" }}
                            sx={roleSettingsAccordionSummary}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography
                                sx={{
                                    margin: "auto 0",
                                    fontFamily: "Source Sans Pro ,sans-serif",
                                }}
                            >
                                {t("menu." + getPageName(permissionGroup.name))}
                            </Typography>
                            <Checkbox
                                sx={{ marginLeft: "auto" }}
                                name="userPermissionParentCheckbox"
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                    permissionGroup.permissionCheckableActions
                                        .length > 0 &&
                                    permissionGroup.permissionCheckableActions.every(
                                        (permissionAction: any) => {
                                            return usersPermissions?.userPermissionIds.includes(
                                                permissionAction.id,
                                            );
                                        },
                                    )
                                }
                                checkedIcon={<CheckedIcon />}
                                icon={
                                    (permissionGroup.permissionCheckableActions
                                        .length === 0 && (
                                        <CheckedDisabledIcon />
                                    )) || <UncheckedIon />
                                }
                                disabled={
                                    permissionGroup.permissionCheckableActions
                                        .length === 0
                                }
                                onChange={(e) => {
                                    dispatch(
                                        handlePermissionsUsersParentCheckbox({
                                            permissionActions:
                                                permissionGroup.permissionActions,
                                            checked: e.target.checked,
                                        }),
                                    );
                                }}
                            />
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0" }}>
                            {permissionGroup.permissionActions.map(
                                (permissionAction) => {
                                    return (
                                        <Box
                                            onClick={() => permissionActionClick(permissionAction, permissionGroup)}
                                            key={permissionAction.id}
                                            sx={rolePermissionActionBox}
                                        >
                                            <Typography
                                                sx={rolePermissionActionName}
                                            >
                                                {t(
                                                    `buttons.${permissionAction.name}`,
                                                )}
                                            </Typography>
                                            <Checkbox
                                                name="userPermissionChildCheckbox"
                                                checked={Boolean(
                                                    usersPermissions?.userPermissionIds.includes(
                                                        permissionAction.id,
                                                    ),
                                                )}
                                                disabled={
                                                    usersPermissions?.userRolePermissionIds.includes(
                                                        permissionAction.id,
                                                    ) &&
                                                    !initialUserPermissionIds.includes(
                                                        permissionAction.id,
                                                    )
                                                }
                                                checkedIcon={<CheckedIcon />}
                                                icon={
                                                    (!usersPermissions?.userRolePermissionIds.includes(
                                                        permissionAction.id,
                                                    ) &&
                                                        !initialUserPermissionIds.includes(
                                                            permissionAction.id,
                                                        ) && (
                                                            <UncheckedIon />
                                                        )) || <UncheckedIon />
                                                }
                                                sx={{ marginLeft: "auto" }}
                                            />
                                        </Box>
                                    );
                                },
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};

export default UsersPermissionsList;
