import React, { useMemo } from "react";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { getFromLocalStorage } from "../../../core/utils";
import {
  cancelBtn,
  projectsListTableCell,
  projectsTableContainer,
} from "../../../styles/MUIStyles";
import { StyledTableCell, StyledTableRow } from "../TrackingEmployeeTable";

import styles from "../Tracking.module.scss";

const getFteColor = (fte: number) => {
  if (fte > 0.5 && fte < 1.3) return "#01AE52";
  else if ((fte >= 0.1 && fte <= 0.5) || (fte >= 1.3 && fte <= 1.8))
    return "#F5A96A";
  else return "#FE0A0B";
};

const getHoursColor = (hours: number) => {
  if (hours > 80 && hours < 208) return "#01AE52";
  else if ((hours >= 16 && hours <= 80) || (hours >= 208 && hours <= 288))
    return "#F5A96A";
  else return "#FE0A0B";
};

const TrackingProjectViewPage = () => {
  const year = getFromLocalStorage<number>("trackingFilterYear");
  const month = getFromLocalStorage<number>("trackingFilterMonth");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ projectId: string }>();
  const projectsTracking = useAppSelector(
    (state) => state.tracking.projectsTracking,
  );
  console.log(projectsTracking);

  const currentProject = useMemo(
    () =>
      projectsTracking.find(
        (project) => project.projectId === Number(params?.projectId),
      ),
    [projectsTracking, params],
  );

  const totalHours = useMemo(
    () =>
      currentProject?.employeesTimes?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.totalTime || 0,
        0,
      ),
    [currentProject],
  );

  const totalFte = useMemo(
    () =>
      currentProject?.employeesTimes?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.fte || 0,
        0,
      ),
    [currentProject],
  );

  return (
    <div>
      <div className={styles.crudButtonWrapper}></div>
        

      <div className={styles.headerWrapper}>
        <div>
          <div className={styles.box_year}>
            <ArrowLeftIcon
              htmlColor="gray"
              sx={{ color: "gray", fontSize: "40px" }}
            />
            <p>{year}</p>
            <ArrowRightIcon
              htmlColor="gray"
              sx={{
                color: "gray",
                fontSize: "40px",
              }}
            />
          </div>
          <div className={styles.box_year}>
            <ArrowLeftIcon
              htmlColor="gray"
              sx={{ color: "gray", fontSize: "40px" }}
            />
            <p>{t(`months.${month}`)}</p>
            <ArrowRightIcon
              htmlColor="gray"
              sx={{
                color: "gray",
                fontSize: "40px",
              }}
            />
          </div>
        </div>
        
        <Button
          className={styles.backButton}
          variant="text"
          onClick={() => navigate(-1)}
          sx={cancelBtn}
        >
          {t("buttons.Close")}
        </Button>
      </div>
        <div style={{ marginRight: "1px" }}>
          <TableContainer sx={{ ...projectsTableContainer }}>
            <Table stickyHeader sx={{ minWidth: 200 }}>
              <TableHead>
                <TableRow sx={{ border: "none", borderBottom: "none" }}>
                  <StyledTableCell
                    colSpan={3}
                    sx={{ border: "none" }}
                    align="right"
                  >
                    {t("tracking.total").toUpperCase()}
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <b
                      style={{
                        color: getFteColor(totalFte || 0),
                      }}
                    >
                      {totalFte}
                    </b>
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <b
                      style={{
                        color: getHoursColor(totalHours || 0),
                      }}
                    >
                      {totalHours}
                    </b>
                  </StyledTableCell>
                </TableRow>
                <TableRow sx={{ border: "none" }}>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <TableSortLabel>{t("data.nameAndPosition")}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <TableSortLabel>{t("data.mainSkill")}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <TableSortLabel>{t("data.fromDate")}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <TableSortLabel>{t("data.fte")}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell sx={{ border: "none" }} align="center">
                    <TableSortLabel>{t("data.hours")}</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ border: "none" }}
                    align="left"
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentProject?.employeesTimes?.map((tracking) => (
                  <StyledTableRow key={tracking.employeeId}>
                    <StyledTableCell align="center" sx={projectsListTableCell}>
                      <Link to={`/employees/${tracking.employeeId}`}>
                        <p>{tracking.position}</p>
                      </Link>
                      <Link to={`/employees/${tracking.employeeId}`}>
                        <p>{tracking.fullName}</p>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={projectsListTableCell}>
                      <p>{tracking.skill}</p>
                      <p>{tracking.skillLevel}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={projectsListTableCell}>
                      {tracking.startDate
                        ? dayjs(tracking.startDate).format("DD.MM.YYYY")
                        : null}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={projectsListTableCell}>
                      <b>{tracking.fte}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={projectsListTableCell}>
                      <b>{tracking.totalTime}</b>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    </div>
  );
};

export default TrackingProjectViewPage;
