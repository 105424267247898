import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import closedEye from "assets/icons/closedEye.svg";
import openedEye from "assets/icons/openedEye.svg";
import classnames from "classnames";
import { Controller, type FieldError,SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ErrorMessage } from "@hookform/error-message";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import CatalogItemActionsComponent from "../../features/Catalogs/CatalogItemActionsComponent";
import ConfirmationModal from "../../features/ConfirmationModal";
import FormButtons from "../../features/FormButtons";
import { useAppDispatch, useAppSelector, useSetServerError } from "../../hooks";
import { countries } from "../../models/countries";
import { IUser, IUserPutArgs } from "../../models/user";
import {
  changePassword,
  changeUserEmail,
  clearCatalogTextFieldErrors,
  editUser,
  getUsers,
} from "../../store";
import { autocompleteStyles } from "../../styles/MUIStyles";
import { StyledSelect, StyledSelectError } from "../../ui/Select";
import { sendNotification } from "../../ui/Toast";

import styles from "views/Catalogs/CatalogsLayout.module.scss";

const UpdateUserForm: FC<{
  createOrUpdate: string | null;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  activeUser: IUser | null;
  setActiveUser: (activeUser: IUser | null) => void;
  setCreateOrUpdate: Dispatch<SetStateAction<string | null>>;
  debouncedSearchUserName: string;
  debouncedSearchUserEmail: string;
  pageNumber: number;
  pageSize: number;
  userStatus: string;
  sortConfig: { column: string | null, direction: string | null };
}> = ({
  createOrUpdate,
  setIsDrawerOpen,
  isDrawerOpen,
  activeUser,
  setActiveUser,
  setCreateOrUpdate,
  debouncedSearchUserName,
  debouncedSearchUserEmail,
  userStatus,
  pageNumber,
  pageSize,
  sortConfig,
}): JSX.Element => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng");

  const dispatch = useAppDispatch();

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const countries = useAppSelector((state) => state.catalogs.countries);
  const userRoles = useAppSelector((state) => state.users.roles);
  const userErrors = useAppSelector((state) => state.users.userErrors);
  const isLoading = useAppSelector((state) => state.users.isLoading);
  const isUserEmailChangingLoading = useAppSelector(
    (state) => state.users.isUserEmailChangingLoading,
  );

  const userStatuses = ["active", "inactive", "markedForDeletion"];

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordVisibleConfirm, setIsPasswordVisibleConfirm] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
    control,
    watch,
  } = useForm<IUserPutArgs>();

  const hasPasswordError = !!errors?.password;
  const hasConfirmPasswordError = !!errors?.confirmPassword;

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    try {
      // 1. POST changeemailuser
      if (activeUser && formData.email !== activeUser.email) {
        const emailResponse = await dispatch(
          changeUserEmail({
            id: activeUser.id,
            userEmail: formData.email,
          })
        );

        if (emailResponse.meta.requestStatus !== "fulfilled") {
          sendNotification(t("errors.somethingWrongWithEmail"), "error");
          return;
        }
      }

      // 2. PUT users
      const editResponse = await dispatch(
        editUser({
          id: activeUser?.id!,
          firstName: formData.firstName,
          middleName: formData.middleName,
          lastName: formData.lastName,
          roleName: formData.roleName,
          phoneNumber: formData.phoneNumber,
          userStatus: formData.userStatus,
          countryId: formData.countryId,
        })
      );

      if (editResponse.meta.requestStatus !== "fulfilled") {
        sendNotification(t("errors.somethingWrongWithEdit"), "error");
        return;
      }

      // 3. POST changepassword
      if (formData.password) {
        const passwordResponse = await dispatch(
          changePassword({
            userId: activeUser?.id!,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
          })
        );

        if (passwordResponse.meta.requestStatus !== "fulfilled") {
          sendNotification(t("errors.somethingWrongWithPassword"), "error");
          return;
        }
      }
      sendNotification(t("notifications.successfullySaved"), "success");

      await dispatch(
        getUsers({
          userName: debouncedSearchUserName,
          email: debouncedSearchUserEmail,
          pageNumber,
          userStatus,
          pageSize,
          sortBy: sortConfig,
        })
      );

      setIsDrawerOpen(false);
      setActiveUser(null);
    } catch (error) {
      console.error("Error in onSubmit:", error);
      sendNotification(t("errors.unexpectedError"), "error");
    }
  };

  const setFormData = (fields: IUser | null) => {
    // Object.entries(fields).forEach(([key, value]) => form.setValue(key, value)
    setValue("email", fields?.email || "");
    setValue("firstName", fields?.firstName || "");
    setValue("middleName", fields?.middleName || "");
    setValue("lastName", fields?.lastName || "");
    setValue("roleName", fields?.roleName || "");
    setValue("phoneNumber", fields?.phoneNumber || "");
    setValue("userStatus", fields?.userStatus || "");
    setValue("countryId", fields?.countryId || "");
  };

  const formClose = () => {
    if (formChangesChecking()) setConfirmationModalOpen(true);
    else closeFormDrawer();
  };

  const closeFormDrawer = () => {
    setCreateOrUpdate(null);
    setIsDrawerOpen(false);
  };

  const closeModalConfirm = () => {
    setFormData(null);
    setIsDrawerOpen(false);
    setConfirmationModalOpen(false);
  };

  // returns true, if form was updated
  const formChangesChecking = () => {
    return Boolean(
      getValues("firstName") !== activeUser?.firstName ||
        getValues("middleName") !== activeUser?.middleName ||
        getValues("lastName") !== activeUser?.lastName ||
        getValues("roleName") !== activeUser?.roleName ||
        getValues("phoneNumber") !== activeUser?.phoneNumber ||
        getValues("userStatus") !== activeUser?.userStatus ||
        getValues("countryId") !== activeUser?.countryId,
    );
  };

  useEffect(() => {
    if (isDrawerOpen) {
      setFormData(activeUser);
    } else {
      setFormData(null);
      clearErrors();
      dispatch(clearCatalogTextFieldErrors());
    }
  }, [isDrawerOpen, dispatch]);

  useSetServerError({
    serverError: userErrors,
    setError,
    clearErrors,
    errorNames: [
      "email",
      "firstName",
      "middleName",
      "lastName",
      "roleName",
      "phoneNumber",
      "userStatus",
      "countryId",
      "password",
    ],
  });

  const getClassNames = (error: FieldError | undefined): string => {
    return error ? styles.catalogsDrawerInputError: styles.catalogsDrawerInput;
  };


  return (
    <>
      <CatalogItemActionsComponent
        name="users"
        isDrawerOpen={isDrawerOpen}
        createOrUpdate={createOrUpdate}
        closeFormDrawer={formClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formInputWrapper}>
            <label htmlFor="email" className={styles.drawerTopSubTitle}>
              {t("data.email")}
            </label>
            <div>
              <input
                {...register("email", {
                  required: t("validation.requiredField") as string,
                  maxLength: {
                    value: 50,
                    message: t("validation.maxLength50"),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
                    message: t("validation.invalidEmail") as string,
                  },
                })}
                id="email"
                type="text"
                autoComplete="off"
                placeholder={t("data.email") as string}
                className={getClassNames(errors.email)}
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>
            <label htmlFor="firstName" className={styles.drawerTopSubTitle}>
              {t("data.firstName")}
            </label>
            <div>
              <input
                {...register("firstName", {
                  required: t("validation.requiredField") as string,
                  maxLength: {
                    value: 50,
                    message: t("validation.maxLength50"),
                  },
                  pattern: {
                    value: /^[А-ЯЁA-Z\s-]+$/i,
                    message: t("validation.onlyLetters") as string,
                  },
                })}
                id="firstName"
                type="text"
                autoComplete="off"
                placeholder={t("data.firstName") as string}
                className={getClassNames(errors.firstName)}
              />
              <ErrorMessage
                name="firstName"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>
            <label htmlFor="middleName" className={styles.drawerTopSubTitle}>
              {t("data.middleName")}
            </label>
            <div>
              <input
                {...register("middleName", {
                  maxLength: {
                    value: 50,
                    message: t("validation.maxLength50"),
                  },
                  pattern: {
                    value: /^[А-ЯЁA-Z\s-]+$/i,
                    message: t("validation.onlyLetters") as string,
                  },
                })}
                id="middleName"
                type="text"
                autoComplete="off"
                placeholder={t("data.middleName") as string}
                className={getClassNames(errors.middleName)}
              />
              <ErrorMessage
                name="middleName"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>
            <label htmlFor="lastName" className={styles.drawerTopSubTitle}>
              {t("data.lastName")}
            </label>
            <div>
              <input
                {...register("lastName", {
                  required: t("validation.requiredField") as string,
                  maxLength: {
                    value: 50,
                    message: t("validation.maxLength50"),
                  },
                  pattern: {
                    value: /^[А-ЯЁA-Z\s-]+$/i,
                    message: t("validation.onlyLetters") as string,
                  },
                })}
                id="lastName"
                type="text"
                autoComplete="off"
                placeholder={t("data.lastName") as string}
                className={getClassNames(errors.lastName)}
              />
              <ErrorMessage
                name="lastName"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>
            <label htmlFor="password" className={styles.drawerTopSubTitle}>
              {t("data.password")}
            </label>
            <div className={styles.loginFormInnerPasswordContainer}>
              <input
                {...register("password", {
                  minLength: {
                    value: 8,
                    message: t("validation.minLength8"),
                  },
                  validate: (value) => {
                    if (!value) return true; // Skip validation if no value provided

                    const validations = [
                      {
                        test: /[A-Z]/.test(value),
                        message: t("validation.mustIncludeUpperCase"),
                      },
                      {
                        test: /[!@#$%^&*(),.?":{}|<>]/.test(value),
                        message: t("validation.mustIncludeSpecialChar"),
                      },
                      {
                        test: /\d/.test(value),
                        message: t("validation.mustIncludeDigit"),
                      },
                      {
                        test: value.length >= 8,
                        message: t("validation.minLength8"),
                      },
                    ];

                    const invalid = validations.find((v) => !v.test);
                    return invalid ? invalid.message : true;
                  },
                })}
                id="password"
                type={isPasswordVisible ? "text" : "password"}
                autoComplete="off"
                placeholder={t("data.password") as string}
                className={getClassNames(errors.password)}
              />
              {isPasswordVisible ? (
                <img
                  className={classnames(styles.loginFormInnerPasswordEye, {
                    [styles.loginFormInnerPasswordEyeError]: hasPasswordError,
                  })}
                  src={openedEye}
                  onClick={() =>
                    setIsPasswordVisible((prevState) => !prevState)
                  }
                  alt="password eye"
                />
              ) : (
                <img
                  className={classnames(styles.loginFormInnerPasswordEye, {
                    [styles.loginFormInnerPasswordEyeError]: hasPasswordError,
                  })}
                  src={closedEye}
                  onClick={() =>
                    setIsPasswordVisible((prevState) => !prevState)
                  }
                  alt="password eye"
                />
              )}
              <ErrorMessage
                name="password"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
                />
            </div>
            <label
              htmlFor="confirmPassword"
              className={styles.drawerTopSubTitle}
            >
              {t("data.confirmPassword")}
            </label>
            <div className={styles.loginFormInnerPasswordContainer}>
              <input
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === watch("password") ||
                    (t("validation.passwordsDoNotMatch") as string),
                })}
                id="confirmPassword"
                type={isPasswordVisibleConfirm ? "text" : "password"}
                autoComplete="off"
                placeholder={t("data.confirmPassword") as string}
                className={getClassNames(errors.confirmPassword)}
              />
              {isPasswordVisibleConfirm ? (
                <img
                  className={classnames(styles.loginFormInnerPasswordEye, {
                    [styles.loginFormInnerPasswordEyeError]: hasConfirmPasswordError,
                  })}
                  src={openedEye}
                  onClick={() =>
                    setIsPasswordVisibleConfirm((prevState) => !prevState)
                  }
                  alt="password eye"
                />
              ) : (
                <img
                  className={classnames(styles.loginFormInnerPasswordEye, {
                    [styles.loginFormInnerPasswordEyeError]: hasConfirmPasswordError,
                  })}
                  src={closedEye}
                  onClick={() =>
                    setIsPasswordVisibleConfirm((prevState) => !prevState)
                  }
                  alt="password eye"
                />
              )}
              <ErrorMessage
                name="confirmPassword"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>

            <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
              <span className={styles.drawerTopSubTitle}>
                {t("data.roleName")}
              </span>
              <Controller
                name="roleName"
                defaultValue=""
                control={control}
                rules={{
                  required: t("validation.requiredField") as string,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    input={
                      errors?.roleName ? (
                        <StyledSelectError />
                      ) : (
                        <StyledSelect />
                      )
                    }
                  >
                    {userRoles.map((item) => (
                      <MenuItem key={item.roleName} value={item.roleName}>
                        {t(`roles.${item.roleName}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage
                name="roleName"
                errors={errors}
                render={({ message }) => (
                  <span className={styles.selectErrorMsg}>{message}</span>
                )}
              />
            </FormControl>
            <label htmlFor="phoneNumber" className={styles.drawerTopSubTitle}>
              {t("data.phoneNumber")}
            </label>
            <div>
              <input
                {...register("phoneNumber", {
                  maxLength: {
                    value: 50,
                    message: t("validation.maxLength50"),
                  },
                })}
                id="phoneNumber"
                type="text"
                autoComplete="off"
                placeholder={t("data.phoneNumber") as string}
                className={getClassNames(errors.phoneNumber)}
              />
              <ErrorMessage
                name="phoneNumber"
                errors={errors}
                render={({ message }) => <span>{message}</span>}
              />
            </div>
            <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
              <span className={styles.drawerTopSubTitle}>
                {t("data.status")}
              </span>
              <Controller
                name="userStatus"
                defaultValue=""
                control={control}
                rules={{
                  required:
                    createOrUpdate === "create"
                      ? (t("validation.requiredField") as string)
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    input={
                      errors?.userStatus ? (
                        <StyledSelectError />
                      ) : (
                        <StyledSelect />
                      )
                    }
                  >
                    {userStatuses.map((item, index) => (
                      <MenuItem key={item} value={index + 1}>
                        {t(`filters.${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage
                name="userStatus"
                errors={errors}
                render={({ message }) => (
                  <span className={styles.selectErrorMsg}>{message}</span>
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
              <span className={styles.drawerTopSubTitle}>
                {t("data.country")}
              </span>
              <Controller
                name="countryId"
                defaultValue=""
                control={control}
                rules={{
                  required:
                    createOrUpdate === "update"
                      ? (t("validation.requiredField") as string)
                      : false,
                }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    ListboxProps={{
                      style: { maxHeight: "200px" },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={autocompleteStyles}
                        placeholder={t("data.country") || ""}
                        error={!!fieldState.error}
                      />
                    )}
                    options={[...countries].sort(
                      (a: countries, b: countries) => {
                        return a.name.localeCompare(
                          b.name,
                          currentLanguage === "en-US" ? "en" : "ru",
                        );
                      },
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value: countries | null) =>
                      field.onChange(value?.id)
                    }
                    value={
                      countries.filter(
                        (el) => el?.id === getValues("countryId"),
                      )[0] || null
                    }
                  />
                )}
              />
              <ErrorMessage
                name="countryId"
                errors={errors}
                render={({ message }) => (
                  <span className={styles.selectErrorMsg}>{message}</span>
                )}
              />
            </FormControl>
          </div>
          <FormButtons
            isLoading={isLoading || isUserEmailChangingLoading}
            createOrUpdate={createOrUpdate}
            cancelHandler={() => formClose()}
          />
        </form>
      </CatalogItemActionsComponent>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setConfirmationModalOpen}
        actionName={"Close"}
        instanceName=""
        message={"closeFormWithoutSaving"}
        handleAction={() => closeModalConfirm()}
      />
    </>
  );
};

export default UpdateUserForm;
