import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from "store";
import { getOrganization } from "store/thunks/organizationsThunk";
import Loader from "ui/Loader";
import { useLocalStorage } from "usehooks-ts";

import { Breadcrumbs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { ORGANIZATIONS_PATH } from "../../../router/constants";

import OrganizationDocuments from "./OrganizationDocuments/OrganizationDocuments";
import OrganizationGeneralInfo from "./OrganizationGeneralInfo/OrganizationGeneralInfo";

import styles from "../Organizations.module.scss";

const OrganizationView: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { organizationId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (organizationId) {
                setIsLoading(true);
                await Promise.all([
                    dispatch(getOrganization(Number(organizationId))),
                ]);
                setIsLoading(false);
            }
        })();
    }, [dispatch, organizationId, t]);

    const organizationDetails = useAppSelector(
        (state) => state.organizations.organizationDetails,
    );

    const [organizationsActiveTab, setOrganizationsActiveTab] = useLocalStorage(
        "organizationsActiveTab",
        0,
    );

    const [isLoading, setIsLoading] = useState(false);

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        localStorage.setItem("Employees_Tab_No", newValue.toString());
        setOrganizationsActiveTab(newValue);
    };

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Organizations");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/organizations`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    if (!organizationDetails || isLoading) {
        return <Loader />;
    }

    return (
        <Box sx={{ width: "100%", padding: "10px 0" }}>
            <Breadcrumbs sx={{ padding: "10px 10px" }}>
                <Link
                    to={"/" + ORGANIZATIONS_PATH}
                    className={styles.breadScrumbsLink}
                >
                    {t("organizations.organizations")}
                </Link>
                <Typography
                    sx={{ color: "#201f1e", fontFamily: "Source Sans Pro" }}
                >
                    {organizationDetails?.fullName}
                </Typography>
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={organizationsActiveTab} onChange={handleActiveTab}>
                    <Tab label={t("tabs.generalInfo")} value={0} />

                    <Tab label={t("tabs.docs")} value={1} />

                    {/* <Tab label={t("tabs.history")} value={3} /> */}
                </Tabs>
            </Box>

            {organizationsActiveTab === 0 && (
                <OrganizationGeneralInfo
                    allowedPermissions={allowedPermissions}
                    organization={organizationDetails}
                    {...organizationDetails}
                />
            )}

            {organizationsActiveTab === 1 && (
                <OrganizationDocuments
                    allowedPermissions={allowedPermissions}
                    id={organizationDetails?.id || 0}
                    fullName={organizationDetails?.fullName}
                    organizationDocuments={organizationDetails?.documents}
                />
            )}
        </Box>
    );
};

export default OrganizationView;
