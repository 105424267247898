import React, { FC, useEffect } from "react";
import {
    useAppDispatch,
    useAppSelector,
    useLocalStorage,
    useSortColumn,
} from "hooks";
import {
    IFormData,
    IProjectEmployeesFilters,
    ITabPanelProps,
} from "models/projects";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    changeActiveTabInEditProject,
    clearProjectData,
    clearProjectEmployees,
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
} from "store";
import {
    createProject,
    editGeneralInfo,
    getEmployeesByProjectId,
    getProjectByIdForUpdate,
} from "store/thunks/projectsThunk";
import { useDebounce } from "usehooks-ts";

import AdapterDayjs from "@date-io/date-fns";
import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { getFromLocalStorage } from "../../../core/utils";
import { PROJECTS_PATH } from "../../../router/constants";
import { sendNotification } from "../../../ui/Toast";

import Documents from "./Documents/Documents";
import Employees from "./Employees/Employees";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import {
    handleCreateGeneralInfo,
    handleEditGeneralInfo,
} from "./ProjectManagementOnSubmitHandler";

import styles from "../../../features/Employee/EmployeeView/Employees.module.scss";

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const ProjectsManagement: FC = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        setError,
        clearErrors,
        formState: { errors, isDirty, isSubmitSuccessful },
    } = useForm<IFormData>({
        defaultValues: {
            id: "",
            isActive: false,
            projectCode: "",
            description: "",
            shortName: "",
            fullName: "",
            projectType: "",
            isBillable: false,
            client: {
                id: "",
                name: "",
            },
            startDate: null,
            finishDate: null,
            projectStatus: "",
        },
    });

    const projectDataFromServer = useAppSelector(
        (state) => state.projects.projectDataFromServer,
    );
    const projectId = useAppSelector((state) => state.projects.projectId);
    const activeTab = useAppSelector((state) => state.projects.activeTab);

    const [search, setSearch] = useLocalStorage(
        "MRTProjectsEditEmployeeSearch",
        "",
    );
    const debouncedSearch = useDebounce(search, 1000);
    const { sortConfig, handleColumnClick } = useSortColumn(
        "MRTProjectEditTableSort",
    );

    const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(changeActiveTabInEditProject(newValue));
    };

    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);

    const onSubmit = async (data: IFormData) => {
        if (params?.operation === "create" && activeTab === 0) {
            const generalInfo = handleCreateGeneralInfo(data);
            const response = await dispatch(createProject({ ...generalInfo }));

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullyCreated"),
                    "success",
                );
            }
        }

        if (
            params?.operation === "edit" &&
            params?.projectId &&
            activeTab === 0
        ) {
            const generalInfo = handleEditGeneralInfo(data);
            const response = await dispatch(
                editGeneralInfo({ ...generalInfo }),
            );

            if (response.meta.requestStatus === "fulfilled") {
                sendNotification(
                    t("notifications.successfullySaved"),
                    "success",
                );
            }
        }
    };

    useEffect(() => {
        const fetchProjectData = async () => {
            if (params?.operation === "edit" && params?.projectId) {
                dispatch(getProjectByIdForUpdate(+params.projectId));
            }
        };

        fetchProjectData().catch((error) => console.log(error));

        return () => {
            dispatch(clearProjectEmployees());
        };
    }, [dispatch, params, t]);

    useEffect(() => {
        const projectEmployeesFilter =
            getFromLocalStorage<IProjectEmployeesFilters>(
                "projectEmployeesFilter",
            );
        if (params?.operation === "edit" && params?.projectId) {
            dispatch(
                getEmployeesByProjectId({
                    projectId: +params.projectId,
                    fullName: debouncedSearch,
                    position: projectEmployeesFilter?.position,
                    organization: projectEmployeesFilter?.organization,
                    skill: projectEmployeesFilter?.skill,
                    startFTE: projectEmployeesFilter?.startFTE,
                    endFTE: projectEmployeesFilter?.endFTE,
                    fromStartDate: projectEmployeesFilter?.fromStartDate,
                    beforeStartDate: projectEmployeesFilter?.beforeStartDate,
                    fromEndDate: projectEmployeesFilter?.fromEndDate,
                    beforeEndDate: projectEmployeesFilter?.beforeEndDate,
                    employeeStatus: projectEmployeesFilter?.employeeStatus,
                    sortBy: sortConfig,
                    pageSize: 100,
                }),
            );
        }
    }, [dispatch, t, params, debouncedSearch, sortConfig]);

    useEffect(() => {
        if (
            params?.operation === "edit" &&
            params?.projectId &&
            projectDataFromServer
        ) {
            const { ...generalInfo } = projectDataFromServer;
            reset({
                ...generalInfo,
                client: { id: generalInfo.clientId, name: generalInfo.client },
            });
        }
    }, [dispatch, params, projectDataFromServer, reset]);

    useEffect(() => {
        if (params?.operation !== "create" && params?.operation !== "edit") {
            navigate("/" + PROJECTS_PATH);
        }
    }, [navigate, params]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [dispatch, isSubmitSuccessful, reset, params]);

    useEffect(() => {
        if (params?.operation === "create") {
            dispatch(clearProjectData());
        }
    }, [dispatch, params]);

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Projects");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [allowedMenus, dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/projects`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    return (
        <div style={{ height: "92.5vh", overflow: "auto" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ width: "100%" }}>
                    <Breadcrumbs sx={{ padding: "10px 10px 10px 35px" }}>
                        <Link
                            to={"/" + PROJECTS_PATH}
                            className={styles.breadScrumbsLink}
                        >
                            {t("projects.projects")}
                        </Link>
                        <Typography
                            sx={{
                                color: "#201f1e",
                                fontFamily: "Source Sans Pro",
                            }}
                        >
                            {params?.operation === "create" && ""}
                            {params?.operation === "edit" &&
                                (projectDataFromServer?.shortName ||
                                    projectDataFromServer?.fullName)}
                        </Typography>
                    </Breadcrumbs>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            paddingLeft: "20px",
                        }}
                    >
                        <Box sx={{ width: "60%" }}>
                            <Tabs value={activeTab} onChange={handleActiveTab}>
                                <Tab label={t("tabs.generalInfo")} />
                                <Tab
                                    disabled={
                                        params?.operation === "create" &&
                                        !projectId
                                    } label={t("tabs.employees")} />
                                <Tab
                                    disabled={
                                        params?.operation === "create" &&
                                        !projectId
                                    } label={t("tabs.docs")} />
                                {/* <Tab
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Source Sans Pro',
                                        color: '#201f1e',
                                    }}
                                    label={t('tabs.history')}
                                /> */}
                            </Tabs>
                        </Box>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TabPanel index={0} value={activeTab}>
                            <GeneralInfo
                                activeTab={activeTab}
                                control={control}
                                register={register}
                                errors={errors}
                                isDirty={isDirty}
                                watch={watch}
                                setError={setError}
                                clearErrors={clearErrors}
                            />
                        </TabPanel>
                    </form>
                    <TabPanel index={2} value={activeTab}>
                        <Documents allowedPermissions={allowedPermissions} />
                    </TabPanel>
                    <TabPanel index={1} value={activeTab}>
                        <Employees
                            activeTab={activeTab}
                            sortConfig={sortConfig}
                            handleColumnClick={handleColumnClick}
                            search={debouncedSearch}
                            setSearch={setSearch}
                        />
                    </TabPanel>
                    <TabPanel index={3} value={activeTab}>
                        История
                    </TabPanel>
                </Box>
            </LocalizationProvider>
        </div>
    );
};

export default ProjectsManagement;
