import React, { FC } from "react";
import { CheckedIcon, ExpandMoreIcon, UncheckedIon } from "assets/icons";
import { useAppDispatch } from "hooks";
import { IAllPermissions, PermissionAction, PermissionGroup } from "models/user";
import { useTranslation } from "react-i18next";
import {
    handlePermissionsRoleChildCheckbox,
    handlePermissionsRoleParentCheckbox,
} from "store";
import {
    rolePermissionActionBox,
    rolePermissionActionName,
    roleSettingsAccordionSummary,
} from "styles/MUIStyles";

import { Accordion, Box, Checkbox, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

interface Props {
    activeMenuItem: null | IAllPermissions;
    rolePermissions: number[];
}

const RolePermissionsList: FC<Props> = ({
    activeMenuItem,
    rolePermissions,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getPageName = (text: string) => {
        const newStr = text.replace("api/", "");

        if (newStr.includes("/")) {
            return newStr.replace("/", "");
        }
        return newStr;
    };

    const rolePermissionClick = (
        permissionAction: PermissionAction, 
        permissionGroup: PermissionGroup
    ) => {
        const checked = rolePermissions.includes(permissionAction.id);
        const viewPermission = permissionGroup.permissionActions.find(
            (action) => action.name === "View"
        );
        const otherSelectedPermissions = permissionGroup.permissionActions.filter(
            (action) =>
                action.id !== viewPermission?.id &&
                rolePermissions.includes(action.id)
        );
    
        if (checked) {
            dispatch(
                handlePermissionsRoleChildCheckbox({
                    permissionId: permissionAction.id,
                    checked: false,
                })
            );
            if (permissionAction.name === 'View') {
                dispatch(
                    handlePermissionsRoleChildCheckbox({
                        permissionId: viewPermission?.id,
                        checked: false,
                    })
                );
            }
        } else {
            dispatch(
                handlePermissionsRoleChildCheckbox({
                    permissionId: permissionAction.id,
                    checked: true,
                })
            );
            if (
                viewPermission &&
                !rolePermissions.includes(viewPermission.id)
            ) {
                dispatch(
                    handlePermissionsRoleChildCheckbox({
                        permissionId: viewPermission.id,
                        checked: true,
                    })
                );
            }
        }
    };
    

    return (
        <>
            {activeMenuItem?.permissionGroups.map((permissionGroup) => (
                <Accordion
                    style={{ margin: 0 }}
                    key={permissionGroup.id}
                    sx={{
                        boxShadow: "none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        overflow: "auto",
                    }}
                >
                    <AccordionSummary
                        style={{ minHeight: "0" }}
                        sx={roleSettingsAccordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography
                            sx={{
                                margin: "auto 0",
                                fontFamily: "Source Sans Pro ,sans-serif",
                            }}
                        >
                            {t("menu." + getPageName(permissionGroup.name))}
                        </Typography>
                        <Checkbox
                            sx={{ marginLeft: "auto" }}
                            checkedIcon={<CheckedIcon />}
                            icon={<UncheckedIon />}
                            onClick={(e) => e.stopPropagation()}
                            checked={permissionGroup.permissionActions.every(
                                (permissionAction) =>
                                    rolePermissions.includes(
                                        permissionAction.id,
                                    ),
                            )}
                            onChange={(e) => {
                                dispatch(
                                    handlePermissionsRoleParentCheckbox({
                                        permissionActions:
                                            permissionGroup.permissionActions,
                                        checked: e.target.checked,
                                    }),
                                );
                            }}
                        />
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0" }}>
                        {permissionGroup.permissionActions.map(
                            (permissionAction) => (
                                <Box
                                onClick={() =>
                                    rolePermissionClick(permissionAction, permissionGroup)
                                }
                                    key={permissionAction.id}
                                    sx={rolePermissionActionBox}
                                >
                                    <Typography sx={rolePermissionActionName}>
                                        {t(`buttons.${permissionAction.name}`)}
                                    </Typography>
                                    <Checkbox
                                        checked={rolePermissions.includes(
                                            permissionAction.id,
                                        )}
                                        checkedIcon={<CheckedIcon />}
                                        icon={<UncheckedIon />}
                                        sx={{ marginLeft: "auto" }}
                                    />
                                </Box>
                            ),
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default RolePermissionsList;
