// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_header__XcL4a {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/PersonalAccount/PersonalInfo/MyRequests/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "styles_header__XcL4a"
};
export default ___CSS_LOADER_EXPORT___;
