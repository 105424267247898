import i18n from "i18next";

const statusMapping: Record<number, string> = {
  0: "none",
  1: "awaitingPM",
  2: "awaitingHR",
  3: "awaitingGM",
  4: "rejected",
  5: "approved",
  6: "cancelled"
};

export const readStatus = (status: number): string => {
  const key = statusMapping[status] || "unknown";
  return i18n.t(`requests.${key}`);
};

export const formatDate = (date: Date): string => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};