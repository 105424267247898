import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { DeleteIcon } from "assets/icons";
import { useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";

import DocumentIcon from "../../assets/icons/document.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import hoverEditIcon from "../../assets/icons/hoverEditIcon.svg";
import useDocumentDownloader from "../../hooks/useDocumentDownloader";
import { IDocument } from "../../models/documents";
import { deleteDocument, getOrganization } from "../../store";
// import DeleteIcon from "../../assets/icons/delete.svg";
import { saveBtn } from "../../styles/MUIStyles";
import { sendNotification } from "../../ui/Toast";
import ConfirmationModal from "../ConfirmationModal";

const DocumentsComponent: FC<{
  documents: Array<IDocument>;
  entity: string;
  editDocumentsList: (document: IDocument, action: string) => void;
  activeDocument: IDocument | null;
  setActiveDocument: Dispatch<SetStateAction<IDocument | null>>;
  setDocumentModalOpen: Dispatch<
    SetStateAction<{ isOpen: boolean; actionName: string }>
  >;
}> = ({
  documents,
  entity,
  editDocumentsList,
  activeDocument,
  setActiveDocument,
  setDocumentModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const { downloadDocument } = useDocumentDownloader();

  // const [activeDocument, setActiveDocument] = useState<IDocument | null>(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<{
    isOpen: boolean;
    actionName: string;
  }>({
    isOpen: false,
    actionName: "",
  });

  const removeDocument = async () => {
    const response = await dispatch(
      deleteDocument({ endpoint: entity, id: activeDocument?.id }),
    );
    if (response.meta.requestStatus === "fulfilled") {
      setConfirmationModalOpen({ isOpen: false, actionName: "" });
      editDocumentsList(activeDocument!, "delete");
      sendNotification(t("notifications.successfullyDeleted"), "success");
      dispatch(getOrganization(Number(id!)));
    }
    if (response.meta.requestStatus === "rejected") {
      sendNotification(t("errors.docNotSaved"), "error");
    }
  };

  const addDocument = () => {
    setActiveDocument(null);
    setDocumentModalOpen({ isOpen: true, actionName: "Create" });
  };

  const editDocumentHandler = (document: IDocument) => {
    setActiveDocument(document);
    setDocumentModalOpen({ isOpen: true, actionName: "Edit" });
  };

  const deleteDocumentHandler = (document: IDocument) => {
    setActiveDocument(document);
    setConfirmationModalOpen({ isOpen: true, actionName: "DeleteDoc" });
  };

  return (
    <>
      {documents?.map((document) => (
        <div
          key={document.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            maxWidth: "500px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div>
              <img src={DocumentIcon} alt="" style={{ marginTop: "5px" }} />
            </div>
            <div>
              <h3>{document.name}</h3>
              <p>{document.description}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            <button
              type="button"
              onClick={() => deleteDocumentHandler(document)}
            >
              <DeleteIcon />
              {/*<img src={DeleteIcon} alt="" style={{cursor: 'pointer'}}/>*/}
            </button>

            <img
              src={DownloadIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => downloadDocument(document.id!, entity)}
            />
            <img
              src={hoverEditIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => editDocumentHandler(document)}
            />
          </div>
        </div>
      ))}

      <Button variant="contained" sx={saveBtn} onClick={() => addDocument()}>
        {t("buttons.AddDocument")}
      </Button>

      <ConfirmationModal
        isModalOpen={confirmationModalOpen.isOpen}
        setIsModalOpen={setConfirmationModalOpen}
        actionName={confirmationModalOpen.actionName}
        instanceName={entity}
        handleAction={() => removeDocument()}
      />
    </>
  );
};

export default DocumentsComponent;
