import axiosApi from "core/request";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCalendarData = createAsyncThunk(
    "personal-cabinet/getCalendarData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosApi.get("personal-cabinet/calendar");
            return response.data;
        } catch (error) {
            return rejectWithValue("Error");
        }
    },
);
