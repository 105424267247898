import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Backdrop, Box, Button, IconButton, Menu, MenuItem, Modal, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';

import AgGrid from "../../../../features/AgGrid/AgGrid";
import { cancelBtn } from "../../../../styles/MUIStyles";

import { Request } from "./components/Request";
import { rowData } from "./mock";

import styles from "./styles.module.css";

export const MyRequests = () => {
  const { t } = useTranslation();
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isOpenModalCancel, setIsOpenModalCancel] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsOpenModalEdit(newOpen);
  };

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };

  const handleCancelRequest = () => {
    setIsOpenModalCancel(true);
    handleCloseMenu();
  };

  const handleConfirmCancel = () => {
    console.log("Заявка отменена:", selectedRow);
    setIsOpenModalCancel(false);
  };

  const columnDefs = [
    { headerName: "Полное название", field: "fullName", resizable: true, width: 300, },
    { headerName: "Тип заявки", field: "requestType", resizable: true, width: 200 },
    { headerName: "Ответственный", field: "responsible", resizable: true, width: 240 },
    { headerName: "Документ", field: "document", resizable: true, width: 220 },
    { headerName: "Дата подачи", field: "submissionDate", resizable: true, width: 190 },
    {
      headerName: "Статус", field: "status", resizable: false, width: 200, cellStyle: (params: any) => {
        switch (params.value) {
          case "Одобрено":
            return { color: "#388E3C", fontWeight: "bold" };
          case "На рассмотрении":
            return { color: "#F57C00", fontWeight: "bold" };
          case "Отклонено":
            return { color: "#D32F2F", fontWeight: "bold" };
          default:
            return { color: "black" };
        }
      }
    },
    {
      headerName: '',
      field: "actions",
      width: 80,
      resizable: false,
      cellRenderer: (params: any) => {
        return (
          <IconButton onClick={(event) => handleMenuClick(event, params.data)}>
            <MoreVertIcon/>
          </IconButton>
        );
      }
    },
  ];

  const getRowStyle = (params: any) => {
    // if (params.data.status === "На рассмотрении") {
    //   return { backgroundColor: "#FFF3CD" };
    // }
    // if (params.data.status === "Одобрено") {
    //   return { backgroundColor: "#D4EDDA" };
    // }
    // if (params.data.status === "Отклонено") {
    //   return { backgroundColor: "#F8D7DA" };
    // }
    return {
      backgroundColor: 'white'
    };
  };

  const handleEdit = () => {
    console.log(selectedRow);
    setIsOpenModalEdit(true);
    handleCloseMenu();
  };

  return (
    <div>
      <div className={styles.header}>
        <h4
          style={{
            flex: 1,
          }}
        >
          {t("vacationsSickDays.myRequests")}
        </h4>
        <Button
          variant="contained"
          sx={{ margin: "0 10px" }}
          onClick={() => setIsOpenModalEdit(true)}
        >
          {t("buttons.NewRequest")}
        </Button>
      </div>
      <AgGrid
        rowData={rowData}
        columnDefs={columnDefs}
        height="500px"
        getRowStyleCustom={getRowStyle}
        activeRow={{}}
      />
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >

        <MenuItem onClick={handleEdit}>
          Редактировать
        </MenuItem>
        <MenuItem onClick={handleCancelRequest}>Отменить</MenuItem>
      </Menu>
      <Modal
        open={isOpenModalCancel}
        onClose={() => setIsOpenModalCancel(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            Вы уверены, что хотите отменить заявку {selectedRow?.fullName}?
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Она уже запланирована и утверждена. Пожалуйста, уточните у HR и укажите причину отмены.
          </Typography>
          <Button variant="contained" color="error" onClick={handleConfirmCancel} sx={{ mr: 2, textTransform: 'none' }}>
            Да, отменить
          </Button>
          <Button sx={{
            ...cancelBtn,
          }} variant="outlined" onClick={() => setIsOpenModalCancel(false)}>
            Нет
          </Button>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isOpenModalEdit} onClose={() => setIsOpenModalEdit(false)}>
        <Request data={selectedRow} toggleDrawer={toggleDrawer} setSelectedRow={setSelectedRow}/>
      </Drawer>
    </div>
  );
};
