import React, { ChangeEvent, FC } from "react";
import closeIcon from "assets/icons/closeIcon.svg";
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorage,
  useUpdateEffect,
} from "hooks";
import { IProjectsFilters, Status } from "models/projects";
import { useTranslation } from "react-i18next";
import { cancelBtn as resetFiltersBtn } from "styles/MUIStyles";

import AdapterDayjs from "@date-io/date-fns";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { getCalculatedFiltersCount } from "../../../core/utils";
import { getProjects } from "../../../store/thunks/projectsThunk";

import styles from "../Projects.module.scss";

interface Props {
  isProjectFiltersOpen: boolean;
  setIsProjectFiltersOpen: (isOpen: boolean) => void;
  projectsPageNumber: number;
  setProjectsPageNumber: (pageNumber: number) => void;
  projectsPageSize: number;
  debouncedSearch: string;
  projectsSortConfig: {
    column: string | null;
    direction: string | null;
  };
  setFiltersCount: (projectsFilter: number) => void;
}

const ProjectFilters: FC<Props> = (props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const clients = useAppSelector((state) => state.projects.clients);
  const employees = useAppSelector((state) => state.projects.employeesLookUp);

  const [projectsFilter, setProjectsFilter] = useLocalStorage<IProjectsFilters>(
    "projectsFilters",
    {
      client: null,
      employee: null,
      startFTE: "",
      endFTE: "",
      selectedStatuses: [],
      fromStartDate: null,
      beforeStartDate: null,
      fromEndDate: null,
      beforeEndDate: null,
    },
  );

  useUpdateEffect(() => {
    props.setFiltersCount(getCalculatedFiltersCount(projectsFilter));
    if (props.projectsPageNumber > 1) {
      props.setProjectsPageNumber(1);
    } else {
      dispatch(
        getProjects({
          pageNumber: 1,
          pageSize: props.projectsPageSize,
          searchProject: props.debouncedSearch,
          client: projectsFilter?.client,
          employee: projectsFilter?.employee,
          startFTE: projectsFilter?.startFTE,
          endFTE: projectsFilter?.endFTE,
          selectedStatuses: projectsFilter?.selectedStatuses,
          fromStartDate: projectsFilter?.fromStartDate,
          beforeStartDate: projectsFilter?.beforeStartDate,
          fromEndDate: projectsFilter?.fromEndDate,
          beforeEndDate: projectsFilter?.beforeEndDate,
          sortBy: props.projectsSortConfig,
        }),
      );
    }
  }, [projectsFilter]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    setProjectsFilter((prevFilters: IProjectsFilters) => {
      if (checked) {
        if (!prevFilters.selectedStatuses.includes(value as Status)) {
          return {
            ...prevFilters,
            selectedStatuses: [
              ...prevFilters.selectedStatuses,
              value as Status,
            ],
          };
        }
      } else {
        return {
          ...prevFilters,
          selectedStatuses: prevFilters.selectedStatuses.filter(
            (status) => status !== value,
          ),
        };
      }
      return prevFilters;
    });
  };

  const resetFilters = () => {
    setProjectsFilter({
      client: null,
      employee: null,
      startFTE: "",
      endFTE: "",
      selectedStatuses: [],
      fromStartDate: null,
      beforeStartDate: null,
      fromEndDate: null,
      beforeEndDate: null,
    });
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Drawer
          sx={{
            width: 300,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 300,
              top: "7.5vh",
              padding: "1.2rem 0.7rem 1.2rem 1.2rem",
              boxSizing: "border-box",
            },
          }}
          anchor="right"
          onClose={() => props.setIsProjectFiltersOpen(false)}
          open={props.isProjectFiltersOpen}
        >
          <Box
            sx={{
              display: "flex",
              "& > img": {
                cursor: "pointer",
                marginLeft: "auto",
              },
            }}
          >
            <img
              onClick={() => props.setIsProjectFiltersOpen(false)}
              src={closeIcon}
              alt="close filter"
            />
          </Box>
          <Box sx={{ display: "flex", marginBottom: "1.5rem" }}>
            <Button variant="text" onClick={resetFilters} sx={resetFiltersBtn}>
              {t("buttons.Reset")}
            </Button>
          </Box>

          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="1"
                    onChange={handleCheckboxChange}
                    checked={projectsFilter.selectedStatuses.includes("1")}
                  />
                }
                label={t("statuses.Preparation")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="2"
                    onChange={handleCheckboxChange}
                    checked={projectsFilter.selectedStatuses.includes("2")}
                  />
                }
                label={t("statuses.InProgress")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="3"
                    onChange={handleCheckboxChange}
                    checked={projectsFilter.selectedStatuses.includes("3")}
                  />
                }
                label={t("statuses.OnHold")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="4"
                    onChange={handleCheckboxChange}
                    checked={projectsFilter.selectedStatuses.includes("4")}
                  />
                }
                label={t("statuses.Cancelled")}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    value="5"
                    onChange={handleCheckboxChange}
                    checked={projectsFilter.selectedStatuses.includes("5")}
                  />
                }
                label={t("statuses.Completed")}
              />
            </FormGroup>
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectsFilter.client}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    client: newValue,
                  }));
                } else {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    client: null,
                  }));
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={clients}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => {
                return (
                  <span {...props} className={styles.dropDownItem}>
                    {option.name}
                  </span>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  placeholder={t("projects.client") as string}
                />
              )}
            />
            <Autocomplete
              ListboxProps={{ style: { maxHeight: "200px" } }}
              value={projectsFilter.employee}
              onChange={(
                event: ChangeEvent<NonNullable<unknown>>,
                newValue,
              ) => {
                if (newValue) {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    employee: newValue,
                  }));
                } else {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    employee: null,
                  }));
                }
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === "" ? true : option.id === value.id
              }
              options={employees}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              renderOption={(props: any, option) => {
                return (
                  <span {...props} className={styles.dropDownItem}>
                    {option.name}
                  </span>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "0",
                      border: "1px solid #8A8886",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        border: "1px solid #8A8886",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                      },
                    },
                    padding: "5px 0",
                  }}
                  size="small"
                  variant="outlined"
                  placeholder={t("projects.manager") as string}
                />
              )}
            />
            <Box>
              <h4>{t("projects.start")}</h4>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "20px",
                  "& > label": {
                    fontWeight: 600,
                  },
                }}
              >
                <label>{t("projects.from")}</label>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={projectsFilter.fromStartDate}
                  onChange={(newValue) => {
                    setProjectsFilter((prevState: IProjectsFilters) => ({
                      ...prevState,
                      fromStartDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "1px solid #8A8886",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid #8A8886",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#80bdff",
                            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                          },
                        },
                        padding: "5px 0",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t("projects.dayMonthYear") as string,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.until")}</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={projectsFilter.beforeStartDate}
                onChange={(newValue) => {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    beforeStartDate: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <h4>{t("projects.end")}</h4>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginLeft: "20px",
                  "& > label": {
                    fontWeight: 600,
                  },
                }}
              >
                <label>{t("projects.from")}</label>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={projectsFilter.fromEndDate}
                  onChange={(newValue) => {
                    setProjectsFilter((prevState: IProjectsFilters) => ({
                      ...prevState,
                      fromEndDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "1px solid #8A8886",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            border: "1px solid #8A8886",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#80bdff",
                            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                          },
                        },
                        padding: "5px 0",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: t("projects.dayMonthYear") as string,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.until")}</label>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={projectsFilter.beforeEndDate}
                onChange={(newValue) => {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    beforeEndDate: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        border: "1px solid #8A8886",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid #8A8886",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#80bdff",
                          boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, .25)",
                        },
                      },
                      padding: "5px 0",
                    }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: t("projects.dayMonthYear") as string,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Box>
            <h4>FTE</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.from")}</label>
              <input
                className={styles.fte}
                placeholder="0.0"
                type="text"
                value={projectsFilter.startFTE}
                onChange={(e) => {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    startFTE: e.target.value,
                  }));
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "20px",
                "& > label": {
                  fontWeight: 600,
                },
              }}
            >
              <label>{t("projects.until")}</label>
              <input
                className={styles.fte}
                type="text"
                placeholder="0.0"
                value={projectsFilter.endFTE}
                onChange={(e) => {
                  setProjectsFilter((prevState: IProjectsFilters) => ({
                    ...prevState,
                    endFTE: e.target.value,
                  }));
                }}
              />
            </Box>
          </Box>
        </Drawer>
      </LocalizationProvider>
    </div>
  );
};

export default ProjectFilters;
