import React, { useEffect, useRef, useState } from "react";
import { EditIcon, FilterIcon, ViewIcon } from "assets/icons";
import { getFromLocalStorage } from "core/utils";
import FiltersComponent from "features/Catalogs/FiltersComponent";
import Guard from "features/Guard";
import {
    useAppDispatch,
    useAppSelector,
    useDebounce,
    useLocalStorage,
    useSortColumn,
} from "hooks";
import { IEmployeeSearchParams } from "models/employees";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    getAllowedGroupsByMenuId,
    getAllowedPermissionsByGroupId,
    getEmployees,
    getLookupByName,
} from "store";
import { createOrEditBtn } from "styles/MUIStyles";

import SearchIcon from "@mui/icons-material/Search";
import {
    Badge,
    Box,
    Button,
    FormControl,
    MenuItem,
    Pagination,
    Select,
    SelectChangeEvent,
} from "@mui/material";

import EmployeesTable from "./EmployeesTable";
import FilterPanel from "./FilterPanel";

import "./employees.css";
import styles from "../Projects/Projects.module.scss";
import employeesStyles from "./Employees.module.scss";

const Employees = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const allowedMenus = useAppSelector((state) => state.auth.allowedMenus);
    const employees = useAppSelector((state) => state.employees.employees);
    const typeOfEmployments = useAppSelector(
        (state) => state.lookups.typeOfEmployments,
    );
    const activeEmployee = useAppSelector(
        (state) => state.employees.activeEmployee,
    );
    const { role } = useAppSelector((state) => state.auth);

    const isSearchingRef = useRef<boolean>(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
    const [createOrUpdate, setCreateOrUpdate] = useState<null | string>(null);
    const { sortConfig, handleColumnClick } =
        useSortColumn("employeesTableSort");
    const [pageNumber, setPageNumber] = useLocalStorage<number>(
        "employeesPageNumber",
        1,
    );
    const [pageSize, setPageSize] = useLocalStorage<number | string>(
        "employeesPageSize",
        10,
    );
    const [filtersCount, setFiltersCount] = useLocalStorage(
        "employeesFiltersBadge",
        0,
    );
    const [employeesFullNameSearch, setEmployeesFullNameSearch] =
        useLocalStorage("employeesFullNameSearch", "");
    const debouncedEmployeeFullName = useDebounce(employeesFullNameSearch);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const handlePageSizeChange = (event: SelectChangeEvent) => {
        setPageSize(event.target.value);
        setPageNumber(1);
        const employeesFilters =
            getFromLocalStorage<IEmployeeSearchParams>("employeesFilters");
        dispatch(
            getEmployees({
                ...employeesFilters,
                pageNumber: 1,
                pageSize: event.target.value,
                sortBy: sortConfig,
                fullName: debouncedEmployeeFullName,
            }),
        );
    };

    const closeFormDrawer = () => {
        setCreateOrUpdate(null);
        setIsFiltersOpen(false);
    };

    useEffect(() => {
        const menu = allowedMenus.find((menu) => menu.name === "Employees");
        typeof menu?.id === "number" &&
            dispatch(getAllowedGroupsByMenuId(menu.id));
    }, [dispatch]);

    useEffect(() => {
        const group = allowedGroups.find(
            (menu) => menu.name === `api/employees`,
        );
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups]);

    useEffect(() => {
        dispatch(getLookupByName("typeOfEmployments"));
    }, [t]);

    return (
        <>
            <div>
                <div className={employeesStyles.crudButtonWrapper}>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="Edit"
                    >
                        <Button
                            startIcon={<EditIcon />}
                            variant="text"
                            onClick={() =>
                                navigate(`edit/${activeEmployee?.id}`)
                            }
                            disabled={!activeEmployee}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.Edit")}
                        </Button>
                    </Guard>
                    <Guard
                        allowedPermissions={allowedPermissions}
                        permissionName="View"
                    >
                        <Button
                            startIcon={<ViewIcon />}
                            variant="text"
                            onClick={() => navigate(`${activeEmployee?.id}`)}
                            disabled={!activeEmployee}
                            sx={createOrEditBtn}
                        >
                            {t("buttons.View")}
                        </Button>
                    </Guard>
                    <Box
                        sx={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span>{t("filters.elementsOnPage")}</span>
                        <FormControl
                            variant="standard"
                            sx={{ margin: "4px .8rem 0 .8rem" }}
                        >
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                      <Badge
                        color="primary"
                        badgeContent={filtersCount}
                        sx={{ "& span": { fontSize: "12px" } }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Button
                          startIcon={<FilterIcon />}
                          variant="text"
                          onClick={() => {
                            setIsFiltersOpen(!isFiltersOpen);
                          }}
                          sx={createOrEditBtn}
                        >
                          {t("buttons.Filters")}
                        </Button>
                      </Badge>
                    </Box>
                </div>
            </div>
            <div
                className={`${styles.scrollContainerHeight} + ${styles.scrollContainer}`}
            >
                <div className={employeesStyles.searchWrapper}>
                    <input
                        type="text"
                        placeholder={
                            t("filters.searchByNameAndSurname") as string
                        }
                        className={employeesStyles.search}
                        value={employeesFullNameSearch}
                        onChange={(e) => {
                            isSearchingRef.current = true;
                            setEmployeesFullNameSearch(e.target.value);
                        }}
                    />
                    <button
                        type="button"
                        className={employeesStyles.searchIconWrapper}
                    >
                        <SearchIcon sx={{ color: "#fff" }} />
                    </button>
                </div>
                <EmployeesTable
                    sortConfig={sortConfig}
                    handleColumnClick={handleColumnClick}
                    activeEmployee={activeEmployee}
                    employeesPageNumber={pageNumber}
                    setEmployeesPageNumber={setPageNumber}
                    setEmployeesFullNameSearch={setEmployeesFullNameSearch}
                    debouncedEmployeeFullName={debouncedEmployeeFullName}
                    isSearchingRef={isSearchingRef}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    maxHeight: "120px",
                    alignItems: "center",
                }}
            >
                <Pagination
                    count={employees?.metadata?.pageCount || 1}
                    page={pageNumber}
                    onChange={handleChange}
                    size="medium"
                    shape="rounded"
                    color="primary"
                    variant="outlined"
                />
            </div>
          <FiltersComponent
            name={"filters"}
            isDrawerOpen={isFiltersOpen}
            createOrUpdate={createOrUpdate}
            closeFormDrawer={closeFormDrawer}
          >
            <FilterPanel
              typeOfEmployments={typeOfEmployments}
              pageNumber={pageNumber}
              pageSize={pageSize}
              setPageNumber={setPageNumber}
              employeeFullName={debouncedEmployeeFullName}
              sortBy={sortConfig}
              setFiltersCount={setFiltersCount}
            />
          </FiltersComponent>
        </>
    );
};

export default Employees;
