import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import {
    firstLoadingHandler,
    getAllowedPermissionsByGroupId,
    getCountries,
    getCurrencyList,
    getDataByCatalogName,
    getGrades,
    getSkillLevels,
    getSkills,
    getTimeZones,
} from 'store';
import { sendNotification, ToastType } from 'ui/Toast';

import Loader from '../../ui/Loader';
import BaseCatalog from '../../views/Catalogs/Base/Base';
import BaseCatalogWithParentId from '../../views/Catalogs/BaseWithParent/BaseWithParent';
import Countries from '../../views/Catalogs/Countries/Countries';
import Currency from '../../views/Catalogs/Currency/Currency';
import { CurrencyRates } from '../../views/Catalogs/CurrencyRates';
import { Documents } from '../../views/Catalogs/Documents';
import Grades from '../../views/Catalogs/Grades/Grades';
import SkillLevels from '../../views/Catalogs/SkillLevels/SkillLevels';
import Skills from '../../views/Catalogs/Skills/Skills';
import TimeZones from '../../views/Catalogs/TimeZones/TimeZones';

const ComponentWrapper: FC<{ name: string }> = ({ name }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    //TODO SET UP PERMISSIONS
    const allowedGroups = useAppSelector((state) => state.auth.allowedGroups);
    const isFirstLoading = useAppSelector(
        (state) => state.catalogs.isFirstLoading,
    );
    const allowedPermissions = useAppSelector(
        (state) => state.auth.allowedPermissions,
    );

    const [createOrUpdate, setCreateOrUpdate] = useState<null | string>(null);

    const getData = () => {
        switch (name) {
            case 'departments':
            case 'costCenters':
            case 'guilds':
            case 'positions':
            case 'regions':
            case 'skillsets':
            case 'typeOfEmployments':
            case 'typeOfContracts':
                dispatch(getDataByCatalogName(name));
                break;
            case 'timebelts':
                dispatch(getTimeZones(name));
                break;
            case 'grades':
                dispatch(getGrades(name));
                break;
            case 'currencies':
                dispatch(getCurrencyList(name));
                break;
            case 'countries':
                dispatch(getCountries(name));
                break;
            case 'skills':
                dispatch(getSkills(name));
                break;
            case 'skilllevels':
                dispatch(getSkillLevels(name));
                break;
        }
    };

    const fulfilledStatusHandler = (message: string, type: ToastType) => {
        sendNotification(t(message), type);
        getData();
    };

    const permissionCheck = (action: string) => {
        return !!allowedPermissions.filter((e) => e.name === action).length;
    };

    useEffect(() => {
        const group = allowedGroups.find((menu) => menu.name === `api/${name}`);
        group?.id && dispatch(getAllowedPermissionsByGroupId(group.id));
    }, [dispatch, allowedGroups, name]);

    useEffect(() => {
        const fetchDataByCatalogName = async () => {
            dispatch(firstLoadingHandler(true));
            await getData();
            dispatch(firstLoadingHandler(false));
        };

        fetchDataByCatalogName().catch((error) => console.error(error));
    }, [dispatch, name, t]);

    if (isFirstLoading) return <Loader/>;

    if (permissionCheck('View')) {
        switch (name) {
            case 'guilds':
            case 'positions':
            case 'regions':
            case 'skillsets':
            case 'typeOfEmployments':
            case 'typeOfContracts': {
                return (
                    <BaseCatalog
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'timebelts': {
                return (
                    <TimeZones
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'departments':
            case 'costCenters': {
                return (
                    <BaseCatalogWithParentId
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'grades': {
                return (
                    <Grades
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'currencies': {
                return (
                    <Currency
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'countries': {
                return (
                    <Countries
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'skills': {
                return (
                    <Skills
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'skilllevels': {
                return (
                    <SkillLevels
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                        fulfilledStatusHandler={fulfilledStatusHandler}
                    />
                );
            }
            case 'currencyRates': {
                return (
                    <CurrencyRates
                        name={name}
                        createOrUpdate={createOrUpdate}
                        setCreateOrUpdate={setCreateOrUpdate}
                    />
                );
            }
            default:
                return <></>;
        }
    } else return <NoAccessPage/>;
};

const NoAccessPage = () => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <h2>{t('notifications.noAccess')}</h2>
        </div>
    );
};
export default ComponentWrapper;
