import { createSlice } from "@reduxjs/toolkit";

import { IPersonalDocuments } from "../../models/documents";
import {
    IAllSkillLevels,
    IAllSkills,
    IDetailedEmployee,
    IDetailedEmployeeInfo,
    IEmployee,
    IEmployeeErrResponse,
    IEmployeePaged,
    IPersonalProject,
} from "../../models/employees";
import { revertAll } from "../store";
import {
    editPersonalDocuments,
    getAllSkillLevels,
    getAllSkills,
    getEmployeeById,
    getEmployees,
    getPersonalDocuments,
    getPersonalInfoEmployeeById,
    getPersonalProjects,
    getProjectByEmployee,
} from "../thunks";

type employeeSlice = {
    isLoading: boolean;
    employees: IEmployeePaged;
    employeeFullName: string;
    employeeErrors: null | IEmployeeErrResponse | undefined | string;
    employeeDetails: null | IDetailedEmployee;
    personalInfoEmployeeDetails: null | IDetailedEmployeeInfo;
    allSkills: IAllSkills[];
    allSkillLevels: IAllSkillLevels[];
    activeEmployee: null | IEmployee;
    personalDocuments: IPersonalDocuments | null;
    personalProjects: IPersonalProject[] | null;
    employeeProjects: IPersonalProject[] | null;
};

const initialState: employeeSlice = {
    isLoading: false,
    employees: {},
    employeeFullName: "",
    employeeErrors: null,
    employeeDetails: null,
    personalInfoEmployeeDetails: null,
    personalDocuments: null,
    allSkills: [],
    allSkillLevels: [],
    activeEmployee: null,
    personalProjects: null,
    employeeProjects: null,
};

const employeesSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        setEmployeeFullName: (state, action) => {
            state.employeeFullName = action.payload;
        },
        setActiveEmployee: (state, action) => {
            state.activeEmployee = action.payload;
        },
        addPersonalDocumentLocal: (state, action) => {
            // @ts-ignore //TODO fix-type
            state.personalDocuments.myDocuments = [
                action.payload,
                // @ts-ignore //TODO fix-type
                ...state.personalDocuments?.myDocuments,
            ];
        },
        removePersonalDocumentsLocal: (state, action) => {
            // @ts-ignore //TODO fix-type
            state.personalDocuments.myDocuments = action.payload;
        },
        removeOneDocument: (state, action) => {
            // @ts-ignore //TODO fix-type
            state.personalDocuments.myDocuments =
                // @ts-ignore //TODO fix-type
                state.personalDocuments.myDocuments.filter(
                    (item) => item.id !== action.payload,
                );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertAll, () => initialState)
            .addCase(getEmployees.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getEmployees.fulfilled, (state, action) => {
                state.employees = { ...action.payload };
                state.isLoading = false;
            })
            .addCase(getEmployees.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getEmployeeById.fulfilled, (state, action) => {
                state.employeeDetails = action.payload;
            })
            .addCase(getPersonalInfoEmployeeById.fulfilled, (state, action) => {
                state.personalInfoEmployeeDetails = action.payload;
            })
            .addCase(getPersonalDocuments.fulfilled, (state, action) => {
                state.personalDocuments = action.payload;
            })
            .addCase(getAllSkills.fulfilled, (state, action) => {
                state.allSkills = action.payload;
            })
            .addCase(getAllSkillLevels.fulfilled, (state, action) => {
                state.allSkillLevels = action.payload;
            })
            .addCase(editPersonalDocuments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editPersonalDocuments.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(editPersonalDocuments.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getPersonalProjects.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPersonalProjects.fulfilled, (state, action) => {
                state.personalProjects = action.payload;
                state.isLoading = false;
            })
            .addCase(getProjectByEmployee.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getProjectByEmployee.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProjectByEmployee.fulfilled, (state, action) => {
                state.employeeProjects = action.payload;
                state.isLoading = false;
            });
    },
});

export const {
    setEmployeeFullName,
    setActiveEmployee,
    addPersonalDocumentLocal,
    removePersonalDocumentsLocal,
    removeOneDocument,
} = employeesSlice.actions;

export default employeesSlice.reducer;
